import isAccess, { isSystemAccess } from 'isAccessFunction';

import { reduxStateReference } from '../../../services/ReduxStateReference';

export const accountFeatures = [''].filter(Boolean);
export const systemFeatures = [''].filter(Boolean);

export const isHasAccessToLLMUserAssistant = () => {
  if (accountFeatures && accountFeatures.length > 0 && !isAccess(accountFeatures)) return false;
  if (systemFeatures && systemFeatures.length > 0 && !isSystemAccess(systemFeatures)) return false;

  const documentationBotConfig = reduxStateReference.getState().AppConfigReducer.documentationBotConfig;
  return !!documentationBotConfig?.enabled;
};
