import { IAceEditor, IAceOptions } from 'react-ace/lib/types';
import { invariant } from 'utils/invariant';

interface Pos {
  column: number;
  row: number;
}

type NodeSlice = {
  nodes: HTMLElement[];
  startCol: number;
  endCol: number;
};

export class AceEditorExtended {
  constructor(private editor: IAceEditor) {}

  on = (key: string, handler: Function): Function => {
    return this.editor.on(key as any, handler as any);
  };

  private get editorTextContainer() {
    return this.editor?.container.querySelector('.ace_content') as HTMLDivElement | null | undefined;
  }

  public changeCursorType(type: 'default' | 'pointer') {
    if (!this.editorTextContainer) return;
    this.editorTextContainer.style.cursor = type;
  }

  public setOption(type: keyof IAceOptions, val: any) {
    this.editor.setOption(type as any, val);
  }

  public getHTMLElementByPosition(pos: Pos) {
    invariant(this.editor, 'Editor must be connected');
    const tokensNodesInRow = this.getHTMLNodesInRow(pos.row);
    if (!tokensNodesInRow) return null;

    let colCount = 0;
    for (const token of tokensNodesInRow) {
      if (!token.textContent) continue;
      const length = token.textContent.length;
      if (pos.column >= colCount && pos.column < colCount + length) {
        return token;
      }
      colCount += length;
    }
    return null;
  }

  public getHTMLElementsByRange(pos: { start: Pos; end: Pos }): NodeSlice {
    invariant(this.editor, 'Editor must be connected');
    invariant(pos.start.row === pos.end.row, 'Multiline search not implemented');
    const tokensNodesInRow = this.getHTMLNodesInRow(pos.start.row);

    const result = {
      nodes: [] as HTMLElement[],
      startCol: -1,
      endCol: -1,
    };

    if (!tokensNodesInRow) return result;

    let colCount = 0;
    for (const token of tokensNodesInRow) {
      if (!token.textContent) continue;
      const length = token.textContent.length;
      if (pos.start.column <= colCount + length && pos.end.column >= colCount) {
        if (result.startCol === -1) {
          result.startCol = colCount;
        }
        result.nodes.push(token);
        result.endCol = colCount + length;
      }
      colCount += length;
    }
    return result;
  }

  private getHTMLNodesInRow(rowNumber: number) {
    // @ts-ignore
    const firstVisibleRowNumber = this.editor.renderer.layerConfig.firstRowScreen as number;
    // @ts-ignore
    const lineEl = this.editor.renderer.$textLayer.$lines.cells[rowNumber - firstVisibleRowNumber]
      ?.element as HTMLElement;
    if (!lineEl) return null;

    return Array.from(lineEl.childNodes) as HTMLElement[];
  }
}
