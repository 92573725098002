import React, { FunctionComponent, useState, useEffect, useContext, useCallback } from 'react';
import { i18nTranslation } from '../../locale/i18nToLocalize';
import { Sidebar, Spinner, Spoiler, ScrollArea } from '@just-ai/just-ui/dist';
import { AppContext } from '../../components/AppContext';
import { IntentData as DefaultIntentData } from '@just-ai/api/dist/generated/Caila';
import { getParentPathFromPath, IntentDataset } from '../../utils/intents';
import IntentsTree from '../../components/IntentsTree';
import { getErrorCodeFromReason, Intents, useError, useLoading } from '../../utils';
import { LogLabelingContext } from './LogLabelingContext';
import { LogLabelingMode } from './reducers';
import { UNGROUPED_GROUP_NAME } from '../../utils/groups';
import { updateIntentPathInTree } from '../../utils/intents';
import { debounce } from 'lodash';

interface IntentData extends DefaultIntentData {
  name?: string;
}

const Separator = () => <div style={{ width: '100%', height: '1px', backgroundColor: '#E4E5E6' }} />;

type IntentsSelectorProps = {
  onChange?: (id: number) => unknown;
  sessionId?: number;
};
const IntentsSelector: FunctionComponent<IntentsSelectorProps> = ({ onChange = () => {}, sessionId, ...restProps }) => {
  const { t } = i18nTranslation('LLIntentsSelector');
  const { IntentsApi, accountId, projectShortName } = useContext(AppContext);
  const { state, updateStagingIntents } = useContext(LogLabelingContext);
  const [tree, setTree] = useState<IntentDataset>({});
  const [selectedIntentPaths, setSelectedIntentPaths] = useState<string[]>([]);
  const [errorMessage, setError, clearError] = useError();
  const [selectedIntent, setSelectedIntent] = useState<IntentData>();
  const [isLoading, load] = useLoading();

  useEffect(() => {
    if (errorMessage) {
      const timeout = setTimeout(() => {
        clearError();
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [clearError, errorMessage]);

  const getIntent = useCallback(
    (id: number) => {
      load(IntentsApi.getIntent(accountId, projectShortName, id, sessionId))
        .then(({ data: intent }) => {
          clearError();
          setSelectedIntent({ ...intent, name: Intents.getNameFromPath(intent.path) });
        })
        .catch(setError);
    },
    [IntentsApi, accountId, clearError, load, projectShortName, sessionId, setError]
  );

  const selectIntent = useCallback(
    (id?: number, path?: string) => {
      if (!id || !path) return;
      onChange(id);
      setSelectedIntentPaths([path]);
      getIntent(id);
    },
    [getIntent, onChange]
  );

  const handleSelect = (selectedIntentPaths: string[]) => {
    if (selectedIntent?.path === selectedIntentPaths[0]) return;
    const path = selectedIntentPaths[0];
    selectIntent(tree[path].id, path);
  };

  useEffect(() => {
    clearError();
    if (!selectedIntent)
      load(IntentsApi.listIntents(accountId, projectShortName, sessionId))
        .then(({ data: intents }) => {
          const newTree = Intents.mapIntentsToTree(intents);
          setTree(newTree);
          if (!state) return;
          const path = state.selectedGroupIds[0];
          if (state.mode === LogLabelingMode.INTENTS && path && path !== UNGROUPED_GROUP_NAME) {
            const intent = intents.find(intent => intent.path === path);
            if (!intent) return;
            selectIntent(intent.id, intent.path);
          }
        })
        .catch(setError);
  }, [
    IntentsApi,
    accountId,
    clearError,
    load,
    projectShortName,
    selectIntent,
    selectedIntent,
    sessionId,
    setError,
    state,
  ]);

  const createIntent = useCallback(
    (parentPath: string, intentName?: string) => {
      let defaultNewIntentName = t('defaultNewIntentName');
      const uniqueName = Intents.getNewUniquePath(defaultNewIntentName, parentPath, tree);
      clearError();
      IntentsApi.createIntent(accountId, projectShortName, { path: intentName || uniqueName }, sessionId)
        .then(({ data: newIntent }) => {
          setTree(Intents.mergeNewIntentIntoTree(newIntent, tree));
          updateStagingIntents && updateStagingIntents();
        })
        .catch(reason => {
          const errorCode = getErrorCodeFromReason(reason);
          if (
            errorCode.includes('intent_with_provided_name_already_exists') ||
            errorCode.includes('shared_intent_with_provided_name_already_exists')
          ) {
            return createIntent(parentPath, Intents.getNewUniquePath(defaultNewIntentName, parentPath));
          }
          setError(reason);
        });
    },
    [IntentsApi, accountId, clearError, projectShortName, sessionId, setError, t, tree, updateStagingIntents]
  );

  const changeIntentName = (name: string, nodeId: string) => {
    const newNodeId = `${getParentPathFromPath(nodeId)}/${name}`;
    const oldTree = JSON.parse(JSON.stringify(tree));
    clearError();
    if (tree[newNodeId] || !Intents.isValidPath(newNodeId)) {
      setTree({});
      setTimeout(() => {
        setTree(oldTree);
      }, 0);
      return setError(
        !Intents.isValidPath(newNodeId)
          ? 'caila.intents.path_must_not_be_empty'
          : 'caila.db_constraints__intents.intent_with_provided_path_already_exists'
      );
    }
    const intent = {
      ...{ id: oldTree[nodeId].id, enabled: oldTree[nodeId].enabled },
      path: newNodeId,
    };
    setTree(updateIntentPathInTree({ ...tree }, nodeId, newNodeId));
    IntentsApi.recursiveUpdate(accountId, projectShortName, oldTree[nodeId].id, intent, sessionId)
      .then(({ data: updatedIntent }) => {
        if (!selectedIntent || !updatedIntent.updatedIntent) return;
        if (updatedIntent.updatedIntent.id === selectedIntent.id) {
          selectIntent(updatedIntent.updatedIntent.id, updatedIntent.updatedIntent.path);
        }
        updateStagingIntents();
      })
      .catch(e => {
        setError(e);
        setTree(oldTree);
      });
  };

  const confirmDelete = (idsToDelete: number[]) => {
    IntentsApi.deleteIntents(accountId, projectShortName, idsToDelete, sessionId)
      .then(() => {
        setTree(Intents.removeIntentsFromTree(idsToDelete, tree));
        updateStagingIntents();
      })
      .catch(setError);
  };

  const handleSearch = (value: string) => {
    clearError();
    load(
      IntentsApi.searchIntents(accountId, projectShortName, {
        path: value,
        phrases: value,
        description: value,
        answer: value,
        slots: value,
      })
        .then(({ data: intents }) => {
          const tree = Intents.mapIntentsToTree(intents);
          setTree(tree);
        })
        .catch(error => {
          console.error('Error searching intents', error);
          return setError(error);
        })
    );
  };

  const debouncedSearch = debounce(handleSearch, 500);

  return (
    <div style={{ display: 'flex', height: '100%', width: '100%' }} {...restProps}>
      <Sidebar minWidth={200} initialWidth={250} maxWidth={400} style={{ border: 0 }} resizeDirection='right'>
        <IntentsTree
          selectedIntentPaths={selectedIntentPaths}
          handleSelect={handleSelect}
          createIntent={createIntent}
          tree={tree}
          whiteBackground
          onChangeName={changeIntentName}
          confirmDelete={confirmDelete}
          handleSearch={debouncedSearch}
        />
      </Sidebar>
      <div style={{ position: 'relative', width: '100%', backgroundColor: 'var(--white)', color: '#555555' }}>
        {typeof errorMessage === 'string' ? (
          <div data-test-id='IntentSelector.ErrorText' style={{ color: 'red', marginLeft: 20 }}>
            {errorMessage}
          </div>
        ) : null}
        <Spinner hidden={!isLoading} size='4x' />
        <ScrollArea style={{ height: 530, padding: '0 20px' }} vertical>
          {selectedIntent ? (
            <>
              <h3 data-test-id='IntentSelector.IntentName' style={{ color: 'var(--gray-950)' }}>
                {selectedIntent.name}
              </h3>
              <Separator />
              <Spoiler
                data-test-id='IntentSelector.DescriptionSpoiler'
                key={`intent-description_${selectedIntent.id}`}
                Header={() => (
                  <span
                    className='ll-intents-selector_spoiler_header'
                    data-test-id='IntentSelector.intentDescriptionCaption'
                  >
                    {t('intentDescriptionCaption')}
                  </span>
                )}
                withWrapper
                initiallyOpened={Boolean(selectedIntent.description)}
              >
                <p className='ll-intents-selector_spoiler_description' data-test-id='IntentSelector.Description'>
                  {selectedIntent.description}
                </p>
              </Spoiler>
              <Separator />
              <Spoiler
                key={`intent-answer_${selectedIntent.id}`}
                Header={() => <span className='ll-intents-selector_spoiler_header'>{t('intentAnswerCaption')}</span>}
                withWrapper
                initiallyOpened={Boolean(selectedIntent.answer)}
                data-test-id='IntentSelector.AnswerSpoiler'
              >
                <p className='ll-intents-selector_spoiler_description' data-test-id='IntentSelector.Description.answer'>
                  {selectedIntent.answer}
                </p>
              </Spoiler>
              <Separator />
              <Spoiler
                key={`intent-phrases_${selectedIntent.id}`}
                Header={() => (
                  <span className='ll-intents-selector_spoiler_header' data-test-id='IntentSelector.header'>
                    {t('intentPhrasesCaption')}
                  </span>
                )}
                withWrapper
                initiallyOpened={Boolean(
                  (selectedIntent.phrases && selectedIntent.phrases.length > 0) ||
                    (selectedIntent.patterns && selectedIntent.patterns.length > 0)
                )}
                data-test-id='IntentSelector.PhrasesSpoiler'
              >
                <ul className='ll-intents-selector_spoiler_phrases'>
                  {selectedIntent.phrases
                    ? selectedIntent.phrases.map((phrase, i) => (
                        <li key={`phrases_${phrase.text}_${i}`} data-test-id='IntentSelector.TrainingPhrases'>
                          {phrase.text}
                        </li>
                      ))
                    : null}
                  {selectedIntent.patterns
                    ? selectedIntent.patterns.map((pattern, i) => <li key={`phrases_${pattern}_${i}`}>{pattern}</li>)
                    : null}
                </ul>
              </Spoiler>
            </>
          ) : null}
        </ScrollArea>
      </div>
    </div>
  );
};

export default IntentsSelector;
