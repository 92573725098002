import Konva from 'konva';

import { TagNames, TJBlock, TTagParameters } from './types';
import { NodeConfig, Node } from 'konva/lib/Node';
import { findParentScreenName } from '../hooks';

import { scan, shareReplay, Subject } from 'rxjs';
import { cacheScheduler } from 'utils/sheduler/buildScheduler';

type CustomGroup = {
  width: () => number;
  height: () => number;
};

export const getGroupRectSize = (konvaGroup: Konva.Group | null) => {
  const sizeRect = {
    width: 0,
    height: 0,
  };
  konvaGroup?.children?.forEach(child => {
    let innerChild: Konva.Group | CustomGroup = child;
    if (child.getType() === 'Group') {
      const gSizeRect = getGroupRectSize(innerChild as Konva.Group);
      innerChild = { width: () => gSizeRect.width, height: () => gSizeRect.height } as CustomGroup;
    }
    let childWidthWithOffset = child.x() + innerChild.width();
    if (sizeRect.width < childWidthWithOffset) {
      sizeRect.width += childWidthWithOffset - sizeRect.width;
    }

    let childHeightWithOffset = child.y() + innerChild.height();
    if (sizeRect.height < childHeightWithOffset) {
      sizeRect.height += childHeightWithOffset - sizeRect.height;
    }
  });

  return sizeRect;
};

export const getScreenFromEvent = (event: Konva.KonvaEventObject<MouseEvent>) => {
  const target = event.target;
  let parent = target.parent;
  while (parent && parent.attrs.isScreen) {
    parent = parent.parent;
  }

  return parent;
};

export const getIsScreenFromRef = (target?: Konva.Node | null) => {
  if (!target) return false;
  let targetNode: Node | null = target;
  while (targetNode) {
    if (
      targetNode.attrs.hasOwnProperty('isScreen') &&
      ['IncomingItem', 'OutgoingItem', 'screen', 'ChildState'].includes(targetNode?.attrs.type)
    ) {
      break;
    }
    targetNode = targetNode.parent;
  }
  return Boolean(targetNode?.attrs.isScreen && targetNode?.attrs.type === 'screen');
};

class ImagesCache {
  imagesSet: { [key: string]: HTMLImageElement } = {};

  load = (src: string, onLoadCallback: (element: HTMLImageElement) => unknown) => {
    if (this.imagesSet[src]) {
      onLoadCallback && onLoadCallback(this.imagesSet[src]);
      return this.imagesSet[src];
    }

    const image = document.createElement('img');
    image.src = src;
    image.style.display = 'none';
    image.addEventListener('load', () => {
      this.imagesSet[src] = image;
      onLoadCallback && onLoadCallback(image);
      document.body.removeChild(image);
      return image;
    });
    image.addEventListener('error', () => {
      document.body.removeChild(image);
    });

    document.body.appendChild(image);
  };

  has = (src: string) => {
    return this.imagesSet[src];
  };
}

export const ICache = new ImagesCache();

export const getRandomAnswers = (blocks: TJBlock[] = []): string[] => {
  return blocks.map(block => {
    switch (block.tagName) {
      case TagNames.a: {
        return block.tagValue || '';
      }
      case TagNames.audio: {
        const parameters = block.tagParameters;
        const audioNameIndex = parameters.findIndex((param: { name: string }) => param.name === 'name');
        if (audioNameIndex > -1) {
          return (parameters[audioNameIndex].value as string) || 'audio';
        }
        return block.tagValue || '';
      }
      default:
        return '';
    }
  });
};

export const getAboveGroupsHeightsAndOffsets = (onlyGroups: Node<NodeConfig>[]) => {
  return onlyGroups.reduce(
    (prevValue, currentValue) => {
      const height = getGroupRectSize(currentValue as Konva.Group).height;
      const currentGroupOffset = (currentValue as Konva.Group).y();
      prevValue.offsetY += currentGroupOffset - prevValue.offsetY - prevValue.height;
      prevValue.height += height;

      return prevValue;
    },
    { height: 0, offsetY: 0 }
  );
};

export const callBlocksRedraw = (selfRef: Konva.Rect | Konva.Group | null, stage: Konva.Stage | null) => {
  if (!selfRef || !stage) return;
  const parent = selfRef.parent;
  const [parentScreenName, stageBlock] = findParentScreenName(selfRef);
  if (!stageBlock) return;
  let nodesWithCalculateOffsetAttr = stageBlock?.find<Konva.Group>(
    (node: Konva.Node) => node.getType() === 'Group' && node.attrs.hasOwnProperty('calculateOffset')
  );
  nodesWithCalculateOffsetAttr.sort((a, b) => {
    if (b.isAncestorOf(a)) {
      //parent should calc after children
      return -1;
    }
    if (a.isAncestorOf(b)) {
      //parent should calc after children
      return 1;
    }
    return 0;
  });

  /*const debugContainer = document.getElementById('debug');
  if (debugContainer) {
    debugContainer.innerHTML = '';
    const stage = GStage.get();
    const debugRect = stage.findOne('.DedugRect');
    //@ts-ignore
    debugRect.hide();
    nodesWithCalculateOffsetAttr.forEach(element => {
      const div = document.createElement('div');
      div.addEventListener('mouseenter', () => {
        const position = element.getAbsolutePosition(stage);
        debugRect.x(position.x);
        debugRect.y(position.y);
        debugRect.height(element.height() || 50);
        debugRect.width(element.width() || 220);
        debugRect.show();

        stage.batchDraw();
      });
      div.addEventListener('mouseleave', () => {
        debugRect.hide();

        stage.batchDraw();
      });
      const textNode = element.findOne((node: Konva.Node) => {
        return !!node.attrs.text;
      });
      div.innerText = `${textNode?.attrs.text} ${element._id}`;
      div.classList.add('btn', 'btn-primary', 'btn-sm');
      debugContainer.appendChild(div);
    });
  }*/

  const parentSelfIndex = nodesWithCalculateOffsetAttr.findIndex(group => group._id === parent?._id);
  if (nodesWithCalculateOffsetAttr && parentSelfIndex < nodesWithCalculateOffsetAttr.length - 1) {
    nodesWithCalculateOffsetAttr.forEach(someParent => {
      requestAnimationFrame(() => {
        if (someParent.name().startsWith('ConditionBody')) {
          someParent.children && someParent.children[0] && someParent.children[0].attrs.calculateHeight();
        }
        someParent.attrs.calculateOffset();
      });
    });
  }

  if (stageBlock) {
    clearCache(stageBlock);
    const EventsGroupAutosizeRect = stageBlock.findOne('.EventsGroupAutosizeRect');
    const MainParentAutosizeRect = stageBlock.findOne('.MainParentAutosizeRect');
    const StatesGroupAutosizeRect = stageBlock.findOne('.StatesGroupAutosizeRect');
    requestAnimationFrame(() => {
      MainParentAutosizeRect?.attrs.calculateHeight();
      EventsGroupAutosizeRect?.attrs.calculateHeight();
      StatesGroupAutosizeRect?.attrs.calculateHeight();
      cacheGroup(stageBlock);
    });
  }
  const ConnectionsLayer = stage.children ? stage.children[0] : null;
  if (ConnectionsLayer) {
    (ConnectionsLayer.children as Konva.Path[])?.forEach(connector => {
      const from = connector.getAttr('from');
      const to = connector.getAttr('to');
      if (
        connector.className === 'Path' &&
        (from === parentScreenName || to === parentScreenName) &&
        connector.attrs.hasOwnProperty('calculatePath')
      ) {
        requestAnimationFrame(() => {
          const newPath = connector.attrs.calculatePath();
          if (!!newPath) {
            connector.data(newPath);
          }
        });
      }
    });
  }
};

export type StateStorePositionSize = {
  width: number;
  height: number;
  x: number;
  y: number;
  nameValue: string;
  stageId: string;
};
export type StatesStore = Record<string, StateStorePositionSize | undefined>;
export const StatesStore$ = new Subject<{
  type: 'addOrRemove';
  value: StatesStore;
}>();

export const StatesStorePipe$ = StatesStore$.pipe(
  scan((prevValue, newValue) => {
    let returnValue = prevValue;
    switch (newValue.type) {
      case 'addOrRemove': {
        Object.entries(newValue.value).forEach(([key, value]) => {
          returnValue[key] = value;
        });
        break;
      }
    }
    return returnValue;
  }, {} as StatesStore),
  shareReplay(1)
);

export function updateStatesStore(group: Konva.Group) {
  const stage = group.getStage();
  if (!stage) return;

  const size = getGroupRectSize(group);

  StatesStore$.next({
    type: 'addOrRemove',
    value: {
      [group.attrs.path]: {
        stageId: stage.attrs.id,
        width: size.width,
        height: size.height,
        ...group.position(),
        nameValue: group.attrs.nameValue,
      },
    },
  });
}

export const stateRemoves = (statePath: string) => {
  StatesStore$.next({
    type: 'addOrRemove',
    value: {
      [statePath]: undefined,
    },
  });
};

export const clearCache = (group: Konva.Group | null) => {
  if (group) {
    group.clearCache();
    updateStatesStore(group);
  }
};
export const cacheGroup = (group: Konva.Group) => {
  cacheScheduler(() => {
    const cache = group.cache({ pixelRatio: 2 });
    if (cache) {
      updateStatesStore(group);
    }
  });
};

export const isActivationToStateByTagParam = (tagParam: Record<string, TTagParameters<string, any>>) => {
  return !!(tagParam.toState && tagParam.toState.value);
};

export const getIntersection = (
  pointerPosition: Konva.Vector2d,
  statesPosition: StatesStore,
  currentTarget: Konva.Node
) => {
  for (const [statePath, coords] of Object.entries(statesPosition)) {
    const currentTargetStage = currentTarget.getStage();
    if (coords && currentTargetStage && currentTargetStage.attrs.id === coords.stageId) {
      //check objects are on the same stage
      if (currentTarget.attrs.nameValue !== coords.nameValue) {
        //check if target is not dragging on self
        if (
          pointerPosition.x > coords.x &&
          pointerPosition.x < coords.x + coords.width &&
          pointerPosition.y > coords.y &&
          pointerPosition.y < coords.y + coords.height
        ) {
          return {
            statePath,
            nameValue: coords.nameValue,
            coords,
          };
        }
      }
    }
  }
  return undefined;
};
