import React, { useContext, useEffect, useState, useCallback } from 'react';
import localize from '../../../localization';
import { InputGroup, InputText } from '@just-ai/just-ui';
import { AnalyticsContext } from '@just-ai/analytic-front';
import { IconButton } from '@just-ai/just-ui/dist';

export function APITokens({ editableProject }) {
  const [analyticToken, setAnalyticToken] = useState(null);
  const [currentPasswordType, setCurrentPasswordType] = useState({ analytic: true });
  const {
    getAnalyticsTokenByProjectShortName,
    deleteAnalyticsTokenByProjectShortName,
    createAnalyticsToken,
  } = useContext(AnalyticsContext);

  useEffect(() => {
    getAnalyticsTokenByProjectShortName(editableProject.shortName)
      .then(value => setAnalyticToken(value))
      .catch(() => {
        return createAnalyticsToken(editableProject.shortName).then(value => setAnalyticToken(value));
      });
  }, [createAnalyticsToken, editableProject.shortName, getAnalyticsTokenByProjectShortName]);

  const recreateAnalyticToken = useCallback(async () => {
    await deleteAnalyticsTokenByProjectShortName(editableProject.shortName);
    const token = await createAnalyticsToken(editableProject.shortName);
    setAnalyticToken(token);
  }, [createAnalyticsToken, deleteAnalyticsTokenByProjectShortName, editableProject.shortName]);

  const copyAnalyticToken = useCallback(() => {
    navigator.clipboard && navigator.clipboard.writeText(analyticToken);
  }, [analyticToken]);

  return (
    <>
      <div className='form-group row' style={{ maxWidth: '100%', margin: 0 }}>
        <label className='col-md-2 form-control-label' htmlFor='accessToken'>
          {localize.translate('ProjectEditForm tabs reporter api')}
        </label>
        <div className='col-md-9'>
          <InputGroup
            AppendInner={[
              {
                name: 'farEye',
                onClick: () => setCurrentPasswordType(state => ({ ...state, analytic: !state.analytic })),
              },
              {
                name: 'faCopy',
                onClick: copyAnalyticToken,
              },
            ]}
          >
            <InputText
              withEvent
              type={currentPasswordType['analytic'] ? 'password' : 'text'}
              value={analyticToken}
              className='skip-readonly'
              readOnly
              onChange={(value, e) => {}}
              onFocus={e => e.preventDefault()}
            />
          </InputGroup>
        </div>
        <div className='col-md-1'>
          <IconButton color='danger' name='farSyncAlt' flat onClick={recreateAnalyticToken} />
        </div>
      </div>
    </>
  );
}
