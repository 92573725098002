import React, { FC, memo, useCallback, useEffect, useRef } from 'react';
import { useForceUpdate } from '@just-ai/just-ui';
import Konva from 'konva';

import { getStageContextFromRef } from '../../utils/stageUtils';
import { allBlocksSetted, applyIslandConnectorOffsets } from '../../utils/connectionLayerUtils';

import { getIsScreenFromRef } from '../../utils/blockLayerUtils';
import { IslandConnector } from './IslandConnector';
import { ConnectorProps } from './index';

export const IslandConnectors: FC<ConnectorProps> = memo(({ connector }) => {
  const { from, to, deferred, fromNode, fromNodeOriginalPath, toNodeOriginalPath } = connector;
  const LabelFromRef = useRef<Konva.Label | null>(null);
  const groupFromRef = useRef<Konva.Group | null>(null);
  const groupToRef = useRef<Konva.Group | null>(null);
  const LabelToRef = useRef<Konva.Label | null>(null);
  const fromNodeRef = useRef<Konva.Node | null | undefined>(null);
  const toNodeRef = useRef<Konva.Node | null | undefined>(null);
  const isToNodeScreen = useRef(true);
  const isFromScreen = useRef(true);

  const setPositionsHandler = useCallback(() => {
    const { GStage } = getStageContextFromRef(LabelFromRef);
    if (!GStage) return;

    isToNodeScreen.current = !!toNodeRef.current?.attrs.isScreen;
    isFromScreen.current = getIsScreenFromRef(fromNodeRef.current);

    let fromPosition = fromNodeRef.current?.getAbsolutePosition(GStage);
    let toPosition = toNodeRef.current?.getAbsolutePosition(GStage);
    if (!fromPosition || !toPosition) return;
    const { fromPosition: newFromPos, toPosition: newToPos } = applyIslandConnectorOffsets(fromPosition, toPosition, {
      isToNodeScreen: isToNodeScreen.current,
    });
    groupFromRef?.current?.attrs.updatePosition(newFromPos);
    groupToRef?.current?.attrs.updatePosition(newToPos);
  }, []);

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (!LabelFromRef.current) return;
    const ctx = getStageContextFromRef(LabelFromRef);

    const sub = ctx.connectorsFromStore$.subscribe(store => {
      if (!allBlocksSetted(store, from, to, fromNode) || !ctx.GStage) {
        groupFromRef?.current?.hide();
        groupToRef?.current?.hide();
        return;
      }

      if (!groupFromRef?.current?.visible()) {
        groupFromRef?.current?.clearCache();
        groupFromRef?.current?.show();
      }
      if (!groupToRef?.current?.visible()) {
        groupToRef?.current?.clearCache();
        groupToRef?.current?.show();
      }

      fromNodeRef.current = store[from].fromRef || store[fromNode].fromRefFallBack;
      toNodeRef.current = store[to!].toRef;

      isToNodeScreen.current = !!toNodeRef.current?.attrs.isScreen;
      isFromScreen.current = getIsScreenFromRef(fromNodeRef.current);

      forceUpdate();
      //should calculate after stage redraw
      setPositionsHandler();
      setTimeout(() => {
        setPositionsHandler();
      }, 100);
    });
    return () => sub.unsubscribe();
  }, [
    forceUpdate,
    deferred,
    from,
    fromNode,
    setPositionsHandler,
    to,
    connector.fromNodeOriginalPath,
    connector.toNodeOriginalPath,
  ]);

  return (
    <>
      <IslandConnector
        groupRef={groupFromRef}
        labelRef={LabelFromRef}
        setPositionsHandler={setPositionsHandler}
        connector={connector}
        isOutgoing={true}
        fromCircleNode={fromNodeRef.current}
        isFromScreen={isFromScreen.current}
        isToNodeScreen={false}
        text={toNodeOriginalPath}
      />
      <IslandConnector
        groupRef={groupToRef}
        labelRef={LabelToRef}
        setPositionsHandler={setPositionsHandler}
        connector={connector}
        isOutgoing={false}
        toScreenNode={toNodeRef.current}
        isFromScreen={false}
        isToNodeScreen={isToNodeScreen.current}
        text={fromNodeOriginalPath}
      />
    </>
  );
});

IslandConnectors.displayName = 'IslandConnectors';
