import { useState, useEffect } from 'react';

import { ProjectDtoWithReplacedFields } from '@just-ai/api/dist/services/ProjectService';

const getBotTestIdFromProject = (project: ProjectDtoWithReplacedFields): string | undefined =>
  project.botChannels?.find(({ channelType }) => channelType === 'TEST_WIDGET')?.botserverBotId;

export default function useTestWidgetBotId(currentProjectProperties: ProjectDtoWithReplacedFields): string | undefined {
  const [botId, setBotId] = useState<string>();

  useEffect(() => {
    if (!currentProjectProperties) return;
    const botId = getBotTestIdFromProject(currentProjectProperties);
    if (!botId) return;
    setBotId(botId);
  }, [currentProjectProperties]);

  return botId;
}
