import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ProductType } from '@just-ai/api/dist/generated/Audit';
import isAccess, { isInternal, isSystemAccess, isSAdmin } from '../../isAccessFunction';
import localize from '../../localization';
import ProjectEditForm from '../../views/ProjectEditForm';
import { IconButton, Icon, Popover, Dropdown, DropdownItem } from '@just-ai/just-ui';
import { setProjectToEdit } from '../../actions/projects.actions';
import { cloudDomainsService } from '../../services/CloudDomainsService';
import './Settings.scss';

const { translate } = localize;

const DropdownLink = ({ testId, text, link }) => {
  return (
    <DropdownItem className='settings-item' style={{ padding: '0' }}>
      <Link to={{ pathname: link }} data-test-id={testId}>
        <span className='nav-item-name'>{text}</span>
      </Link>
    </DropdownItem>
  );
};

export const Settings = ({
  currentProject,
  currentProjectProperties,
  editableProject,
  isEdit,
  reduxSetProjectToEdit,
  currentUser,
}) => {
  let dropdown = useRef(null);

  const [dropdownOpen, setOpen] = useState(false);
  const accountId = isSAdmin() ? currentUser?.logInAccount?.accountId : currentUser?.account?.id;

  const setProjectToEdit = useCallback(() => {
    if (currentProjectProperties) {
      reduxSetProjectToEdit(currentProjectProperties);
      setOpen(false);
    }
  }, [currentProjectProperties, reduxSetProjectToEdit]);

  const handleClickOutside = useCallback(e => {
    if (!dropdown.current?.contains(e.target)) {
      setOpen(false);
    }
  }, []);

  const handleAuditClick = useCallback(() => {
    if (!accountId) return;
    window.open(cloudDomainsService.getFullAuditPath(accountId, [ProductType.PLATFORM, ProductType.GENERAL]), '_blank');
  }, [accountId]);

  useEffect(() => {
    window.addEventListener('open_settings_current_project', setProjectToEdit);
    return () => window.addEventListener('open_settings_current_project', setProjectToEdit);
  }, [setProjectToEdit]);

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isEdit, dropdownOpen, handleClickOutside]);

  const toggle = useCallback(
    event => {
      if (event?.stopPropagation) event.stopPropagation();
      setOpen(x => !x);
    },
    [setOpen]
  );

  return (
    <div ref={dropdown}>
      {isAccess('PROJECTS_WRITE') && editableProject && editableProject.id > 0 && isEdit && (
        <ProjectEditForm toggle={() => {}} open={true} />
      )}
      <IconButton
        className='navbar-btn'
        id='settings-popover'
        name='farCog'
        onClick={toggle}
        data-test-id='userHeader.settingsDropdown.cog'
      />

      <Popover
        isOpen={dropdownOpen}
        placement='bottom'
        target='settings-popover'
        className='header-menu-popover help-menu-popover Settings_container'
        data-test-id='userHeader.settingsDropdown.popover'
      >
        <Dropdown className='header-menu' isOpen={true} toggle={toggle} data-test-id='userHeader.settingsDropdown'>
          {(isAccess(['ROLES_WRITE']) || isAccess(['ROLES_READ']) || Boolean(currentUser?.accountOwner)) && (
            <a
              className='settings-item'
              data-test-id='userHeader.settingsDropdown.account'
              href={`${cloudDomainsService.getFullDomainPath('cc')}/c/accounts/${accountId}`}
            >
              {translate('Account')}
            </a>
          )}
          {currentUser?.accountOwner && (
            <DropdownLink
              testId='userHeader.settingsDropdown.projectReports'
              link='/project-reports'
              text={translate('Sidebar:ProjectReports')}
            />
          )}
          {(isAccess(['SPECIFIC_ACCOUNT_MANAGEMENT']) || isAccess(['ACCOUNTS_TARIFF_READ'])) &&
            isSystemAccess(['botadmin_billing_feature']) && (
              <DropdownLink
                link='/accountmanager'
                icon={<Icon name='faUserCircle' size='sm' />}
                data-test-id='unprojectedSidebar.userlogNavLink'
                text={localize.translate('Sidebar: tariff and limits')}
              />
            )}
          {isAccess('PROJECTS_WRITE') && currentProject && (
            <DropdownItem
              className='settings-item'
              style={{ width: '100%', height: '100%', padding: '0.5rem 1rem' }}
              onClick={() => {
                setProjectToEdit();
                toggle();
              }}
              data-test-id='userHeader.settingsDropdown.usersNavLink'
            >
              <span className='nav-item-name' style={{ color: 'var(--gray-800)' }}>
                {translate('Project Properties')}
              </span>
            </DropdownItem>
          )}
          <DropdownLink
            testId='userHeader.settingsDropdown.imputer'
            link='/imputer'
            text={translate('Imputer: sidebar label')}
          />

          {isAccess('SPECIFIC_ACCOUNT_MANAGEMENT') && (
            <DropdownLink
              testId='userHeader.settingsDropdown.tokens'
              link='/tokens'
              text={translate('TokensManagement')}
            />
          )}

          <DropdownLink
            link='/tagsettings'
            testId='userHeader.settingsDropdown.tagSettingsNavLink'
            text={translate('Directories of tags')}
          />
          <DropdownLink
            link='/calllists'
            testId='userHeader.settingsDropdown.callListNavLink'
            text={translate('Client lists')}
          />
          {(isAccess('SIP_TRUNK_READ') || isInternal()) && isSystemAccess(['botadmin_telephony_feature']) && (
            <DropdownLink
              link='/connectionslist'
              testId='userHeader.settingsDropdown.telephonySettingsNavLink'
              text={translate('Telephony settings')}
            />
          )}
          {isAccess('AUDIT_READ') && accountId && (
            <DropdownItem
              className='settings-item'
              onClick={handleAuditClick}
              data-test-id='userHeader.settingsDropdown.userLogNavLink'
            >
              <span className='nav-item-name'>{translate('User log')}</span>
            </DropdownItem>
          )}
          {isAccess('MASKING_RULE_READ') && (
            <DropdownLink
              link='/logsmasking'
              icon={<Icon name='farShieldAlt' size='sm' className='btn-icon left gray' />}
              testId='userHeader.settingsDropdown.logsmaskingNavLink'
              text={translate('Logs:LogsMasking.navLink')}
            />
          )}
        </Dropdown>
      </Popover>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    currentProject: state.CurrentProjectsReducer.currentProject,
    currentProjectProperties: state.CurrentProjectsReducer.currentProjectProperties,
    editableProject: state.EditProjectsReducer.editableProject,
    isEdit: state.EditProjectsReducer.isEdit,
    currentUser: state.CurrentUserReducer.currentUser,
  };
}

const mapDispatchToProps = dispatch => ({
  reduxSetProjectToEdit: bindActionCreators(setProjectToEdit, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings));
