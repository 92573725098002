import React, { FC, useCallback, useMemo } from 'react';
import { useRightSideMenuContext } from '../index';
import { TActivationParameters, TagNames, TTagParameters, TJBlock } from '../../../utils/types';
import EventActivationEdit from '../RSAddingMenu/ActivationsAddingMenu/EventActivationEdit';

export const ActivationEventSettings: FC = () => {
  const { editMenuScreen, jBlockIndex, onChangeCommonTag } = useRightSideMenuContext();
  const block = editMenuScreen.blocks[jBlockIndex!] as TJBlock | undefined;
  const tagParameters = block?.tagParameters;
  const mapTagParametersToObj = useMemo(() => {
    let obj: Record<string, TTagParameters<string, any>> = {};
    if (!tagParameters) return obj;
    tagParameters.forEach(tagParam => {
      obj[tagParam.name] = tagParam;
    });
    return obj;
  }, [tagParameters]);

  const activationEvent = block?.tagValue || '';

  const selectActivationEvent = useCallback(
    (value: string) => {
      onChangeCommonTag({
        tagValue: value,
      });
    },
    [onChangeCommonTag]
  );
  const changeEventParams = (name: string) => (value: boolean | string) => {
    let newTagParameters = [] as TActivationParameters;
    let isGlobal = activationEvent === TagNames.event_;
    if (name === 'isGlobal') {
      //if activation is global there is no parameters
      if (value) {
        isGlobal = true;
        newTagParameters = [];
      } else {
        if (mapTagParametersToObj.onlyThisState) {
          mapTagParametersToObj.onlyThisState.value = null;
        } else {
          mapTagParametersToObj.onlyThisState = {
            name: 'onlyThisState',
            value: null,
            required: false,
          };
        }
        if (mapTagParametersToObj.fromState) {
          mapTagParametersToObj.fromState.value = '';
        } else {
          mapTagParametersToObj.fromState = {
            name: 'fromState',
            value: '',
            required: false,
          };
        }
        if (mapTagParametersToObj.toState) {
          mapTagParametersToObj.toState.value = '';
        } else {
          mapTagParametersToObj.toState = {
            name: 'toState',
            value: '',
            required: false,
          };
        }

        newTagParameters = Object.keys(mapTagParametersToObj).map(
          objKey => mapTagParametersToObj[objKey]
        ) as TActivationParameters;
      }
    }

    if (name === 'onlyThisState') {
      if (mapTagParametersToObj.onlyThisState) {
        mapTagParametersToObj.onlyThisState.value = value;
      } else {
        mapTagParametersToObj.onlyThisState = {
          name: 'onlyThisState',
          value: value,
          required: false,
        };
      }
      newTagParameters = Object.keys(mapTagParametersToObj).map(
        objKey => mapTagParametersToObj[objKey]
      ) as TActivationParameters;
    }

    if (name === 'fromState') {
      if (mapTagParametersToObj.fromState) {
        mapTagParametersToObj.fromState.value = value;
      } else {
        mapTagParametersToObj.fromState = {
          name: 'fromState',
          value: value,
          required: false,
        };
      }
      newTagParameters = Object.keys(mapTagParametersToObj).map(
        objKey => mapTagParametersToObj[objKey]
      ) as TActivationParameters;
    }
    if (name === 'toState') {
      if (mapTagParametersToObj.toState) {
        mapTagParametersToObj.toState.value = value;
      } else {
        mapTagParametersToObj.toState = {
          name: 'toState',
          value: value,
          required: false,
        };
      }
      newTagParameters = Object.keys(mapTagParametersToObj).map(
        objKey => mapTagParametersToObj[objKey]
      ) as TActivationParameters;
    }

    onChangeCommonTag({
      tagName: isGlobal ? TagNames.event_ : TagNames.event,
      tagParameters: newTagParameters,
    });
  };

  return (
    <div className='JGraph-RightSideMenu-commonContainer'>
      <EventActivationEdit
        isFromState={false} //TODO
        isToState={!!mapTagParametersToObj.toState?.value}
        activationEvent={activationEvent}
        onChange={selectActivationEvent}
        isGlobal={block?.tagName === TagNames.event_}
        onChangeIsGlobal={changeEventParams('isGlobal')}
        onlyThisState={mapTagParametersToObj.onlyThisState?.value}
        onChangeOnlyThisState={changeEventParams('onlyThisState')}
        fromStateValue={mapTagParametersToObj.fromState?.value || ''}
        toStateValue={mapTagParametersToObj.toState?.value || ''}
        onChangeFromStateValue={changeEventParams(mapTagParametersToObj.toState?.value ? 'toState' : 'fromState')}
      />
    </div>
  );
};
