import React, { Fragment, useCallback, useMemo } from 'react';
import localize from '../../../localization';
import { Button, FormText, Icon, InputText, Select, JustSelect, Textarea, MessagesTypes } from '@just-ai/just-ui';
import isAccess, { isConfigEnabled } from '../../../isAccessFunction';
import { DropdownMenu } from 'reactstrap';

import { Popohover } from 'views/TariffPage/Popohover';

export function BaseDescription({
  editableProject,
  changeLanguage,
  selectedLanguage,
  nameRef,
  descriptionRef,
  languages,
  customStyles,
  changeProjectTemplate,
  selectedProjectTemplate,
  onValidateForm,
  isEdit,
  useExternalRepository,
  botTemplates,
  filteredTemplates,
  initialCode,
  initialCodeValues,
  changeInitialCode,
  setActiveTab,
}) {
  const selectedProjectTemplateSummary = useMemo(
    () =>
      Boolean(selectedProjectTemplate)
        ? botTemplates.find(botTemplate => botTemplate.name === selectedProjectTemplate.value).summary
        : null,
    [botTemplates, selectedProjectTemplate]
  );

  const botTemplatesOptions = useMemo(
    () =>
      filteredTemplates.map(botTemplate => {
        return { value: botTemplate.name, label: botTemplate.title };
      }),
    [filteredTemplates]
  );

  const blurHandler = useCallback(() => {
    //this workaround is needed to let onClick work before onblur when clicking on cancel button in modal
    return setTimeout(onValidateForm, 500);
  }, [onValidateForm]);

  const initialCodeOptions = useMemo(
    () =>
      initialCodeValues
        .filter(code => code.value !== 'external')
        .map(code => ({
          ...code,
          description: localize.translate(`jaicf initial code ${code.value} template help text`),
        })),
    [initialCodeValues]
  );

  const CustomOption = ({ value, label, description }) => (
    <div className='initial-code-option'>
      <p>{label}</p>
      <span>{description}</span>
    </div>
  );

  return (
    <Fragment>
      <div className='form-group row'>
        <label className='col-md-4 form-control-label' htmlFor='name'>
          {localize.translate('ProjectName')}
          <span className='red-dot'>*</span>
        </label>
        <div className='col-md-8'>
          <InputText
            type='text'
            name='name'
            maxLength='30'
            id='name'
            required
            placeholder={localize.translate('Description')}
            defaultValue={Boolean(editableProject) ? editableProject.name : ''}
            innerRef={nameRef}
            onChange={onValidateForm}
            onBlur={blurHandler}
            data-test-id='projectsPage.createProjectModal.nameInputText'
            autoFocus
          />
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-md-4 form-control-label' htmlFor='accessToken'>
          {localize.translate('Project Description')}
        </label>
        <div className='col-md-8'>
          <Textarea
            name='description'
            id='description'
            rows={4}
            placeholder={localize.translate('Project Description')}
            defaultValue={Boolean(editableProject) ? editableProject.description : ''}
            innerRef={descriptionRef}
            data-test-id='projectsPage.createProjectModal.descriptionInputTextarea'
          />
        </div>
      </div>

      {isAccess(['BOT_CONTENT_READ', 'nlu']) && !!languages.length && (
        <div className='form-group row select-access'>
          <label className='col-md-4 form-control-label'>
            {localize.translate('caila project language')}
            <Icon color='primary' name='faInfoCircle' size='sm' id='cailaLanguage' />
            <Popohover className='project-language-popover' target='cailaLanguage' closable={false}>
              <p>{localize.translate('caila project language tooltip')}</p>
              <p>{localize.translate('caila project language tooltip list header')}</p>
              <ul>
                <li>{localize.translate('caila project language tooltip list item1')}</li>
                <li>{localize.translate('caila project language tooltip list item2')}</li>
                <li>{localize.translate('caila project language tooltip list item3')}</li>
              </ul>
              {isConfigEnabled('showHelp') && (
                <a href={localize.translate('caila project language tooltip link')}>
                  <Button color='primary'>{localize.translate('caila project language tooltip btn caption')}</Button>
                </a>
              )}
            </Popohover>
          </label>
          <div className='col-md-8'>
            <JustSelect
              options={languages}
              closeable={false}
              onChange={el => changeLanguage({ label: el[0], value: el[0] })}
              value={selectedLanguage?.value}
              fullWidth
              styles={customStyles}
              position='fixed'
              data-test-id='projectsPage.createProjectModal.languageSelect'
              messages={{
                [MessagesTypes.NOT_FOUND]: localize.translate('BotControls:not_found'),
              }}
            />
          </div>
        </div>
      )}

      {!isEdit && (
        <div className='form-group row'>
          <label className='col-md-4 form-control-label form-control-label-jaicf'>
            {localize.translate('project initial code')}
          </label>
          <div className='col-md-8'>
            <Select
              options={initialCodeOptions}
              styles={customStyles}
              isSearchable={false}
              onChange={changeInitialCode}
              value={initialCodeValues.find(code => code.value === initialCode)}
              dropdownComponent={DropdownMenu}
              menuPosition='fixed'
              data-test-id='projectsPage.createProjectModal.initialCodeSelect'
              separatedOptions
              formatOptionLabel={CustomOption}
              className='initial-code-select'
              noOptionsMessage={() => localize.translate('BotControls:not_found')}
            />
            <FormText tag='small'>
              <p
                dangerouslySetInnerHTML={{
                  __html: localize.translate(
                    `jaicf initial code ${initialCode} template help text${
                      initialCode === 'connect' ? ' with link' : ''
                    }`,
                    localize.translate('ProjectEditForm tabs placement')
                  ),
                }}
                onClick={() => initialCode === 'connect' && setActiveTab('2')}
              />
            </FormText>
            {initialCode === 'system' && (
              <>
                <Select
                  options={botTemplatesOptions}
                  isMulti={false}
                  isClearable
                  isDisabled={isEdit}
                  onChange={changeProjectTemplate}
                  placeholder={localize.translate('Choose template')}
                  value={selectedProjectTemplate}
                  data-test-id='projectsPage.createProjectModal.templatesSelect'
                  menuPosition='fixed'
                  noOptionsMessage={() => localize.translate('BotControls:not_found')}
                />
                <FormText tag='small'>{selectedProjectTemplateSummary}</FormText>
              </>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
}
