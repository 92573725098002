import { GroupingSummaryData, GroupingRequestData, MethodEnum } from '@just-ai/api/dist/generated/Caila';

export enum LabelingMethod {
  'keywords' = 'keywords',
  'clusters' = 'clusters',
  'intents' = 'intents',
  'duplicates' = 'duplicates',
}

export const mapMethodToFrontendTypes = (rawMethod?: MethodEnum): LabelingMethod => {
  switch (rawMethod?.toLowerCase()) {
    case MethodEnum.KmeansClustering.toLowerCase():
    case MethodEnum.LinkageClustering.toLowerCase():
    case MethodEnum.StsdbscanClustering.toLowerCase():
      return LabelingMethod.clusters;
    case MethodEnum.TfidfKeywords.toLowerCase():
    case MethodEnum.UdpipeKeywords.toLowerCase():
      return LabelingMethod.keywords;
    case MethodEnum.Classification.toLowerCase():
      return LabelingMethod.intents;
    default:
      return LabelingMethod.duplicates;
  }
};

export type GroupingTreeNode = {
  nodeId: string;
  id: number;
  name: string;
  completed: boolean;
  groupsCount: number;
  groupedPhrasesCount: number;
  parameters: GroupingRequestData;
  error?: string;
  createdBySystem?: boolean;
};
export type GroupingsDataset = {
  [nodeId: string]: GroupingTreeNode;
};

export const SOURCE_GROUPING_NAME = '_sys.unnamed_grouping_';
export const UNRECOGNIZED_GROUPING_NAME = 'NotRecognizedMessages';
export const SOURCE_GROUPING_NODE: GroupingTreeNode = {
  nodeId: SOURCE_GROUPING_NAME,
  id: NaN,
  name: SOURCE_GROUPING_NAME,
  completed: true,
  parameters: {},
  groupsCount: 0,
  groupedPhrasesCount: 0,
};

export const toDataset = (groupings: GroupingSummaryData[], fromDialogs?: boolean): GroupingsDataset => {
  const dataset = {} as GroupingsDataset;
  if (!fromDialogs) {
    addNode(dataset, SOURCE_GROUPING_NODE);
  }
  return groupings.reduce(addGroupingMutably, dataset);
};

export const GROUPING_WITHOUT_ID = '_sys.grouping_without_id_';
export const toNode = (grouping: GroupingSummaryData): GroupingTreeNode => ({
  nodeId: grouping.groupingId ? prefixed(grouping.groupingId) : GROUPING_WITHOUT_ID,
  id: Number(grouping.groupingId),
  name: grouping.name!,
  parameters: grouping.parameters ? grouping.parameters : {},
  completed: Boolean(grouping.completed),
  error: grouping.error,
  groupsCount: grouping.groupsCount ? grouping.groupsCount : 0,
  groupedPhrasesCount: grouping.groupedPhrasesCount ? grouping.groupedPhrasesCount : 0,
  createdBySystem: grouping.createdBySystem,
});

export const addGrouping = (dataset: GroupingsDataset, grouping: GroupingSummaryData) =>
  addNode({ ...dataset }, toNode(grouping));

export const removeGrouping = (dataset: GroupingsDataset, grouping: GroupingTreeNode) =>
  removeNode({ ...dataset }, grouping);

const addGroupingMutably = (dataset: GroupingsDataset, grouping: GroupingSummaryData) =>
  addNode(dataset, toNode(grouping));

const ID_PREFIX = 'grouping_';
const isPrefixed = (id: string) => id.startsWith(ID_PREFIX);
export const prefixed = (id: string | number) =>
  typeof id === 'number' || !isPrefixed(id) ? `${ID_PREFIX}${id}` : `${id}`;
export const clearPrefix = (id: string) => (isPrefixed(id) ? Number(id.slice(ID_PREFIX.length)) : Number(id));

const addNode = (dataset: GroupingsDataset, node: GroupingTreeNode) => Object.assign(dataset, { [node.nodeId]: node });
const removeNode = (dataset: GroupingsDataset, node: GroupingTreeNode) => {
  const updatedData = { ...dataset };
  delete updatedData[node.nodeId];
  return updatedData;
};

export const allCompleted = (groupings: GroupingsDataset) =>
  Object.values(groupings).every(grouping => grouping.completed);
