import React, { Component, createRef } from 'react';
import ReactDOM from 'react-dom';
import { IAceEditor } from 'react-ace/lib/types';
import { t } from 'localization';

import './EditorPopup.scss';

interface PopupOption {
  label: string;
  value: any;
}

interface PopupConfig<OPTION extends PopupOption> {
  editor: IAceEditor;
  position: { column: number; row: number };
  align?: 'top' | 'bottom' | 'left' | 'right';
  options?: OPTION[];
}

interface PopupResult<OPTION> {
  selected: OPTION | null;
}

interface EditorPopupState {
  isOpened: boolean;
  loading: boolean;
  position: { x: number; y: number } | null;
  config?: PopupConfig<any>;
}

const instanceContainer: { value?: EditorPopupCmp; EditorPopupCmp: typeof EditorPopupCmp } = {} as any;

class EditorPopupCmp extends Component<{}, EditorPopupState> {
  private resolvePromise: ((result: PopupResult<any>) => void) | null = null;
  private editor?: IAceEditor;
  private popupRef = createRef<HTMLDivElement>();

  state: EditorPopupState = {
    isOpened: false,
    loading: false,
    position: null,
  };

  componentDidMount() {
    instanceContainer.value = this;
  }

  public open<OPTION extends PopupOption>(config: PopupConfig<OPTION>): Promise<PopupResult<OPTION>> {
    this.close();

    return new Promise(resolve => {
      this.resolvePromise = resolve;
      if (!config.editor) {
        console.error('[EditorPopup] Editor not found');
        resolve({ selected: null });
        return;
      }

      this.editor = config.editor;
      const textCoords = config.editor.renderer.textToScreenCoordinates(config.position.row, config.position.column);

      this.setState(
        {
          isOpened: true,
          config,
          position: { x: textCoords.pageX, y: textCoords.pageY },
        },
        () => this.placePopupByCurrentPosition()
      );

      setTimeout(() => {
        document.addEventListener('click', this.outsideClickHandler);
        this.editor?.session.on('changeScrollTop', this.close);
      }, 0);
    });
  }

  public toggleLoadingSpinner(isOpen: boolean) {
    this.setState({ loading: isOpen }, () => this.placePopupByCurrentPosition());
  }

  private placePopupByCurrentPosition() {
    if (!this.popupRef.current || !this.state.config || !this.state.position) return;

    const popupRect = this.popupRef.current.getBoundingClientRect();
    const offset = 0;

    const { x, y } = this.state.position;

    let left: number;
    let top: number;
    switch (this.state.config?.align) {
      case 'top':
        top = y - popupRect.height - offset;
        left = x - popupRect.width / 2;
        break;
      case 'bottom':
        top = y + offset;
        left = x - popupRect.width / 2;
        break;
      case 'left':
        left = x - popupRect.width - offset;
        top = y - popupRect.height / 2;
        break;
      case 'right':
        left = x + offset;
        top = y - popupRect.height / 2;
        break;
      default:
        top = y + offset;
        left = x - popupRect.width / 2;
    }

    this.popupRef.current.style.left = `${left}px`;
    this.popupRef.current.style.top = `${top}px`;
  }

  public close = (): void => {
    this.setState({
      isOpened: false,
      loading: false,
    });

    document.removeEventListener('click', this.outsideClickHandler);
    this.editor?.session.off('changeScrollTop', this.close);
    this.resolvePromise = null;
  };

  private outsideClickHandler = (e: MouseEvent): void => {
    if (this.popupRef.current && !this.popupRef.current.contains(e.target as Node)) {
      this.close();
    }
  };

  private renderOptions() {
    const { config } = this.state;
    if (!config?.options) return null;

    if (!this.state.loading && config.options.length === 0) {
      return <div className='noFound'>{t('No matches')}</div>;
    }
    return (
      <ul className='options'>
        {config.options.map((option, index) => (
          <li key={index} title={option.label} onClick={() => this.handleOptionSelect(option)}>
            {option.label}
          </li>
        ))}
      </ul>
    );
  }

  private handleOptionSelect(option: PopupOption) {
    this.resolvePromise?.({ selected: option });
    this.close();
  }

  render() {
    const { isOpened, loading } = this.state;
    if (!isOpened) return null;

    return ReactDOM.createPortal(
      <div ref={this.popupRef} id='editor-popup'>
        <div id='editor-popup-container'>{this.renderOptions()}</div>
        {loading && (
          <div id='editor-popup-loading'>
            <div className='loader' />
          </div>
        )}
      </div>,
      document.body
    );
  }
}
instanceContainer.EditorPopupCmp = EditorPopupCmp;

export const EditorPopup = instanceContainer;
