import { Cords, Vector2D } from './2DVector';

export type Rect = { x: number; y: number; width: number; height: number };

export function getOutlineRect(nodes: Cords[]) {
  if (nodes.length === 0) return;
  const firstNode = nodes[0];
  return nodes.reduce(
    (acc, val) => {
      if (val.x < acc.sx) acc.sx = val.x;
      if (val.y < acc.sy) acc.sy = val.y;
      if (val.x > acc.ex) acc.ex = val.x;
      if (val.y > acc.ey) acc.ey = val.y;
      return acc;
    },
    { sx: firstNode.x, sy: firstNode.y, ex: firstNode.x, ey: firstNode.y }
  );
}

export function scaleFromCenterRect(rect: Rect, scale: Cords): Rect {
  const scaleVector = Vector2D.fromObj(scale);
  const center = Vector2D.from(rect.x + rect.width / 2, rect.y + rect.height / 2);
  const newSize = scaleVector.multiply(Vector2D.from(rect.width, rect.height));
  const pos = center.subtract(newSize.divide(2));
  return {
    x: pos.x,
    y: pos.y,
    width: newSize.x,
    height: newSize.y,
  };
}
