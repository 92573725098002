import React, { FC, useEffect, useState } from 'react';

import { buildScheduler } from 'utils/sheduler/buildScheduler';

import { hiddenConnectionsPipe$ } from '../../hooks/hiddenConnections';
import { SplineConnector } from './SplineConnector';
import { TConnector } from '../../../../reducers/JGraph.reducer/types';
import { IslandConnectors } from './IslandConnectors';

export const islandConnectorShowScheduler = buildScheduler({ maxTimeout: 20, scheduleFn: cb => setTimeout(cb, 16) });

export type ConnectorProps = {
  connector: TConnector;
};

export const Connector: FC<ConnectorProps> = React.memo(({ connector }) => {
  const { toNodeOriginalPath, fromNodeOriginalPath } = connector;

  const [isIslandConnector, setIslandConnector] = useState(false);

  useEffect(() => {
    let rafCall: { cancel: Function };
    const sub = hiddenConnectionsPipe$.subscribe(value => {
      rafCall?.cancel();
      const isNeedToShow = value.to.has(toNodeOriginalPath) || value.from.has(fromNodeOriginalPath);
      if (isNeedToShow) {
        rafCall = islandConnectorShowScheduler(() => setIslandConnector(true));
      } else {
        setIslandConnector(false);
      }
    });

    return () => sub.unsubscribe();
  }, [fromNodeOriginalPath, toNodeOriginalPath]);

  if (isIslandConnector) return <IslandConnectors connector={connector} />;

  return <SplineConnector connector={connector} />;
});
