import React, { RefObject, useCallback, useEffect } from 'react';
import { Layer, Stage } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';
import Konva from 'konva';
import cn from 'classnames';
import { Spinner, useBehaviorSubject, Portal } from '@just-ai/just-ui';

import { t } from 'localization';

import { useAppSelector, useAppDispatch } from 'storeHooks';

import { getCenterOfStage, setScale } from 'modules/JGraph/utils/stageUtils';
import SearchState from 'modules/JGraph/view/SearchState.feature';

import StageObservablesProvider, { useStageObservableContext } from 'modules/JGraph/contexts/StageObservablesProvider';
import { JGraphTheme, JStateWithId } from 'reducers/JGraph.reducer/types';
import {
  JGLS,
  saveThemeMovement,
  setSelectedTheme,
  openThemeCreationMenu,
  closeThemeCreationMenu,
  openContextMenu,
} from 'reducers/JGraph.reducer';
import moveThemeApi from 'reducers/JGraph.reducer/AsyncActions/moveThemeApi';
import { onStageClickAsync } from 'reducers/JGraph.reducer/JGraphAsyncActions';

import { useStageActions } from 'modules/JGraph/hooks/useStageActions';
import { AutoLayoutProgressSubject$ } from '../../hooks/useAutoplacement';
import { lazyActionsSubject, useStageSizes } from '../../hooks';
import { mainSave$ } from '../../hooks/savingPipe';
import { JGToolbar, JGToolbarIconButton } from '../JGToolbar';

import AutoPlacementButton from '../AutoPlacementButton';

import ThemesContextMenu from '../ThemesContextMenu';

import ThemesConnectionsLayer from './ThemesConnectionsLayer';
import ThemeCard, { themePositionSaving$ } from './ThemeCard';

import JGToolbarClasses from '../JGToolbar/JGToolbar.module.scss';
import { CommitButtonWithContext } from '../CommitButton';
import { OtherHudActions } from '../OtherHudActions';
import RenderingBlockWrapper from '../RenderingModal/RenderingBlockWrapper';
import { useJGraphRestorePosition } from '../../hooks/useJGraphRestorePosition';

interface ThemesStageProps {
  containerRef: RefObject<HTMLDivElement>;
}

const ThemesStageID = 'ThemesStageID';

type ThemesStageInnerProps = {
  size: { width: number; height: number };
  onDragEnd: (ev: KonvaEventObject<WheelEvent | DragEvent>) => void;
  themes: JGraphTheme[];
  states: JStateWithId[];
  onSelectTheme: (theme: JGraphTheme) => void;
};
const ThemesStageInner = React.forwardRef(
  ({ onDragEnd, size, themes, states, onSelectTheme }: ThemesStageInnerProps, ref: React.ForwardedRef<any>) => {
    const observableProps = useStageObservableContext();

    const stageGetRef = useCallback(
      Stage => {
        if (window.isUnderTest) {
          //@ts-ignore
          window.jgStage = Stage;
        }
        observableProps.getStage(Stage);
        // @ts-ignore
        ref.current = Stage;
      },
      [observableProps, ref]
    );

    const dispatch = useAppDispatch();
    const onStageClick = useCallback(
      (event: KonvaEventObject<MouseEvent>) => {
        dispatch(onStageClickAsync({ event }));
      },
      [dispatch]
    );

    const openContextMenuHandler = useCallback(
      (event: Konva.KonvaEventObject<PointerEvent>, theme: JGraphTheme) => {
        event.cancelBubble = true;
        event.evt.preventDefault();
        event.evt.stopPropagation();

        const stage = event.currentTarget.getStage();
        if (!stage) return;

        dispatch(closeThemeCreationMenu());

        const pointerPosition = {
          x: event.evt.pageX,
          y: event.evt.pageY,
        };
        dispatch(
          openContextMenu({
            screenPosition: pointerPosition,
            pointerPosition,
            themePath: theme.value,
          })
        );
      },
      [dispatch]
    );

    return (
      <Stage
        width={size.width}
        height={size.height}
        isStage={true}
        onClick={onStageClick}
        draggable
        ref={stageGetRef}
        onDragEnd={onDragEnd}
        onWheel={onDragEnd}
        ctx={observableProps}
        stagePath={ThemesStageID}
      >
        <ThemesConnectionsLayer themes={themes} states={states} />
        <Layer isLayer={true}>
          {themes.map((theme, index) => (
            <ThemeCard
              key={theme.value + index}
              theme={theme}
              states={states}
              onSelectTheme={onSelectTheme}
              onContextMenu={openContextMenuHandler}
            />
          ))}
        </Layer>
      </Stage>
    );
  }
);
const stickers: any[] = [];
const themesLayerScreens: any[] = [];

const ThemesStage = ({ containerRef }: ThemesStageProps) => {
  const dispatch = useAppDispatch();
  const { themes, states, allScreens, themeCreationMenu, loadingGraph, loadingCustomTags, error } = useAppSelector(
    state => ({
      themes: state.JGraphReducer.graph.themes,
      states: state.JGraphReducer.graph.blocks,
      allScreens: state.JGraphReducer.graph.blocks,
      themeCreationMenu: state.JGraphReducer.themeCreationMenu,
      loadingGraph: state.JGraphReducer.loadingGraph,
      loadingCustomTags: state.JGraphReducer.loadingCustomTags,
      error: state.JGraphReducer.error,
    })
  );

  const loaderShowed = React.useRef<boolean>(false);

  useEffect(() => {
    if (!loaderShowed.current && !(loadingGraph || loadingCustomTags) && !error) {
      RenderingBlockWrapper.State$.next({ cancellable: false, title: t('RenderingModal:BuildingScenarioTitle') });

      RenderingBlockWrapper.Percentage$.next({
        type: 'start',
        total: themes.filter(theme => theme.hasOwnProperty('canRender')).length,
        startTime: Date.now(),
      });
      loaderShowed.current = true;
    }
  }, [error, loadingCustomTags, loadingGraph, themes]);

  useEffect(
    () => () => {
      loaderShowed.current = false;
    },
    []
  );

  const stage = React.useRef<Konva.Stage | null>(null);

  const size = useStageSizes(containerRef.current);
  const setScaleAndSave = useCallback((ev: KonvaEventObject<WheelEvent | DragEvent>) => {
    if (!ev.evt?.type) return;
    const [newScale, newPosition] =
      ev.evt.type === 'wheel'
        ? setScale(ev as KonvaEventObject<WheelEvent>)
        : [stage.current!.scaleX(), stage.current!.position()];
    if (ev.evt.type === 'wheel' || !!ev.target.attrs.isStage) {
      const settings = {
        stageScale: newScale,
        stagePosition: newPosition,
      };
      lazyActionsSubject.next({
        type: 'saveVisualStageFileSettings',
        action: async () => JGLS.store.saveStagePathSettings(settings, ThemesStageID),
      });
    }
  }, []);

  const onSelectTheme = useCallback(
    (theme: JGraphTheme) => {
      if (!theme.value) return;
      dispatch(setSelectedTheme({ value: theme.value }));
    },
    [dispatch]
  );

  useEffect(() => {
    const sub = themePositionSaving$.subscribe(moveInfo => {
      const movePayload = { newPositions: moveInfo.pos, themeValue: moveInfo.theme.value };
      dispatch(saveThemeMovement(movePayload));
      mainSave$.next({
        type: 'themeMove',
        path: moveInfo.theme.value,
        action: () => dispatch(moveThemeApi(movePayload)),
      });
    });
    return () => sub.unsubscribe();
  }, [dispatch]);

  const autoLayoutProgress = useBehaviorSubject(AutoLayoutProgressSubject$);

  const openCreationMenu = useCallback(
    (event: React.SyntheticEvent) => {
      if (!themeCreationMenu.open) {
        if (stage.current) {
          event.stopPropagation();
          const themePosition = getCenterOfStage(stage.current) || { x: 0, y: 0 };
          const targetPosition = event.currentTarget.getBoundingClientRect();
          dispatch(
            openThemeCreationMenu({
              themePosition,
              pointerPosition: {
                x: targetPosition.right + 18,
                y: targetPosition.top + 22,
              },
              isOpenedByButton: true,
            })
          );
        }
      }
    },
    [dispatch, themeCreationMenu.open]
  );

  const isGlobalSearchOpenedByButton = useBehaviorSubject(SearchState.GlobalSearchOpenedByButton$);
  const stageHudActions = useStageActions(stage);

  const isNeedToRestorePosition =
    !loadingGraph && !loadingCustomTags && (!autoLayoutProgress.status || autoLayoutProgress.status === 'done');
  useJGraphRestorePosition(isNeedToRestorePosition, true, ThemesStageID, [], themes, stage);

  return (
    <div>
      {autoLayoutProgress.status === 'pending' && <Spinner />}
      <ThemesContextMenu />
      <Portal targetNodeSelector='#JGHud-stage-ui'>
        <div className='jgraph-toolbar-container'>
          <div className='vertical-container'>
            <div className='horizontal-container'>
              <JGToolbar type='horizontal'>
                <JGToolbarIconButton
                  className={cn(JGToolbarClasses.jgToolbarButton, {
                    active: isGlobalSearchOpenedByButton,
                  })}
                  id='JGToolbar_Search'
                  iconName='farSearch'
                  onClick={stageHudActions.actions.openSearch}
                  data-test-id='JGToolbar:Search'
                  tooltip={t('JGToolbar:Search')}
                  placement='bottom'
                />
                <CommitButtonWithContext className={JGToolbarClasses.jgToolbarButton} />
                <OtherHudActions
                  className={JGToolbarClasses.jgToolbarButton}
                  options={[
                    {
                      label: t('JGToolbar:Export'),
                      icon: 'farArrowToBottom',
                      dataTestId: 'JGToolbar:Export',
                      onClick: stageHudActions.actions.createThemesPagePng,
                    },
                    {
                      label: t('JGToolbar:Help'),
                      dataTestId: 'JGToolbar:Help',
                      icon: 'faQuestionCircle',
                      link: t('JGToolbar:Help:Url'),
                    },
                  ]}
                />
                {stageHudActions.nodes}
              </JGToolbar>
            </div>
            <JGToolbar type='vertical'>
              <JGToolbarIconButton
                className={cn(JGToolbarClasses.jgToolbarButton, {
                  active: themeCreationMenu.open && themeCreationMenu.isOpenedByButton,
                })}
                iconName='farShapes'
                onMouseDown={openCreationMenu}
                data-test-id='JGToolbar:ShowMenuButton'
                id={`${ThemesStageID}_ShowMenuButton`}
                tooltip={t('JGToolbar:NewTheme')}
                placement='right'
              />
            </JGToolbar>
            <JGToolbar type='vertical'>
              <AutoPlacementButton stageInnerId={ThemesStageID} classname={JGToolbarClasses.jgToolbarButton} />
            </JGToolbar>
          </div>
        </div>
      </Portal>
      <StageObservablesProvider
        isThemesStage
        screens={themesLayerScreens}
        allScreens={allScreens}
        stickers={stickers}
        themes={themes}
      >
        <ThemesStageInner
          size={size}
          ref={stage}
          onDragEnd={setScaleAndSave}
          themes={themes}
          states={states}
          onSelectTheme={onSelectTheme}
        />
      </StageObservablesProvider>
    </div>
  );
};

ThemesStage.displayName = 'ThemesStage';

export default React.memo(ThemesStage);
