import { GroupsDataset, GroupInfo } from '../../../utils/groups';
import { MethodEnum, PhraseIndexWithConfidence, PhraseStatusData } from '@just-ai/api/dist/generated/Caila';
import { useReducer, Dispatch, Reducer } from 'react';
import { PhraseItem, PhrasesDataset } from '../../../utils/phrases';
import GroupingReducer from './grouping.reducer';

export enum LogLabelingMode {
  SOURCE,
  STAGING,
  SIMPLE,
  KEYWORDS,
  INTENTS,
  UNRECOG,
}

export type ConfidenceThreshold = {
  min: number;
  max: number;
};

export type LogLabelingState<PhrasesType = PhraseIndexWithConfidence> = {
  allPhrases: PhraseItem[];
  allGroups: GroupsDataset;
  filteredAllPhrases: PhrasesDataset;
  filteredAllGroups: GroupsDataset;
  stagedPhrases: PhraseItem[];
  phrases: PhrasesDataset;
  groups: GroupsDataset<PhrasesType>;
  isTree: boolean;
  withTopN: boolean;
  multipleSelectByClick: boolean;
  selectedGroupIds: string[];
  mode: LogLabelingMode;
  showLabeled: boolean;
  hideConflicts: boolean;
  phrasesFilter: string;
  phrasesConfidenceThreshold: ConfidenceThreshold;
};
export const LOG_LABELING_INITIAL_STATE: LogLabelingState = Object.freeze({
  allPhrases: [],
  allGroups: {},
  filteredAllPhrases: {},
  filteredAllGroups: {},
  stagedPhrases: [],
  phrases: {},
  groups: {},
  isTree: false,
  withTopN: false,
  multipleSelectByClick: false,
  selectedGroupIds: [],
  mode: LogLabelingMode.SOURCE,
  showLabeled: false,
  hideConflicts: false,
  phrasesFilter: '',
  phrasesConfidenceThreshold: { min: 0, max: 1 },
});

export type LogLabelingAction =
  | {
      type: 'SET_METHOD';
      method: MethodEnum | 'source' | 'staging';
      allGroups?: GroupInfo[];
      allPhrases?: string[];
      statuses?: PhraseStatusData[];
      stagedPhrases?: PhraseItem[];
    }
  | { type: 'SET_PHRASES'; phrases: PhrasesDataset }
  | { type: 'SET_PHRASES_FILTER'; value: string }
  | { type: 'SET_PHRASES_CONFIDENCE_THRESHOLD'; value: ConfidenceThreshold }
  | { type: 'SELECT_GROUPS'; groupIds: string[] }
  | { type: 'DELETE_PHRASES'; indexes: string[] }
  | { type: 'STAGE_PHRASES'; indexes: string[]; intentId?: number }
  | { type: 'CLEAR_PHRASES'; indexes: string[] }
  | { type: 'UPDATE_INTENTS'; allGroups?: GroupInfo[] }
  | { type: 'APPLY_STAGED_PHRASES' }
  | { type: 'TOGGLE_SHOW_LABELED' }
  | { type: 'TOGGLE_HIDE_CONFLICTS' }
  | {
      type: 'INIT';
      allGroups: GroupInfo[];
      allPhrases?: string[];
      statuses?: PhraseStatusData[];
      stagedPhrases?: PhraseItem[];
    };
export type LogLabelingDispatchAction = Dispatch<LogLabelingAction>;

export type LogLabelingReducer<PhraseType = PhraseIndexWithConfidence> = Reducer<
  LogLabelingState<PhraseType>,
  LogLabelingAction
>;
export type Reducers = {
  [key in LogLabelingMode]: LogLabelingReducer;
};

export const useGroupingReducer = () => useReducer(GroupingReducer, LOG_LABELING_INITIAL_STATE);
