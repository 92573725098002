import {
  CHANGE_USER_TARIFF_PENDING,
  CHANGE_USER_TARIFF_FULFILLED,
  CHANGE_USER_TARIFF_REJECTED,
  CHANGE_USER_LOCK_PENDING,
  CHANGE_USER_LOCK_FULFILLED,
  CHANGE_USER_LOCK_REJECTED,
  SET_USER_HISTORY_FILTER,
  DROP_ERRORS,
  FETCH_AVAILABLE_OPTIONS_DATA_PENDING,
  FETCH_AVAILABLE_OPTIONS_DATA_FULFILLED,
  FETCH_AVAILABLE_OPTIONS_DATA_REJECTED,
  FETCH_USER_DATA_FULFILLED,
  FETCH_USER_DATA_PENDING,
  FETCH_USER_DATA_REJECTED,
  ADD_MINUTES_FULFILLED,
  ADD_MINUTES_PENDING,
  ADD_MINUTES_REJECTED,
  ADD_PACKAGE_PENDING,
  ADD_PACKAGE_FULFILLED,
  ADD_PACKAGE_REJECTED,
  FETCH_OWNER_DATA_PENDING,
  FETCH_OWNER_DATA_FULFILLED,
  FETCH_OWNER_DATA_REJECTED,
  FETCH_USER_HISTORY_PENDING,
  FETCH_USER_HISTORY_FULFILLED,
  FETCH_USER_HISTORY_REJECTED,
  CHANGE_MANUAL_CONTROL_PENDING,
  CHANGE_MANUAL_CONTROL_FULFILLED,
  CHANGE_MANUAL_CONTROL_REJECTED,
  FETCH_PARTNER_DATA_FULFILLED,
  FETCH_PARTNER_DATA_PENDING,
  FETCH_PARTNER_DATA_REJECTED,
  ADD_PARTNER_FULFILLED,
  ADD_PARTNER_PENDING,
  ADD_PARTNER_REJECTED,
  CHANGE_PARTNER_DATA_FULFILLED,
  CHANGE_PARTNER_DATA_PENDING,
  CHANGE_PARTNER_DATA_REJECTED,
  FETCH_PARTNER_REFERRALS_PENDING,
  FETCH_PARTNER_REFERRALS_REJECTED,
  FETCH_PARTNER_REFERRALS_FULFILLED,
  ADD_PARTNER_REFERRAL_FULFILLED,
  ADD_PARTNER_REFERRAL_PENDING,
  ADD_PARTNER_REFERRAL_REJECTED,
  MOVE_PARTNER_REFERRAL_FULFILLED,
  MOVE_PARTNER_REFERRAL_PENDING,
  MOVE_PARTNER_REFERRAL_REJECTED,
  DISCONNECT_PARTNER_REFERRAL_FULFILLED,
  DISCONNECT_PARTNER_REFERRAL_PENDING,
  DISCONNECT_PARTNER_REFERRAL_REJECTED,
  DROP_REFERRALS_ERRORS,
  DROP_PARTNER_DATA,
  DROP_ACCOUNT_MANAGER_DATA_ERRORS,
  ADD_PACKAGES_PENDING,
  ADD_PACKAGES_FULFILLED,
  ADD_PACKAGES_REJECTED,
  GET_USER_TARIFF_CHANGE_FULFILLED,
  GET_REFERRAL_PARTNER_FULFILLED,
  ADD_PARTNER_TO_REFERRAL_FULFILLED,
  DEDUCT_LIMITS_PENDING,
  DEDUCT_LIMITS_REJECTED,
  DEDUCT_LIMITS_FULFILLED,
} from '../../constants/accountManager.actions';

import { parseErrors } from '../../pipes/functions';
import { LOGOUT_FROM_ACCOUNT_FULFILLED } from '../../constants/accounts.actions';
import safeJsonParse from 'utils/safeJsonParse';

const InitialState = {
  errors: [],
  fetching: false,
  fetchingAccountManagerData: false,
  accountManagerDataErrors: [],
  availableOptionsData: {
    minutesPockets: [],
    tariffs: [],
    uniqueOptions: [],
  },
  partnerData: null,
  referralsData: {
    fetching: false,
    fetched: true,
    referrals: [],
    errors: [],
  },
  blockType: null,
  blocked: false,
  countryIsoCode: null,
  demo: true,
  dueDate: '2019-05-25T00:00:00.000+0000',
  startDate: '2019-04-25T00:00:00.000+0000',
  optionsData: null,
  price: null,
  tariffName: null,
  products: null,
  tariffUniqueName: null,
  nextTariffName: null,
  nextTariffUniqueName: null,
  manualControl: false,

  owner: null,

  accountHistory: {
    fetching: false,
    fetched: false,
    errors: [],
    content: [],
    empty: true,
    first: true,
    last: true,
    number: 0,
    numberOfElements: 0,
    pageable: null,
    size: 20,
    sort: null,
    totalElements: 0,
    totalPages: 0,
  },
  limitsData: null,
  addPackagesFetch: false,
  tariffChangeData: null,
  paymentSystem: '',
  relatedPartnerData: null,
  fetchingPartnerModal: false,
};

export default function AccountsReducer(state = InitialState, action) {
  switch (action.type) {
    case LOGOUT_FROM_ACCOUNT_FULFILLED: {
      return {
        ...state,
        tariffName: '',
      };
    }
    case DROP_PARTNER_DATA: {
      return {
        ...state,
        referralsData: InitialState.referralsData,
        partnerData: InitialState.partnerData,
      };
    }

    case DROP_REFERRALS_ERRORS: {
      return {
        ...state,
        referralsData: {
          ...state.referralsData,
          errors: InitialState.referralsData.errors,
        },
      };
    }

    case DISCONNECT_PARTNER_REFERRAL_PENDING:
    case MOVE_PARTNER_REFERRAL_PENDING:
    case ADD_PARTNER_REFERRAL_PENDING:
    case FETCH_PARTNER_REFERRALS_PENDING: {
      return {
        ...state,
        referralsData: {
          ...state.referralsData,
          errors: InitialState.referralsData.errors,
          fetching: true,
          fetched: true,
        },
      };
    }

    case DISCONNECT_PARTNER_REFERRAL_REJECTED:
    case MOVE_PARTNER_REFERRAL_REJECTED:
    case ADD_PARTNER_REFERRAL_REJECTED:
    case FETCH_PARTNER_REFERRALS_REJECTED: {
      const errors = parseErrors(action.payload.response);

      return {
        ...state,
        referralsData: {
          ...state.referralsData,
          errors: errors,
          fetching: false,
          fetched: true,
        },
      };
    }

    case FETCH_PARTNER_REFERRALS_FULFILLED: {
      return {
        ...state,
        referralsData: {
          ...state.referralsData,
          referrals: action.payload.data,
          fetching: false,
          fetched: true,
        },
      };
    }

    case MOVE_PARTNER_REFERRAL_FULFILLED: {
      const newReferrals = [...state.referralsData.referrals];

      const index = newReferrals.findIndex(referral => referral.accountId === action.payload.data.accountId);

      if (index === -1) {
        //move referral into current partner
        newReferrals.push(action.payload.data);
      } else {
        //move referral from current partner
        newReferrals.splice(index, 1);
      }

      return {
        ...state,
        referralsData: {
          ...state.referralsData,
          referrals: newReferrals,
          fetching: false,
          fetched: true,
        },
      };
    }

    case DISCONNECT_PARTNER_REFERRAL_FULFILLED: {
      const newReferrals = [...state.referralsData.referrals];

      const index = newReferrals.findIndex(referral => referral.accountId === action.payload.data.accountId);

      newReferrals.splice(index, 1);

      return {
        ...state,
        relatedPartnerData: null,
        referralsData: {
          ...state.referralsData,
          referrals: newReferrals,
          fetching: false,
          fetched: true,
        },
      };
    }

    case ADD_PARTNER_REFERRAL_FULFILLED: {
      const newReferrals = [...state.referralsData.referrals];

      if (action.payload?.data) {
        newReferrals.push(action.payload.data);
      }

      return {
        ...state,
        referralsData: {
          ...state.referralsData,
          referrals: newReferrals,
          fetching: false,
          fetched: true,
        },
      };
    }

    case FETCH_USER_HISTORY_PENDING: {
      return {
        ...state,
        accountHistory: {
          ...state.accountHistory,
          errors: InitialState.accountHistory.errors,
          fetching: true,
          fetched: false,
        },
      };
    }

    case FETCH_USER_HISTORY_REJECTED: {
      const errors = parseErrors(action.payload.response);

      return {
        ...state,
        accountHistory: {
          ...state.accountHistory,
          errors: errors,
          fetching: false,
          fetched: true,
        },
      };
    }

    case FETCH_USER_HISTORY_FULFILLED: {
      return {
        ...state,
        accountHistory: {
          ...state.accountHistory,
          ...action.payload.data,
          fetching: false,
          fetched: true,
        },
      };
    }

    case FETCH_USER_DATA_PENDING: {
      const existingUserData = safeJsonParse(localStorage.getItem('CURRENT_USER'));
      if (!existingUserData?.tariffData) {
        return {
          ...state,
          fetchingAccountManagerData: true,
        };
      }
      return {
        ...state,
        ...existingUserData.tariffData,
      };
    }
    case ADD_PARTNER_PENDING:
    case CHANGE_PARTNER_DATA_PENDING: {
      return {
        ...state,
        fetchingPartnerModal: true,
      };
    }

    case FETCH_PARTNER_DATA_PENDING:
    case CHANGE_MANUAL_CONTROL_PENDING:
    case FETCH_OWNER_DATA_PENDING:
    case FETCH_AVAILABLE_OPTIONS_DATA_PENDING: {
      return {
        ...state,
        fetchingAccountManagerData: true,
      };
    }

    case FETCH_AVAILABLE_OPTIONS_DATA_FULFILLED: {
      return {
        ...state,
        fetchingAccountManagerData: false,
        availableOptionsData: action.payload.data,
      };
    }

    case ADD_PARTNER_FULFILLED:
    case FETCH_PARTNER_DATA_FULFILLED: {
      return {
        ...state,
        fetchingPartnerModal: false,
        fetchingAccountManagerData: false,
        partnerData: action.payload.data,
      };
    }

    case FETCH_OWNER_DATA_FULFILLED: {
      return {
        ...state,
        fetchingAccountManagerData: false,
        owner: action.payload.data,
      };
    }

    case ADD_PARTNER_REJECTED:
    case CHANGE_PARTNER_DATA_REJECTED:
    case CHANGE_MANUAL_CONTROL_REJECTED:
    case FETCH_OWNER_DATA_REJECTED:
    case FETCH_USER_DATA_REJECTED:
    case FETCH_AVAILABLE_OPTIONS_DATA_REJECTED: {
      const errors = parseErrors(action.payload.response);

      return {
        ...state,
        fetchingPartnerModal: false,
        fetchingAccountManagerData: false,
        accountManagerDataErrors: errors,
      };
    }

    case FETCH_PARTNER_DATA_REJECTED: {
      return {
        ...state,
        fetchingAccountManagerData: false,
      };
    }

    case DROP_ACCOUNT_MANAGER_DATA_ERRORS: {
      return {
        ...state,
        fetchingAccountManagerData: false,
        accountManagerDataErrors: InitialState.accountManagerDataErrors,
      };
    }

    case CHANGE_MANUAL_CONTROL_FULFILLED: {
      return {
        ...state,
        fetchingAccountManagerData: false,
        manualControl: action.payload.data.manualControl,
      };
    }

    case CHANGE_PARTNER_DATA_FULFILLED: {
      return {
        ...state,
        fetchingPartnerModal: false,
        partnerData: action.payload.data,
      };
    }

    case FETCH_USER_DATA_FULFILLED: {
      const existingUserData = safeJsonParse(localStorage.getItem('CURRENT_USER'));
      existingUserData.tariffData = action.payload.data;
      localStorage.setItem('CURRENT_USER', JSON.stringify(existingUserData));
      return {
        ...state,
        fetchingAccountManagerData: false,
        blocked: action.payload.data.blocked,
        blockType: action.payload.data.blockType,
        countryIsoCode: action.payload.data.countryIsoCode,
        demo: action.payload.data.demo,
        dueDate: action.payload.data.dueDate,
        startDate: action.payload.data.startDate,
        optionsData: action.payload.data.optionsData,
        price: action.payload.data.price,
        tariffName: action.payload.data.tariffName,
        products: action.payload.data.products,
        tariffUniqueName: action.payload.data.tariffUniqueName,
        nextTariffName: action.payload.data.nextTariffName,
        nextTariffUniqueName: action.payload.data.nextTariffUniqueName,
        manualControl: action.payload.data.manualControl,
        limitsData: action.payload.data.limitsData,
        hasActiveSubscription: action.payload.data.hasActiveSubscription,
        paymentSystem: action.payload.data.paymentSystem,
      };
    }

    case DROP_ERRORS: {
      return {
        ...state,
        errors: InitialState.errors,
      };
    }

    case ADD_PACKAGE_PENDING:
    case ADD_MINUTES_PENDING:
    case CHANGE_USER_LOCK_PENDING:
    case CHANGE_USER_TARIFF_PENDING: {
      return {
        ...state,
        errors: InitialState.errors,
        fetching: true,
      };
    }

    case ADD_PACKAGE_FULFILLED:
    case ADD_MINUTES_FULFILLED: {
      return {
        ...state,
        fetching: false,
        optionsData: action.payload.data.optionsData,
      };
    }

    case ADD_MINUTES_REJECTED:
    case ADD_PACKAGE_REJECTED: {
      const errors = parseErrors(action.payload.response);

      return {
        ...state,
        fetching: false,
        errors: errors,
      };
    }

    case CHANGE_USER_LOCK_FULFILLED: {
      return {
        ...state,
        fetching: false,
        blocked: action.payload.data.blocked,
        blockType: action.payload.data.blockType,
      };
    }

    case CHANGE_USER_TARIFF_FULFILLED: {
      return {
        ...state,
        fetching: false,
        tariffName: action.payload.data.tariffName,
        products: action.payload.data.products,
        tariffUniqueName: action.payload.data.tariffUniqueName,
        nextTariffName: action.payload.data.nextTariffName,
        nextTariffUniqueName: action.payload.data.nextTariffUniqueName,
        dueDate: action.payload.data.dueDate,
        startDate: action.payload.data.startDate,
        optionsData: action.payload.data.optionsData,
        limitsData: action.payload.data.limitsData,
        price: action.payload.data.price,
        manualControl: action.payload.data.manualControl,
        hasActiveSubscription: action.payload.data.hasActiveSubscription,
        paymentSystem: action.payload.data.paymentSystem,
      };
    }

    case CHANGE_USER_LOCK_REJECTED:
    case CHANGE_USER_TARIFF_REJECTED: {
      const errors = parseErrors(action.payload.response);

      return {
        ...state,
        errors: errors,
        fetching: false,
      };
    }

    case SET_USER_HISTORY_FILTER: {
      return {
        ...state,
        accountHistory: {
          ...state.accountHistory,
          [action.what]: action.value,
          dateFromTimeLimit: action.dateFromTimeLimit,
        },
      };
    }

    case ADD_PACKAGES_PENDING: {
      return {
        ...state,
        addPackagesFetch: true,
      };
    }

    case ADD_PACKAGES_REJECTED:
    case ADD_PACKAGES_FULFILLED: {
      return {
        ...state,
        addPackagesFetch: false,
      };
    }

    case DEDUCT_LIMITS_PENDING: {
      return {
        ...state,
        isLimitsDeductingFetching: true,
      };
    }

    case DEDUCT_LIMITS_REJECTED:
    case DEDUCT_LIMITS_FULFILLED: {
      return {
        ...state,
        isLimitsDeductingFetching: false,
      };
    }

    case GET_USER_TARIFF_CHANGE_FULFILLED: {
      return {
        ...state,
        tariffChangeData: action.payload.data,
      };
    }

    case GET_REFERRAL_PARTNER_FULFILLED: {
      return {
        ...state,
        relatedPartnerData: action.payload.data,
      };
    }

    case ADD_PARTNER_TO_REFERRAL_FULFILLED: {
      return {
        ...state,
        relatedPartnerData: action.payload.data,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
