type SubFnType = (value: any) => void;

export class PubSub<SUBSCRIPTIONS_MAP extends Record<string, any> = Record<string, any>> {
  private subscribers: Record<keyof SUBSCRIPTIONS_MAP, Set<SubFnType>> = {} as any;

  subscribe = <KEY extends keyof SUBSCRIPTIONS_MAP>(key: KEY, subFn: (data: SUBSCRIPTIONS_MAP[KEY]) => void) => {
    (this.subscribers[key] ??= new Set()).add(subFn);
    return () => this.unsubscribe(key, subFn);
  };

  unsubscribe = (key: keyof SUBSCRIPTIONS_MAP, subFn: SubFnType) => {
    if (!this.subscribers[key]) return;
    this.subscribers[key].delete(subFn);
  };

  unsubscribeAll = () => {
    this.subscribers = new Set() as any;
  };

  notify<KEY extends keyof SUBSCRIPTIONS_MAP>(key: KEY, value?: SUBSCRIPTIONS_MAP[KEY]): void {
    const subs = this.subscribers[key];
    if (!subs) return;
    subs.forEach(sub => sub(value));
  }
}
