import { IAceEditor } from 'react-ace/lib/types';
import { Ace } from 'ace-builds';

import { EditorPopup } from '../../components/CodeEditor/components/EditorPopupCmp';
import { EntityASTNode, ScParser, Locator } from '../../components/CodeEditor/parser/ScParser';
import { LinkHighlight } from '../../components/CodeEditor/services/LinkHighlight';
import { AceEditorExtended } from '../../components/CodeEditor/services/AceEditorExtended';
import { EditorMode } from '../../context/EditorContext';
import { EditorCursorPosition } from '../../model/FileTabs';

export function decorateAceEditor(
  editor: IAceEditor,
  filePath: string,
  parser: ScParser,
  goToTextPosition: (fileId: string, mode: EditorMode, position?: EditorCursorPosition) => Promise<unknown>
) {
  parser.parse({ fileName: filePath, content: editor.getValue() });

  const linkHighlighter = new LinkHighlight<EntityASTNode>();
  linkHighlighter.connect(new AceEditorExtended(editor));
  linkHighlighter.loadLinkableNodes(parser.getEntities(filePath));

  const onChange = () => {
    parser.parse({ fileName: filePath, content: editor.getValue() });
    linkHighlighter.loadLinkableNodes(parser.getEntities());
  };
  editor.on('change', onChange);

  async function goToCodePosition(position: Locator) {
    if (filePath === position.fileName) {
      editor.scrollToLine(position.row + 1, true, false, () => {});
      editor.gotoLine(position.row + 1, position.column, false);
      return;
    }
    return goToTextPosition(position.fileName, 'editor', position);
  }

  const handleFindUsage = (linkNode: EntityASTNode, cursorPosition: Ace.Point) => {
    const usage = parser.getUsage(linkNode.entity, linkNode.value);

    EditorPopup.value
      ?.open({
        editor,
        position: cursorPosition,
        align: 'top',
        options: usage.map(el => ({
          label: el.containerPath,
          value: el.containerPath,
          data: el,
        })),
      })
      .then(res => {
        if (!res.selected) return;
        goToCodePosition(res.selected.data.position.start);
        EditorPopup.value?.close();
      });
  };
  const handleFindDeclaration = (linkNode: EntityASTNode) => {
    const declaration = parser.getDeclaration(linkNode.entity, linkNode.value);

    if (declaration) {
      goToCodePosition(declaration.position.start);
      EditorPopup.value?.close();
      return true;
    }
    return false;
  };

  linkHighlighter.subscribe('onLinkClick', ({ linkNode }) => {
    const cursorPosition = editor.getCursorPositionScreen();

    switch (linkNode.type) {
      case 'declaration':
        handleFindUsage(linkNode, cursorPosition);

        EditorPopup.value?.toggleLoadingSpinner(true);
        parser
          .loadAndParseTokensFromWholeScenario()
          .then(() => {
            handleFindUsage(linkNode, cursorPosition);
            EditorPopup.value?.toggleLoadingSpinner(false);
          })
          .catch((err: Error) => {
            if (err.message === 'cancel') return;
            EditorPopup.value?.toggleLoadingSpinner(false);
          });
        break;
      case 'usage':
        if (handleFindDeclaration(linkNode)) return;
        EditorPopup.value?.open({
          editor,
          position: cursorPosition,
          align: 'top',
          options: [],
        });
        EditorPopup.value?.toggleLoadingSpinner(true);
        parser
          .loadAndParseTokensFromWholeScenario()
          .then(() => {
            handleFindDeclaration(linkNode);
            EditorPopup.value?.toggleLoadingSpinner(false);
          })
          .catch((err: Error) => {
            if (err.message === 'cancel') return;
            EditorPopup.value?.toggleLoadingSpinner(false);
          });
        break;
      default:
        break;
    }
  });

  return () => {
    editor.off('change', onChange);
    linkHighlighter.disconnect();
  };
}
