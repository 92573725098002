import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Subject } from 'rxjs';
import Konva from 'konva';
import cn from 'classnames';
import { IRect } from 'konva/lib/types';
import { flip } from '@floating-ui/react-dom';
import { useBehaviorSubject } from '@just-ai/just-ui';

import useFloaterPosition from 'utils/hooks/useFloaterPosition';
import { hideRootSlashInPath } from '../../../utils/state';
import { JGraphHudInfo } from '../../JGraphHud';
import { scrollToTargetGlobal$ } from '../../../utils/stageUtils';

import classes from './styles.module.scss';

type SubjectStateValue = {
  clientRect: IRect;
  values: MenuItemProps['value'][];
  target: Konva.Node;
} | null;

export const CollapsedConnectorsMenuSubject$ = new Subject<SubjectStateValue>();
export const CollapsedConnectorsMenuClose = () => CollapsedConnectorsMenuSubject$.next(null);

type MenuItemProps = { value: { fromPathId?: string; toPathId?: string; fromPath: string; toPath: string } };

function MenuItem({ value }: MenuItemProps) {
  const scrollTo = useCallback((targetPathId?: string) => {
    if (!targetPathId) return;
    scrollToTargetGlobal$.next({ targetPathId, type: 'state' });
    CollapsedConnectorsMenuClose();
  }, []);

  return (
    <div className={classes.CollapsedConnectorsMenu_menu_item}>
      <div
        className={cn(classes.CollapsedConnectorsMenu_menu_item_name, 'text-right')}
        onClick={() => scrollTo(value.fromPathId)}
      >
        <span>{hideRootSlashInPath(value.fromPath)}</span>
      </div>
      <div className={classes.CollapsedConnectorsMenu_menu_item_arrow}>
        <svg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M10.5 4.00488L6.5 1.00488V4.00488V7.00488L10.5 4.00488Z' fill='#888E93' />
          <path
            d='M6.5 4.00488V7.00488L10.5 4.00488L6.5 1.00488V4.00488ZM6.5 4.00488H1.5'
            stroke='#888E93'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </div>
      <div
        className={cn(classes.CollapsedConnectorsMenu_menu_item_name, 'text-left')}
        onClick={() => scrollTo(value.toPathId)}
      >
        <span>{hideRootSlashInPath(value.toPath)}</span>
      </div>
    </div>
  );
}

export const CollapsedConnectorsMenu = memo(() => {
  const [subjectState, setSubjectState] = useState<SubjectStateValue>(null);
  const reference = useRef<HTMLDivElement>(null);
  const floating = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const sub = CollapsedConnectorsMenuSubject$.subscribe(setSubjectState);
    return () => sub.unsubscribe();
  }, []);

  useFloaterPosition({
    enable: !!subjectState,
    floaterElement: floating,
    target: reference.current ?? null,
    options: {
      strategy: 'absolute',
      placement: 'top-start',
      middleware: [flip()],
    },
  });

  useEffect(() => {
    if (!subjectState?.target) return;
    setSubjectState(prev => {
      if (!prev) return prev;
      return { ...prev, clientRect: subjectState.target.getClientRect() };
    });
  }, [subjectState?.target]);

  const hubContainer = useBehaviorSubject(JGraphHudInfo);
  if (!subjectState) return null;

  return (
    <>
      <div
        ref={reference}
        style={{
          position: 'absolute',
          left: subjectState.clientRect.x - hubContainer.position.x,
          top: subjectState.clientRect.y - hubContainer.position.y,
          width: subjectState.clientRect.width,
          height: subjectState.clientRect.height,
        }}
        className={classes.CollapsedConnectorsMenu_ghost}
      >
        <div ref={floating} className={classes.CollapsedConnectorsMenu_menu}>
          <div className={classes.CollapsedConnectorsMenu_menu_container}>
            {subjectState.values.map((value, index) => (
              <MenuItem key={(value.fromPathId || '') + (value.toPathId || '') + index} value={value} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
});
