import { DomainOptions } from '@just-ai/api/dist/generated/Accountsadmin';
import { ProductType } from '@just-ai/api/dist/generated/Audit';

type DomainsType = {
  [key: string]: DomainOptions;
};

class CloudDomainsService {
  private cloudDomains: DomainsType = {};

  setDomains(domains: DomainsType) {
    if (!domains) return;
    this.cloudDomains = domains;
  }

  getProductDomainData(product: string) {
    return this.cloudDomains[product];
  }

  getFullDomainPath(product: string) {
    return `${window.location.protocol}//${this.getProductDomainData(product)?.domain}`;
  }

  getSadminLogoutPath(redirectUrl: string) {
    return `${this.getFullDomainPath('cc')}/api/accountsadmin/c/authorization/change-account-and-redirect?forDomain=${
      this.getProductDomainData('cc')?.domain
    }&redirectUrl=${redirectUrl}`;
  }

  getAuditPath(accountId: number, products: ProductType[]) {
    const base = `/c/accounts/${accountId}`;
    const searchParams = new URLSearchParams();
    searchParams.append('activeTab', 'USER_LOGS');
    products.forEach(product => searchParams.append('product', product));
    return `${base}?${searchParams.toString()}`;
  }

  getFullAuditPath(accountId: number, products: ProductType[]) {
    return `${this.getFullDomainPath('cc')}${this.getAuditPath(accountId, products)}`;
  }

  getCurrentDomainData() {
    if (!this.cloudDomains) return {} as DomainOptions;
    return Object.values(this.cloudDomains).find(domain => domain.domain === window.location.hostname);
  }
}

export const cloudDomainsService = new CloudDomainsService();
