import { AnswerReply } from '@just-ai/just-ui/dist/RichTextEditor/types';
import { IntentPhrase } from '@just-ai/nlu-modules/dist/types';
import { AxiosResponse, AxiosError } from 'axios';
import { t } from 'localization';
import { StringifyOptions } from 'querystring';
import { useCallback, useState } from 'react';
import {
  FaqQuestionData,
  NLUStatusData,
  SimilarAnswerData,
  SimilarPhraseData,
} from '@just-ai/api/dist/generated/Caila';
import { isExistKey } from '../locale/i18nToLocalize';
import { IntentItem } from '../model';

export const CONNECTION_ABORTED_CODE = 'ECONNABORTED';
export const UNKNOWN_ERROR_MESSAGE_CODE = 'UnknownError';
const ERRORS_TRANSLATION_NAMESPACE = 'Errors';

export type ErrorMessage = string | null;
export type SetErrorCallback = (reason: any) => void;
export type ClearErrorCallback = () => void;

interface CommonErrorBody {
  error: string;
  message: string;
}
interface ErrorBody<ErrorType> {
  error: ErrorType;
  errors: ErrorType[];
}

type SpreadsheetArgs = {
  coordinates: {
    column: number;
    row: number;
    type?: StringifyOptions;
  };
  critical: boolean;
  questionHeader?: string;
  groupHeader?: string;
  enabledHeader?: string;
};

type CellArgs = {
  cellValue: string;
};

type SizeArgs = {
  maxSizeInMB: number;
};

type MaxRowsArgs = {
  maxNumberOfRows: number;
};

type HeadersArgs = {
  headers: string;
};

type HeaderArgs = {
  header: string;
};

type ExtensionArgs = {
  extension: string;
};

type PatternArgs = {
  coordinates: SpreadsheetArgs['coordinates'];
  lineNumber: number;
  pattern: string;
  message: string;
};

type MaxRepliesTypeArgs = {
  coordinates: SpreadsheetArgs['coordinates'];
  maxRepliesNumber: string;
};

type MaxPhrasesArgs = {
  phrasesNum: string;
  allowedPhrasesNum: string;
};

type MaxMarkupArgs = {
  markupLength: string;
  markupLengthLimit: string;
};

type ArgsTypes =
  | CellArgs
  | SpreadsheetArgs
  | SizeArgs
  | MaxRowsArgs
  | HeadersArgs
  | HeaderArgs
  | ExtensionArgs
  | PatternArgs
  | MaxRepliesTypeArgs
  | MaxPhrasesArgs
  | MaxMarkupArgs;

const isArgsCoords = (args: ArgsTypes): args is SpreadsheetArgs => Boolean((args as SpreadsheetArgs).coordinates);

const isArgsCell = (args: ArgsTypes): args is CellArgs => Boolean((args as CellArgs).cellValue);

const isArgsSize = (args: ArgsTypes): args is SizeArgs => Boolean((args as SizeArgs).maxSizeInMB);

const isArgsMaxRows = (args: ArgsTypes): args is MaxRowsArgs => Boolean((args as MaxRowsArgs).maxNumberOfRows);

const isArgsHeaders = (args: ArgsTypes): args is HeadersArgs => Boolean((args as HeadersArgs).headers);

const isArgsHeader = (args: ArgsTypes): args is HeaderArgs => Boolean((args as HeaderArgs).header);

const isArgsExtension = (args: ArgsTypes): args is ExtensionArgs => Boolean((args as ExtensionArgs).extension);

const isArgsPattern = (args: ArgsTypes): args is PatternArgs => Boolean((args as PatternArgs).pattern);

const isArgsMaxReplies = (args: ArgsTypes): args is MaxRepliesTypeArgs =>
  Boolean((args as MaxRepliesTypeArgs).maxRepliesNumber);

const isArgsMaxPhrases = (args: ArgsTypes): args is MaxPhrasesArgs => Boolean((args as MaxPhrasesArgs).phrasesNum);

const isArgsMaxMarkup = (args: ArgsTypes): args is MaxMarkupArgs => Boolean((args as MaxMarkupArgs).markupLength);

export type CommonErrorType = {
  args: { critical?: boolean } & ArgsTypes;
  errorCode: string;
  message: string;
};

export const isCommonError = (error: CommonErrorBody | unknown): error is CommonErrorBody => {
  if ((error as CommonErrorBody).message && typeof (error as CommonErrorBody).error === 'string') return true;
  return false;
};

export const useError = (): [ErrorMessage, SetErrorCallback, ClearErrorCallback] => {
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>(null);

  const setErrorCallback = useCallback((reason: any) => {
    setErrorMessage(getErrorMessageFromReason(reason) ?? '');
  }, []);

  const clearErrorCallback = useCallback(() => setErrorMessage(null), []);

  return [errorMessage, setErrorCallback, clearErrorCallback];
};

export const addErrorNamespace = (code: string) => `${ERRORS_TRANSLATION_NAMESPACE}:${code}`;

export const getErrorCodeFromReason = (reason: any): string => {
  if (typeof reason === 'string') return reason;
  if (typeof reason !== 'object' || reason === null) return UNKNOWN_ERROR_MESSAGE_CODE;
  if (reason.code === CONNECTION_ABORTED_CODE) return CONNECTION_ABORTED_CODE;
  if (typeof reason?.response?.data?.error === 'string') return reason.response.data.error;
  if (typeof reason?.response?.data?.error?.errorCode === 'string') return reason.response.data.error.errorCode;
  return UNKNOWN_ERROR_MESSAGE_CODE;
};

//TODO remove parameter after caila error fix ZB-20236
export const getErrorMessageFromReason = (
  reason: any,
  translate?: (key: string, args?: any) => string,
  cailaError?: boolean
): string => {
  const translateFunc = translate || t;
  const errorCode = getErrorCodeFromReason(reason);
  let errorMessage = cailaError ? reason?.response?.data?.error?.message : reason?.response?.data?.message;
  if (reason?.response?.data?.errors && reason?.response?.data?.errors.length > 0 && !errorMessage) {
    if (!errorMessage) {
      errorMessage = '';
    }
    reason.response.data.errors.forEach((error: string) => {
      if (typeof error === 'string') {
        errorMessage += error + '</br>';
      }
    });
  }

  if (errorCode || errorMessage) {
    if (errorMessage === 'No message available' && isExistKey(addErrorNamespace(errorCode)))
      return translateFunc(addErrorNamespace(errorCode));
    if (errorMessage && errorCode === UNKNOWN_ERROR_MESSAGE_CODE) return errorMessage;
    if (isExistKey(addErrorNamespace(errorCode))) {
      try {
        //@ts-ignore
        return translateFunc.apply(this, [
          addErrorNamespace(errorCode),
          ...Object.values(getErrorArgsFromReason(reason) ?? {}),
        ]);
      } catch (e) {
        return translateFunc(addErrorNamespace(errorCode));
      }
    }
    return errorMessage || errorCode || translateFunc(addErrorNamespace(UNKNOWN_ERROR_MESSAGE_CODE));
  }
  return translateFunc(addErrorNamespace(UNKNOWN_ERROR_MESSAGE_CODE));
};

export const getErrorArgsFromReason = (reason: any): Record<string, any> | undefined => {
  if (!reason) return;
  return reason.response?.data?.error?.args || reason.response?.data?.args;
};

export const getNLUErrorFromResponse = (payload: AxiosResponse<NLUStatusData>) => {
  let data = { error: '' };
  const { lastError } = payload.data;
  if (lastError) {
    try {
      data = typeof lastError === 'string' ? JSON.parse(lastError) : { error: lastError };
    } catch (e) {
      // @ts-ignore
      data = { error: lastError || e.toString() };
    }
  }
  return { response: { data } };
};

export const processErrorsArray = (errorsArr: any[] = []) => {
  const translate = t;
  const errorArgNames = ['settings', 'extSettingsAlgorithm', 'classifier', 'tokenizer', 'analyzer', 'id'];
  return errorsArr.map(error =>
    isExistKey(`Errors:${error.errorCode}`)
      ? translate(`Errors:${error.errorCode}`, {
          error: error.args?.[Object.keys(error.args).find(key => errorArgNames.includes(key)) || ''] ?? '',
        })
      : translate('caila set language error')
  );
};

export const getMessageFromError = (args?: CommonErrorType['args'], errorCode?: string) => {
  if (!args) return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode);
  if (isArgsPattern(args)) {
    return t(
      ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode,
      args.coordinates.row,
      args.coordinates.column,
      args.lineNumber,
      args.message
    );
  }

  if (isArgsMaxReplies(args)) {
    return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode, args.maxRepliesNumber, args.coordinates.row);
  }

  if (isArgsMaxPhrases(args)) {
    return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode, args.phrasesNum, args.allowedPhrasesNum);
  }

  if (isArgsMaxMarkup(args)) {
    return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode, args.markupLength, args.markupLengthLimit);
  }

  if (isArgsCoords(args)) {
    return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode, args.coordinates.row, args.coordinates.column);
  }

  if (isArgsCell(args)) {
    return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode, args.cellValue);
  }

  if (isArgsSize(args)) return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode, args.maxSizeInMB);

  if (isArgsMaxRows(args)) return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode, args.maxNumberOfRows);

  if (isArgsHeaders(args)) return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode, args.headers);

  if (isArgsHeader(args)) return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode, args.header);

  if (isArgsExtension(args)) return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode, args.extension);
  if (typeof args === 'object') {
    return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode, ...Object.values(args));
  }
};

export const getMessageTranslationFromImportError = (
  reason: AxiosError<CommonErrorBody | ErrorBody<CommonErrorType>>
) => {
  if (!reason.response) return t('Unexpected error');
  const errorBody = reason.response.data;
  if (isCommonError(errorBody)) {
    return typeof errorBody.error === 'string' && isExistKey(errorBody.error) ? t(errorBody.error) : errorBody.message;
  }
  const { args, errorCode } = errorBody.error;
  if (errorBody.errors.length === 1) {
    return getMessageFromError(args, errorCode);
  }
  return errorBody.errors.map(error => getMessageFromError(error.args, error.errorCode)).join('\n\n');
};

export const getDeletedPhrasesMessage = (count: number, language: string) => {
  const intl = new Intl.PluralRules(language);

  const phrasesDeclination = intl.select(count);
  if (count === 1) return t('LogLabelingPage:deletedPhrasesInfo_one');
  return phrasesDeclination === 'one'
    ? t(
        'LogLabelingPage:deletedPhrasesInfo_one--with-number',
        count,
        t(`LogLabelingPage:deletedPhrasesCount_${phrasesDeclination}`)
      )
    : t(
        'LogLabelingPage:deletedPhrasesInfo_other',
        count,
        t(`LogLabelingPage:deletedPhrasesCount_${phrasesDeclination}`)
      );
};

export const assignValidationErrorToReply = (errorData: SimilarAnswerData[], question: FaqQuestionData) => {
  if (!errorData || !errorData.length) return question;
  errorData.forEach(answer => {
    const replyToUpdateIndex = question.replies.findIndex(reply => (reply as AnswerReply).text === answer.origin);
    if (question.replies[replyToUpdateIndex]) {
      (question.replies[replyToUpdateIndex] as AnswerReply).error = 'validation';
    }
  });
  return question;
};

export const assignValidationErrorToPhrase = (
  errorData: SimilarPhraseData[],
  phrases: IntentPhrase[] | IntentItem[]
) => {
  if (!errorData || !errorData.length) return phrases;
  const newPhrases = [...phrases];
  errorData.forEach(error => {
    const phraseToUpdateIndex = newPhrases.findIndex(phrase => phrase.text === error.origin);
    if (newPhrases[phraseToUpdateIndex]) {
      newPhrases[phraseToUpdateIndex].error = 'validation';
    }
  });
  return newPhrases;
};
/**
 * @param {number} deletedCount число удаленных фраз
 * @param {number} updatedCount число измененных фраз
 * @param {string} language язык для склонения
 *
 * Конструктор фразы о результатах предобработки фраз.
 * Если оба параметра - 0, то просто строка-уведомление о завершении предобработки.
 *
 * Если есть оба параметра, то строка вида
 *
 * Предобработка завершена: X фраз изменены, Y — удалены.
 * Preprocessing completed: X phrases changed, Y phrases deleted.
 *
 * Если есть что то одно - то часть строки выше
 *
 * */

export const getProcessedPhrasesMessage = (deletedCount: number, updatedCount: number, language: string) => {
  if (!deletedCount && !updatedCount) return t('LogLabelingPage:cleanDataCompleted') + '.';

  const intl = new Intl.PluralRules(language);
  const deletedDeclination = intl.select(deletedCount);
  const changedDeclination = intl.select(updatedCount);

  if (deletedCount && !updatedCount) {
    return `${t('LogLabelingPage:cleanDataCompleted')}: ${deletedCount} ${t(
      `LogLabelingPage:deletedPhrasesCount_${deletedDeclination}`
    )} ${t(`LogLabelingPage:deletedCount_${deletedDeclination}`)}.`;
  }

  if (updatedCount && !deletedCount) {
    return `${t('LogLabelingPage:cleanDataCompleted')}: ${updatedCount} ${t(
      `LogLabelingPage:deletedPhrasesCount_${changedDeclination}`
    )} ${t(`LogLabelingPage:changedCount_${changedDeclination}`)}.`;
  }

  if (language.toLowerCase() === 'ru') {
    return `${t('LogLabelingPage:cleanDataCompleted')}: ${updatedCount} ${t(
      `LogLabelingPage:deletedPhrasesCount_${changedDeclination}`
    )} ${t(`LogLabelingPage:changedCount_${changedDeclination}`)}, ${deletedCount} — ${t(
      `LogLabelingPage:deletedCount_${deletedDeclination}`
    )}`;
  }

  return `${t('LogLabelingPage:cleanDataCompleted')}: ${updatedCount} ${t(
    `LogLabelingPage:deletedPhrasesCount_${changedDeclination}`
  )} ${t(`LogLabelingPage:changedCount_${changedDeclination}`)}, ${deletedCount} ${t(
    `LogLabelingPage:deletedPhrasesCount_${changedDeclination}`
  )} ${t(`LogLabelingPage:deletedCount_${deletedDeclination}`)}`;
};
