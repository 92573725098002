/* eslint-disable jsx-a11y/no-access-key */
import React, { Component } from 'react';

import { connect } from 'react-redux';
import history from 'appHistory';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import * as ProjectsActions from '../../actions/projects.actions';
import isAccess, { isEuroInstance, isReporterEnabled } from '../../isAccessFunction';
import localize from '../../localization';
import Modal from 'components/Modal';
import { v4 as uuidv4 } from 'uuid';
import { getRandomString } from './steps/helpers/getRandomString';
import { TIMEZONES, getClassificationAlgorithmTypes } from './constants';
import { BaseDescription, Classificator, NLUSettings, OtherSettings, RepoLocation } from './steps';
import { FormHeader } from './FormHeader';
import { BaseDescriptionJAICF } from './steps/BaseDescriptionJAICF';
import { RepoLocationJAICF } from './steps/RepoLocationJAICF';
import './styles.scss';
import { withRouter } from 'react-router';
import { JAICFLogo, JAICPLogo, TovieLogo } from 'components/Logo';
import { AppContext } from '../../modules/Caila/components/AppContext';
import { APITokens } from './steps/APITokens';
import { AnalyticConfig } from '../../analyticConfig';
import { AnalyticsProvider } from '@just-ai/analytic-front';

import { mapLanguageToTemplateLanguage } from '../CreateProject/consts';
import { cailaLocalization } from './localization/caila.loc';
import { projecteditformLocalization } from './localization/projecteditform.loc';
import { jaicfLocalization } from './localization/jaicf.loc';
import { getTestWidgetBotProjectFromProject } from '../../utils/botConfig';
import { AppLogger } from 'services/AppLogger';

localize.addTranslations(cailaLocalization);
localize.addTranslations(projecteditformLocalization);
localize.addTranslations(jaicfLocalization);

class ProjectEditForm extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.form = {
      name: React.createRef(),
      description: React.createRef(),
      repositoryUrl: React.createRef(),
      contentDirectory: React.createRef(),
      defaultBranch: React.createRef(),
      justAiKey: React.createRef(),
      repositoryLogin: React.createRef(),
      repositoryPassword: React.createRef(),

      cleanupDialogs: React.createRef(),
      cleanupOlderThenDays: React.createRef(),
      accessKey: React.createRef(),
      extendedSettings: React.createRef(),
      webHookUrl: React.createRef(),
      externalTemplate: React.createRef(),
    };
    this.accountId = props.account?.id || null;
    this.classificationAlgorithmTypes = getClassificationAlgorithmTypes();
  }

  repositoryAuthTypes = [
    {
      value: 'PERSONAL_USER',
      label: localize.translate('PERSONAL_USER'),
    },
    {
      value: 'GUEST_USER',
      label: localize.translate('GUEST_USER'),
    },
  ];

  environments = [
    {
      value: 'jaicpCloud',
      label: localize.translate('jaicf runtime environment jaicpCloud'),
    },
    {
      value: 'external',
      label: localize.translate('jaicf runtime environment external'),
    },
  ];

  initialCodeValues = [
    {
      value: 'system',
      label: localize.translate('jaicf initial code system template'),
    },
    {
      value: 'external',
      label: localize.translate('jaicf initial code external template'),
    },
    {
      value: 'connect',
      label: localize.translate('jaicf initial code connect template'),
    },
  ];

  timezones = [...TIMEZONES];

  state = {
    showPassword: false,
    personalRepo: false,
    externalRepository: 'internal',
    defaultRepositoryAuthTypes: this.repositoryAuthTypes[1],
    repositoryAuthTypes: this.repositoryAuthTypes,
    changePersonalUser: false,
    showPasswordJustAiKey: false,
    languages: [],
    selectedLanguage: null,
    classificationAlgorithm: getClassificationAlgorithmTypes()[0],

    cleanupAvailable: false,
    spellingCorrection: false,
    shared: false,
    timezone: this.timezones[12],
    accessKey: null,
    filteredTemplates: [],
    extendedSettings: '',
    tab: '1',
    justAiKey: '',
    nluSettingsError: false,
    nameError: false,
    repositoryUrlError: false,
    defaultFormInvalid: false,
    externalBotToken: null,
    jaicfProject: false,
    webHookUrlSelected: false,
    environment:
      this.props.location?.search && this.props.location.search.includes('?template=') && this.props.isJaicfProject
        ? 'jaicpCloud'
        : 'external',
    initialCode:
      this.props.location?.search && this.props.location.search.includes('?template=') && this.props.isJaicfProject
        ? 'connect'
        : 'system',
    externalTemplate: '',
    validation: {
      answerValidationEnabled: false,
      answerValidationThreshold: 0.8,
      phraseValidationEnabled: false,
      phraseValidationThreshold: 0.5,
    },
  };

  async componentDidMount() {
    if (isAccess('projects.hide_advanced_settings')) {
      this.changeAccess(this.state.repositoryAuthTypes[0]);
    }
    await this.props.actions.fetchBotTemplates();
    await this.applyExistedData();
    if (this.context.currentProject?.initialTab) {
      this.setState({ tab: this.context.currentProject?.initialTab });
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.fetching && !this.props.fetching) {
      this.updateForm();
    }
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.dismiss();
    }
  }

  updateForm = () => {
    const { editableProject, open } = this.props;
    const form = this.form;

    if (Boolean(editableProject.id) && open) {
      [
        'name',
        'repositoryUrl',
        'repositoryLogin',
        'defaultBranch',
        'contentDirectory',
        'description',
        'webHookUrl',
        'externalTemplate',
      ].forEach(key => {
        if (Boolean(form[key].current)) {
          form[key].current.value = editableProject[key];
        }
      });

      ['justAiKey', 'cleanupOlderThenDays'].forEach(key => {
        if (Boolean(form[key].current) && Boolean(editableProject[key])) {
          form[key].current.value = editableProject[key];
        }
      });

      if (Boolean(form.cleanupDialogs.current) && Boolean(editableProject.cleanupDialogs)) {
        form.cleanupDialogs.current.checked = editableProject.cleanupDialogs;
        if (editableProject.cleanupDialogs) {
          this.setState({ cleanupAvailable: editableProject.cleanupDialogs });
        }
      }

      if (editableProject.jaicfProject) {
        this.setState({
          jaicfProject: true,
          externalBotToken: editableProject.externalBotToken,
          webHookUrlSelected: Boolean(editableProject.webHookUrl),
          environment: editableProject.environment,
        });
      }

      try {
        this.validateForm();
      } catch {
        return false;
      }
    }

    if (
      this.props.location?.search &&
      this.props.location.search.includes('?template=') &&
      open &&
      form.repositoryUrl.current &&
      this.props.isJaicfProject
    ) {
      let projectLinkFromTemplate = this.props.location.search.split('template=')[1];
      if (projectLinkFromTemplate && projectLinkFromTemplate.includes('README.md')) {
        //removing readme path, '/blob/master/README.md'.length === 22
        projectLinkFromTemplate = projectLinkFromTemplate.substring(0, projectLinkFromTemplate.length - 22);
      }
      form.repositoryUrl.current.value = projectLinkFromTemplate;
      this.setState({
        externalTemplate: projectLinkFromTemplate,
      });
    }
  };

  getFilteredTemplates = language => {
    const { isJaicfProject } = this.props;
    const filteredTemplates = this.props.botTemplates.filter(template =>
      isJaicfProject ? template.jaicf : !template.jaicf
    );
    return filteredTemplates.filter(
      template =>
        template.language.toUpperCase() === mapLanguageToTemplateLanguage(language.substring(0, 2)).toUpperCase()
    );
  };

  applyExistedData = async () => {
    let newState = {};
    const { account, language, isEdit, editableProject, appConfig, isJaicfProject } = this.props;
    this.accountId = account && account.id;

    this.setState({ filteredTemplates: this.getFilteredTemplates(language) });

    if (isAccess(['BOT_CONTENT_READ', 'nlu']) && this.state.languages.length === 0) {
      await this.donwloadAndApplyCailapubData();
      await this.getCailaAccessKey();
    }

    if (isEdit && editableProject.repositoryLogin && !this.state.personalRepo) {
      newState.personalRepo = true;
    }
    if (isEdit && editableProject.justAiKey) {
      newState.justAiKey = editableProject.justAiKey;
    }

    if (isJaicfProject) {
      newState.externalBotToken = isEdit ? editableProject.externalBotToken : uuidv4();
      newState.jaicfProject = true;
    }

    if (isEdit && editableProject.repositoryAuthType) {
      const { repositoryAuthTypes } = this.state;
      const { repositoryAuthType } = editableProject;
      newState.defaultRepositoryAuthTypes =
        repositoryAuthType === 'GUEST_USER' ? repositoryAuthTypes[1] : repositoryAuthTypes[0];
    }

    if (Object.keys(newState).length > 0) {
      this.setState({ ...newState });
    }

    if (!Boolean(appConfig.getLogin)) {
      let newRepositoryAuthTypes = this.state.repositoryAuthTypes;
      if (newRepositoryAuthTypes.length > 1) newRepositoryAuthTypes.pop();
      this.setState({
        repositoryAuthTypes: newRepositoryAuthTypes,
        defaultRepositoryAuthTypes: newRepositoryAuthTypes[0],
        personalRepo: true,
      });
    }

    if (editableProject && editableProject.cleanupDialogs) {
      this.setState({ cleanupAvailable: editableProject.cleanupDialogs });
    }
    this.setDefaultTemplate();
    setTimeout(() => this.updateForm(), 16);
  };

  setDefaultTemplate = () => {
    if (this.state.filteredTemplates) {
      const { isJaicfProject } = this.props;
      const defaultTemplate = this.state.filteredTemplates.find(template => {
        if (isJaicfProject) {
          return template.jaicf && template.parsedJson.defaultTemplate;
        }
        return template.parsedJson.defaultTemplate;
      });
      defaultTemplate && this.changeProjectTemplate({ label: defaultTemplate.title, value: defaultTemplate.name });
    }
  };

  donwloadAndApplyCailapubData = async () => {
    const { actions, isEdit, editableProject, language } = this.props;
    let data = await actions.getCailaLanguages();
    let payload = data.action.payload;
    if (payload && typeof payload.data === 'object') {
      const languages = payload.data.map(lang => ({
        value: lang,
        label: localize.translate(`caila language ${lang}`),
      }));
      const defaultLanguage = language
        ? languages.find(lang => {
            if (language === 'eng' && lang.value === 'en') return true;
            if (language === 'cn' && lang.value === 'zh') return true;
            if (language === lang.value) return true;
            return false;
          })
        : languages[1];

      if (isEdit) {
        const data = await actions.getCailaProjectSetings(this.accountId, editableProject.shortName);
        await this.applySavedDataForEditing(languages, data.action.payload);
      } else {
        this.setState({
          languages: languages,
          selectedLanguage: defaultLanguage || language[0],
          filteredTemplates: this.getFilteredTemplates(defaultLanguage ? defaultLanguage.value : languages[0].value),
        });
      }
    }
  };

  applySavedDataForEditing = async (languages, payload) => {
    const baseState = {
      languages,
      timezone: this.timezones[12],
      spellingCorrection: false,
      shared: false,
      selectedLanguage: languages[0],
      classificationAlgorithm: this.classificationAlgorithmTypes[0],
    };

    let newState = { ...baseState };

    const extendedSettings = Boolean(payload.data.extendedSettings)
      ? JSON.stringify(payload.data.extendedSettings, null, 4)
      : '';

    try {
      if (payload.data.language) {
        const { language, spellingCorrection, classificationAlgorithm, timezone, shared } = payload.data;

        newState = {
          ...newState,
          extendedSettings,
          selectedLanguage: {
            value: language,
            label: localize.translate(`caila language ${payload.data.language}`),
          },
          classificationAlgorithm: this.classificationAlgorithmTypes.find(
            ({ value }) => value === classificationAlgorithm
          ),
          spellingCorrection: !!spellingCorrection,
          shared: !!shared,
          timezone: {
            value: timezone,
            label: timezone,
          },
          validation: payload.data.validation,
        };
      } else {
        newState.extendedSettings = extendedSettings;
      }
    } catch (e) {
      newState = baseState;
    }

    this.setState({ ...newState });
  };

  createProject = async () => {
    const { actions, account, projectList, currentProjectProperties } = this.props;
    const { selectedLanguage, classificationAlgorithm, spellingCorrection, shared, timezone } = this.state;
    const { setCurrentProject, BotProjectsService } = this.context;

    let newCailaProjectSettings;

    if (isAccess(['nlu', 'NLU_READ'])) {
      let extendedSettings = this.getExtendedSettingsParsedValue();
      if (!extendedSettings && this.projectShortName) {
        const fetchedSettings = await actions.getCailaProjectSetings(account.id, this.projectShortName);
        extendedSettings = fetchedSettings.value?.data?.extendedSettings;
      }
      newCailaProjectSettings = {
        language: selectedLanguage && selectedLanguage.value,
        classificationAlgorithm: classificationAlgorithm.value,
        spellingCorrection: spellingCorrection,
        shared: shared,
        timezone: timezone.value,
        extendedSettings: extendedSettings,
      };
    }

    const project = this.buildCreatableProjectData();

    const {
      action: {
        payload: { data },
      },
    } = await actions.createNewProject(() =>
      BotProjectsService.createProject({ ...project, projectSettingsData: newCailaProjectSettings })
    );

    this.projectShortName = data.shortName;
    this.closeAfterSuccess();
    this.props.actions.setCurrentProject(this.projectShortName, window.location.href.includes('?template='), true);
    const currentProject =
      projectList.find(project => project.shortName === this.projectShortName) || currentProjectProperties;
    setCurrentProject(currentProject);
  };

  buildCreatableProjectData = () => {
    const { editableProject, useExternalRepository, selectedProjectTemplate } = this.props;

    const { defaultRepositoryAuthTypes, personalRepo, jaicfProject } = this.state;

    const {
      name,
      description,
      justAiKey,
      repositoryUrl,
      contentDirectory,
      defaultBranch,
      repositoryLogin,
      repositoryPassword,
      webHookUrl,
      externalTemplate,
    } = this.form;

    let project = {
      name: name.current.value,
      description: jaicfProject ? '' : description.current.value,
    };

    if (defaultRepositoryAuthTypes.value === 'GUEST_USER' && justAiKey.current) {
      project.justAiKey = justAiKey.current.value.trim() || null;
    }

    if (selectedProjectTemplate && !jaicfProject) {
      project.template = selectedProjectTemplate.value?.trim();
    }

    if (jaicfProject) {
      project = {
        ...project,
        webHookUrl: webHookUrl?.current?.value?.trim() || null,
        externalBotToken: this.state.externalBotToken?.trim(),
        jaicfProject: true,
        environment: this.state.environment,
      };
      if (this.state.initialCode === 'external') {
        project.externalTemplate = externalTemplate.current?.value?.trim() || null;
      } else {
        project.template = selectedProjectTemplate ? selectedProjectTemplate.value?.trim() : null;
      }
    }

    if (useExternalRepository || this.state.initialCode === 'connect') {
      project = {
        ...project,
        repositoryUrl: repositoryUrl.current.value?.trim(),
        contentDirectory: contentDirectory.current.value?.trim(),
        defaultBranch: defaultBranch.current.value?.trim(),
        repositoryAuthType: defaultRepositoryAuthTypes.value?.trim(),
      };

      if (personalRepo) {
        project = {
          ...project,
          repositoryLogin: repositoryLogin.current.value?.trim(),
          repositoryPassword: repositoryPassword.current.value?.trim(),
        };
      }
    } else {
      project = {
        ...project,
        contentDirectory: editableProject.contentDirectory?.trim(),
        defaultBranch: editableProject.branch?.trim(),
      };
    }

    project = this.checkAndAddCleanupParameters(project);

    return project;
  };

  isEditorPage = () => window.location.pathname.split('/')[2]?.startsWith('editor');

  refreshEditor = () => history.replace(window.location.pathname, { refreshEditor: true });

  saveEditableProject = async () => {
    const { editableProject, actions } = this.props;
    const {
      setCurrentProject,
      currentProject: currentProjectFromContext,
      BotProjectsService,
      ProjectsServiceFromApi,
    } = this.context;

    const project = this.buildEditableProjectData();

    this.projectShortName = editableProject.shortName;

    try {
      const updatedCailaSettings = await this.saveCaiapubSetting();
      await actions.saveEditableProject(() => BotProjectsService.updateProject(project.id, project));
      await actions.loadProjects(ProjectsServiceFromApi.getProjectsWithPagination);
      updatedCailaSettings
        ? setCurrentProject({ ...currentProjectFromContext, ...updatedCailaSettings })
        : setCurrentProject({ ...currentProjectFromContext });
      if (this.isEditorPage()) this.refreshEditor();
      this.closeAfterSuccess();
    } catch (e) {
      if (e && e.response && (e.response.status === 404 || e.response.status === 405)) {
        this.closeAfterSuccess();
      } else {
        AppLogger.error({
          message: 'error in saveEditableProject',
          exception: e,
        });
        throw e;
      }
    }
  };

  buildEditableProjectData = () => {
    const { editableProject, isEdit } = this.props;

    const { defaultRepositoryAuthTypes, changePersonalUser, jaicfProject } = this.state;

    const {
      name,
      description,
      justAiKey,
      repositoryUrl,
      contentDirectory,
      defaultBranch,
      repositoryLogin,
      repositoryPassword,
      webHookUrl,
      externalTemplate,
    } = this.form;

    const botProject = getTestWidgetBotProjectFromProject(editableProject);
    let project = {
      id: editableProject.id,
      name: name.current.value,
      description: jaicfProject ? '' : description.current.value,
    };

    if (editableProject.repositoryUrl) {
      project = {
        ...project,
        repositoryUrl: editableProject.repositoryUrl,
        gitIntegration: botProject?.gitIntegration,
        repositoryAuthType: editableProject?.repositoryAuthType || 'PERSONAL_USER',
      };
    }

    if (jaicfProject) {
      project = {
        ...project,
        webHookUrl: webHookUrl?.current?.value || null,
        externalBotToken: this.state.externalBotToken,
        jaicfProject: true,
        environment: this.state.environment,
      };
    }

    if (jaicfProject && this.state.initialCode === 'external') {
      project = {
        ...project,
        externalTemplate: externalTemplate.current.value || null,
      };
    }

    if (isEdit && isAccess('projects.hide_advanced_settings')) {
      project = {
        ...project,
        contentDirectory: editableProject.contentDirectory,
        defaultBranch: editableProject.defaultBranch,
      };
    } else {
      project = {
        ...project,
        contentDirectory: contentDirectory?.current?.value || editableProject.contentDirectory,
        defaultBranch: defaultBranch?.current?.value || editableProject.defaultBranch,
      };
    }

    if (editableProject.repositoryAuthType) {
      project = {
        ...project,
        repositoryUrl: repositoryUrl.current.value,
        repositoryAuthType: defaultRepositoryAuthTypes.value,
      };
    }

    if (Boolean(justAiKey) && Boolean(justAiKey.current)) {
      project.justAiKey = justAiKey.current.value.trim() || null;
    }

    if (changePersonalUser && Boolean(repositoryLogin) && Boolean(repositoryPassword)) {
      project = {
        ...project,
        changePersonalUser: true,
        repositoryLogin: repositoryLogin.current.value,
        repositoryPassword: repositoryPassword.current.value,
      };
    }

    project = this.checkAndAddCleanupParameters(project);
    return project;
  };

  checkAndAddCleanupParameters = project => {
    const { cleanupAvailable } = this.state;
    const { cleanupOlderThenDays } = this.form;

    if (isAccess(['dialogs.cleanup'])) {
      return {
        ...project,
        cleanupDialogs: cleanupAvailable,
        cleanupOlderThenDays: cleanupOlderThenDays.current ? parseInt(cleanupOlderThenDays.current.value, 10) : null,
      };
    } else {
      return project;
    }
  };

  saveCaiapubSetting = async () => {
    const {
      currentProject,
      account,
      actions: { setCailaProjectSettings, getCailaProjectSetings },
    } = this.props;
    const { setCurrentProject, currentProject: currentProjectFromContext } = this.context;

    const { selectedLanguage, classificationAlgorithm, spellingCorrection, shared, timezone, validation } = this.state;

    if (isAccess(['BOT_CONTENT_READ', 'nlu'])) {
      let extendedSettings = this.getExtendedSettingsParsedValue();
      if (!extendedSettings) {
        const fetchedSettings = await getCailaProjectSetings(account.id, this.projectShortName);
        extendedSettings = fetchedSettings.value?.data?.extendedSettings;
      }
      const newCailaProjectSettings = {
        language: selectedLanguage && selectedLanguage.value,
        classificationAlgorithm: classificationAlgorithm && classificationAlgorithm.value,
        spellingCorrection: spellingCorrection,
        shared: shared,
        timezone: timezone.value,
        extendedSettings: extendedSettings,
        validation,
      };

      const newSettings = await setCailaProjectSettings(this.accountId, this.projectShortName, newCailaProjectSettings);
      const formattedNewProjectForContext = {
        ...currentProjectFromContext,
        ...newSettings.value.data,
        ...newSettings.value.data.extendedSettings,
      };
      delete formattedNewProjectForContext.extendedSettings;
      setCurrentProject({ ...currentProjectFromContext, ...formattedNewProjectForContext });
      return formattedNewProjectForContext;
    }

    if (!currentProject) {
      this.props.actions.setCurrentProject(this.projectShortName, window.location.href.includes('?template='), false);
    }
  };

  getExtendedSettingsParsedValue = () => {
    const { extendedSettings } = this.form;
    let extendedSettingsValue = extendedSettings?.current?.value;

    try {
      extendedSettingsValue = JSON.parse(extendedSettings.current.value);
      this.setState({ nluSettingsError: false });
    } catch {
      if (extendedSettingsValue) {
        this.setState({ nluSettingsError: true });
      }
    }
    return Boolean(extendedSettingsValue) ? extendedSettingsValue : null;
  };

  closeAfterSuccess = () => {
    if (!this.props.currentProject) {
      this.props.actions.setCurrentProject(this.projectShortName, window.location.href.includes('?template='), true);
    }

    this.dismiss();
  };

  getCailaAccessKey = async () => {
    const { editableProject, actions } = this.props;
    this.projectShortName = editableProject.shortName;
    if (!this.projectShortName) return;
    const response = await actions.getCailaAccessKey(this.accountId, this.projectShortName);

    this.setState({ accessKey: response.action.payload.data });
  };

  importCailaProject = async files => {
    if (!isAccess(['NLU_WRITE'])) return;
    const { editableProject, actions } = this.props;
    const file = files[0];
    const projectShortName = editableProject.shortName;
    await actions.importCailaProject(this.accountId, projectShortName, file);
    this.dismiss();
  };

  removeEditableProject = id => {
    this.props.actions.removeEditableProject(() => this.context.BotProjectsService.deleteProject(id));
  };

  toggleExternalRepository = initialCodeValue => {
    const { externalRepository, selectedLanguage, initialCode } = this.state;
    const { actions } = this.props;
    if (initialCodeValue === initialCode) return;
    const value = externalRepository === 'internal' || initialCodeValue === 'connect' ? 'external' : 'internal';

    actions.toggleExternalRepository(value === 'external');

    if (value === 'internal' && selectedLanguage) {
      const filteredTemplates = this.getFilteredTemplates(selectedLanguage.value);
      this.changeProjectTemplate(
        filteredTemplates.length ? { label: filteredTemplates[0].title, value: filteredTemplates[0].name } : null
      );
    }

    this.setState({ externalRepository: value }, this.runValidateForm);
  };

  dismiss = () => {
    this.props.actions.dropEditableProject();
    if (this.props.nluSettingsError) this.props.actions.clearNluSettingsError();
    this.props.toggle(false);
  };

  validateForm = stayAtCurrentTab => {
    const { externalRepository, tab, jaicfProject, initialCode, environment } = this.state;
    const { name, repositoryUrl, webHookUrl, externalTemplate } = this.form;

    const invalidName = name.current.value === '' && !name.current.checkValidity();
    const invalidWebhook = !isAccess(['framework.cloud']) && !webHookUrl?.current?.checkValidity();

    const invalidExternalTemplate = externalTemplate?.current ? !externalTemplate.current.checkValidity() : false;
    const tabOneIsInvalid =
      invalidName ||
      (jaicfProject && environment !== 'jaicpCloud' && invalidWebhook) ||
      (initialCode === 'external' && invalidExternalTemplate);
    const tabTwoIsInvalid =
      (externalRepository === 'external' || initialCode === 'connect') &&
      repositoryUrl?.current?.value === '' &&
      !repositoryUrl?.current?.checkValidity();
    const baseState = {
      formInvalid: tabOneIsInvalid || tabTwoIsInvalid,
      nameError: tabOneIsInvalid,
      repositoryUrlError: tabTwoIsInvalid,
    };
    if (tabOneIsInvalid) {
      this.setState(
        {
          ...baseState,
          tab: stayAtCurrentTab ? tab : '1',
        },
        () => (!stayAtCurrentTab || tab === '1') && invalidName
      );

      throw new Error('invalid form tab1');
    }

    if (tabTwoIsInvalid) {
      this.setState(
        {
          ...baseState,
          tab: stayAtCurrentTab ? tab : '2',
        },
        () => (!stayAtCurrentTab || tab === '2') && repositoryUrl.current.focus()
      );

      throw new Error('invalid form tab2');
    }

    if (
      this.state.formInvalid ||
      this.state.defaultFormInvalid ||
      this.state.nameError ||
      this.state.repositoryUrlError
    ) {
      this.setState({
        formInvalid: false,
        defaultFormInvalid: false,
        nameError: false,
        repositoryUrlError: false,
      });
    }
  };

  runValidateForm = () => {
    try {
      this.validateForm(true);
    } catch (error) {
      console.error('ERROR', error);
    }
  };

  saveForm = async () => {
    const { isEdit } = this.props;

    try {
      this.validateForm();
    } catch {
      return false;
    }

    try {
      if (isEdit) {
        await this.saveEditableProject();
      } else {
        await this.createProject();
      }
    } catch (error) {
      AppLogger.error({
        message: 'save form error',
        exception: error,
      });
      return false;
    }
  };

  changeProjectTemplate = val => {
    this.props.actions.changeProjectTemplate(val ? { ...val } : val);
  };

  switchPasswordJustAiKeyShow = () => this.setState({ showPasswordJustAiKey: !this.state.showPasswordJustAiKey });

  changeAccess = val => {
    const { repositoryAuthTypes } = this.state;
    const { editableProject } = this.props;

    const newState = {
      defaultRepositoryAuthTypes: val,
      personalRepo: val.value === repositoryAuthTypes[0].value,
      changePersonalUser: false,
    };

    if (editableProject.repositoryAuthType === 'GUEST_USER') {
      newState.changePersonalUser = val.value === repositoryAuthTypes[0].value;
    }

    this.setState(newState);
  };

  changeLanguage = val => {
    if (val) {
      const { selectedProjectTemplate, useExternalRepository } = this.props;

      const currentTemplate =
        selectedProjectTemplate &&
        selectedProjectTemplate.value &&
        selectedProjectTemplate.value.substring(0, selectedProjectTemplate.value.length - 3);

      const filteredTemplates = this.getFilteredTemplates(val.value);

      this.setState({
        selectedLanguage: val,
        filteredTemplates,
      });

      if (!useExternalRepository || selectedProjectTemplate) {
        const similarTemplate = filteredTemplates.find(
          ({ name }) => name.substring(0, name.length - 3) === currentTemplate
        );
        const template = similarTemplate
          ? { label: similarTemplate.title, value: similarTemplate.name }
          : (filteredTemplates.length && { label: filteredTemplates[0].title, value: filteredTemplates[0].name }) ||
            null;

        this.changeProjectTemplate(template);
      }
    }
  };

  changeEnvironment = val => {
    if (!val) return;
    this.setState(
      {
        environment: val.value,
      },
      () => {
        this.setDefaultTemplate();
      }
    );
    this.runValidateForm();
  };

  changeInitialCode = val => {
    if (!val) return;
    this.setState(
      {
        initialCode: val.value,
      },
      val.value === 'external' ? null : () => this.toggleExternalRepository(val.value)
    );
    val.value === 'connect' && this.changeProjectTemplate(null);
    val.value === 'system' && this.state.initialCode !== 'system' && this.setDefaultTemplate();
    // make validation async to check after state changes
    setTimeout(() => this.runValidateForm(), 0);
  };

  changeAlgorithm = val => this.setState({ classificationAlgorithm: val });

  changeTimezone = val => this.setState({ timezone: val });

  changePassword = () => this.setState({ changePersonalUser: !this.state.changePersonalUser });

  createPassword = () => {
    const { justAiKey } = this.form;

    if (justAiKey) {
      justAiKey.current.removeAttribute('readonly');
      this.setState({ justAiKey: getRandomString() });
    }
  };

  copyKey = () => {
    const { justAiKey } = this.form;

    justAiKey.current.type = 'text';
    justAiKey.current.select();
    navigator.clipboard.writeText(justAiKey.current.value);
    justAiKey.current.type = 'password';
  };

  copyAccessKey = () => {
    this.form.accessKey.current.select();
    navigator.clipboard.writeText(this.form.accessKey.current.value);
  };

  toggleCleanup = () => this.setState({ cleanupAvailable: !this.state.cleanupAvailable });

  createRef = i => (this.form.cleanupOlderThenDays.current = i);

  setActiveTab = tab => this.setState({ tab }, this.runValidateForm);

  setSpellingCorrection = () => this.setState({ spellingCorrection: !this.state.spellingCorrection });

  setShared = () => this.setState({ shared: !this.state.shared });

  changeExtendedSettings = value => this.setState({ extendedSettings: value });

  updateExternalBotToken = () => this.setState({ externalBotToken: uuidv4() });

  changeExternalTemplate = value => {
    this.setState({ externalTemplate: value }, this.runValidateForm());
  };

  setValidationData = data => {
    this.setState({ validation: data }, this.runValidateForm());
  };

  render() {
    const {
      editableProject,
      isEdit,
      error,
      fetching,
      fetchingProjectList,
      useExternalRepository,
      selectedProjectTemplate,
      botTemplates,
      appConfig,
      language,
      errorArgs,
    } = this.props;
    const {
      defaultRepositoryAuthTypes,
      showPasswordJustAiKey,
      selectedLanguage,
      classificationAlgorithm,
      timezone,
      tab,
      filteredTemplates,
      languages,
      justAiKey,
      externalRepository,
      changePersonalUser,
      personalRepo,
      accessKey,
      spellingCorrection,
      extendedSettings,
      cleanupAvailable,
      formInvalid,
      defaultFormInvalid,
      repositoryUrlError,
      nameError,
      shared,
      externalBotToken,
      jaicfProject,
      webHookUrlSelected,
      environment,
      initialCode,
      externalTemplate,
      validation,
    } = this.state;

    const isLoading = fetching || fetchingProjectList;

    const hidden = useExternalRepository || isEdit || initialCode === 'connect';
    const nluSettingsError = this.props.nluSettingsError || this.state.nluSettingsError;
    const title = isEdit
      ? `${localize.translate('Project editing')} ${editableProject.name} `
      : `${localize.translate('Project creation')}`;

    return (
      <Modal
        isOpen={this.props.open}
        className='createProjectModal has-spinner'
        data-test-id='projectsPage.createProjectModal'
        size='lg'
        titleIcon={
          this.props.appConfig.botadmin.logo ? null : jaicfProject ? (
            <div className='logo-box logo-box--jaicf'>
              <JAICFLogo />
            </div>
          ) : (
            <div className='logo-box logo-box--jaicp'>{isEuroInstance() ? <TovieLogo /> : <JAICPLogo />}</div>
          )
        }
        title={title}
        buttonCancelText={localize.translate('Cancel')}
        buttonCancelTestId='projectsPage.createProjectModal.cancelButton'
        buttonSubmitText={localize.translate(isEdit ? 'Save' : 'Create')}
        buttonSubmitTestId='projectsPage.createProjectModal.createButton'
        buttonSubmitDisabled={!isAccess('PROJECTS_WRITE') || formInvalid || defaultFormInvalid || isLoading}
        disableActionButtonAutoFocus
        noValidate
        formClassName={classNames({ 'is-invalid': this.state.formInvalid })}
        onActionClick={this.saveForm}
        onCancelClick={() => {
          this.dismiss();
          if (this.props.location?.search && !isEdit) {
            history.push('/');
          }
        }}
      >
        <FormHeader
          fetching={isLoading}
          nluSettingsError={nluSettingsError}
          editableProject={editableProject}
          tab={tab}
          nameError={nameError}
          repositoryUrlError={repositoryUrlError}
          error={error}
          errorArgs={errorArgs}
          isJaicfProject={jaicfProject}
          setActiveTab={this.setActiveTab}
        />

        <div className={classNames('tab', { invisible: this.state.tab !== '1' })}>
          {jaicfProject ? (
            !isAccess(['framework.cloud']) ? (
              <RepoLocationJAICF
                editableProject={editableProject}
                nameRef={this.form.name}
                onValidateForm={this.runValidateForm}
                updateExternalBotToken={this.updateExternalBotToken}
                webHookUrlRef={this.form.webHookUrl}
                webHookUrlSelected={webHookUrlSelected}
                externalBotToken={externalBotToken}
                customStyles={customStyles}
                selectedLanguage={selectedLanguage}
                languages={languages}
                changeLanguage={this.changeLanguage}
              />
            ) : (
              <BaseDescriptionJAICF
                editableProject={editableProject}
                onValidateForm={this.runValidateForm}
                nameRef={this.form.name}
                customStyles={customStyles}
                environments={this.environments}
                environment={this.state.environment}
                changeEnvironment={this.changeEnvironment}
                initialCodeValues={this.initialCodeValues}
                initialCode={this.state.initialCode}
                changeInitialCode={this.changeInitialCode}
                setActiveTab={this.setActiveTab}
                botTemplates={botTemplates}
                filteredTemplates={filteredTemplates}
                isEdit={isEdit}
                changeProjectTemplate={this.changeProjectTemplate}
                selectedProjectTemplate={selectedProjectTemplate}
                externalTemplateRef={this.form.externalTemplate}
                externalTemplate={externalTemplate}
                changeExternalTemplate={this.changeExternalTemplate}
                selectedLanguage={selectedLanguage}
                languages={languages}
              />
            )
          ) : (
            <BaseDescription
              editableProject={editableProject}
              selectedLanguage={selectedLanguage}
              languages={languages}
              customStyles={customStyles}
              selectedProjectTemplate={selectedProjectTemplate}
              isEdit={isEdit}
              initialCodeValues={this.initialCodeValues}
              initialCode={this.state.initialCode}
              changeInitialCode={this.changeInitialCode}
              setActiveTab={this.setActiveTab}
              useExternalRepository={useExternalRepository}
              botTemplates={botTemplates}
              currentLanguage={language}
              filteredTemplates={filteredTemplates}
              changeLanguage={this.changeLanguage}
              changeProjectTemplate={this.changeProjectTemplate}
              onValidateForm={this.runValidateForm}
              nameRef={this.form.name}
              descriptionRef={this.form.description}
            />
          )}
        </div>
        <div className={classNames('tab', { invisible: this.state.tab !== '2' })}>
          {jaicfProject && environment !== 'jaicpCloud' && isAccess(['framework.cloud']) ? (
            <RepoLocationJAICF
              editableProject={editableProject}
              onValidateForm={this.runValidateForm}
              updateExternalBotToken={this.updateExternalBotToken}
              webHookUrlRef={this.form.webHookUrl}
              webHookUrlSelected={webHookUrlSelected}
              externalBotToken={externalBotToken}
              customStyles={customStyles}
              selectedLanguage={selectedLanguage}
              languages={languages}
              changeLanguage={this.changeLanguage}
            />
          ) : (
            <RepoLocation
              isEdit={isEdit}
              editingDisabled={!isAccess('CODE_WRITE')}
              externalRepository={externalRepository}
              editableProject={editableProject}
              hidden={hidden}
              defaultRepositoryAuthTypes={defaultRepositoryAuthTypes}
              repositoryAuthTypes={this.repositoryAuthTypes}
              customStyles={customStyles}
              appConfig={appConfig}
              showPasswordJustAiKey={showPasswordJustAiKey}
              changePersonalUser={changePersonalUser}
              personalRepo={personalRepo}
              justAiKey={justAiKey}
              toggleExternalRepository={this.toggleExternalRepository}
              changeAccess={this.changeAccess}
              switchPasswordJustAiKeyShow={this.switchPasswordJustAiKeyShow}
              copyKey={this.copyKey}
              createPassword={this.createPassword}
              changePassword={this.changePassword}
              onValidateForm={this.runValidateForm}
              justAiKeyRef={this.form.justAiKey}
              repositoryUrlRef={this.form.repositoryUrl}
              contentDirectoryRef={this.form.contentDirectory}
              defaultBranchRef={this.form.defaultBranch}
              repositoryLoginRef={this.form.repositoryLogin}
              repositoryPasswordRef={this.form.repositoryPassword}
              initialCode={initialCode}
            />
          )}
        </div>

        <div className={classNames('tab', { invisible: this.state.tab !== '3' })}>
          <Classificator
            classificationAlgorithmTypes={this.classificationAlgorithmTypes}
            customStyles={customStyles}
            classificationAlgorithm={classificationAlgorithm}
            spellingCorrection={spellingCorrection}
            timezone={timezone}
            timezones={this.timezones}
            isEdit={isEdit}
            accessKey={accessKey}
            changeAlgorithm={this.changeAlgorithm}
            setSpellingCorrection={this.setSpellingCorrection}
            changeTimezone={this.changeTimezone}
            getCailaAccessKey={this.getCailaAccessKey}
            copyAccessKey={this.copyAccessKey}
            importCailaProject={this.importCailaProject}
            accessKeyRef={this.form.accessKey}
            shared={shared}
            setShared={this.setShared}
            selectedLanguage={selectedLanguage}
            languages={languages}
            changeLanguage={this.changeLanguage}
            isJaicfProject={jaicfProject}
            validationData={validation}
            setValidationData={this.setValidationData}
          />
        </div>

        <div className={classNames('tab', { invisible: this.state.tab !== '4' })}>
          <NLUSettings
            extendedSettings={extendedSettings}
            nluSettingsError={nluSettingsError}
            changeExtendedSettings={this.changeExtendedSettings}
            extendedSettingsRef={this.form.extendedSettings}
            error={error}
          />
        </div>

        <div className={classNames('tab', { invisible: this.state.tab !== '5' })}>
          <OtherSettings
            cleanupAvailable={cleanupAvailable}
            editableProject={editableProject}
            toggleCleanup={this.toggleCleanup}
            createRef={this.createRef}
          />
        </div>
        {!jaicfProject && isReporterEnabled() && editableProject.shortName && (
          <AnalyticsProvider
            accountId={this.context.accountId}
            projectShortName={editableProject.shortName}
            t={localize.translate}
            history={history}
            config={AnalyticConfig()}
          >
            <div className={classNames('tab', { invisible: this.state.tab !== '6' })}>
              <APITokens editableProject={editableProject} />
            </div>
          </AnalyticsProvider>
        )}
      </Modal>
    );
  }
}

const customStyles = {
  menu: provided => ({
    ...provided,
    zIndex: 6,
  }),
};

function mapStateToProps(state) {
  return {
    editableProject: state.EditProjectsReducer.editableProject,
    useExternalRepository: state.EditProjectsReducer.useExternalRepository,
    botTemplates: state.EditProjectsReducer.botTemplates,
    selectedProjectTemplate: state.EditProjectsReducer.selectedProjectTemplate,
    isEdit: state.EditProjectsReducer.isEdit,
    error: state.EditProjectsReducer.error,
    errorArgs: state.EditProjectsReducer.errorArgs,
    usersList: state.UsersListReducer.usersList,
    fetching: state.EditProjectsReducer.fetching,
    showDeleteConfirm: state.EditProjectsReducer.showDeleteConfirm,
    account: state.CurrentAccountReducer.account,
    language: state.CurrentUserReducer.language,
    appConfig: {
      getLogin: state.AppConfigReducer.getLogin,
      botadmin: state.AppConfigReducer.botadmin,
    },
    nluSettingsError: state.EditProjectsReducer.nluSettingsError,
    currentProject: state.CurrentProjectsReducer.currentProject,
    currentProjectProperties: state.CurrentProjectsReducer.currentProjectProperties,
    fetchingProjectList: state.ProjectsReducer.fetching,
    projectList: state.ProjectsReducer.projectList,
    fetchedProjectList: state.ProjectsReducer.fetchedProjectList,
  };
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(ProjectsActions, dispatch),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withRouter(ProjectEditForm)));
