import { LocalStorageService, objectSerializer } from 'services/Storage/LocalStorageService';

export class VisualEditorSettings {
  useCodeEditorOnScreenAdd: boolean = true;
  codeEditorSize: { width: number; height: number } = {
    width: 400,
    height: 400,
  };
}

export class JGraphStagePathSettings {
  stageScale: number = 0.8;
  stagePosition = {
    x: 0,
    y: 0,
  };
}

export default class JGraphStorageService {
  private static JGRAPH_VIEW_SETTINGS = 'JGRAPH_VIEW_SETTINGS';
  private projectShortName: string;
  private localStorageService: LocalStorageService;

  constructor(projectShortName: string) {
    this.projectShortName = projectShortName;
    this.localStorageService = new LocalStorageService();
  }

  private prefixed(key: string, path?: string) {
    return `${key}-${this.projectShortName}${path ? '-' + path : ''}`;
  }

  public saveSettings(settings: VisualEditorSettings, path?: string) {
    return this.localStorageService.set(
      this.prefixed(JGraphStorageService.JGRAPH_VIEW_SETTINGS, path),
      settings,
      true,
      objectSerializer
    );
  }

  public saveStagePathSettings(settings: JGraphStagePathSettings, path?: string) {
    return this.localStorageService.set(
      this.prefixed(JGraphStorageService.JGRAPH_VIEW_SETTINGS, path),
      settings,
      true,
      objectSerializer
    );
  }

  public async getStagePathSettings(path?: string) {
    const fromLs = await this.localStorageService.get<JGraphStagePathSettings>(
      this.prefixed(JGraphStorageService.JGRAPH_VIEW_SETTINGS, path),
      objectSerializer
    );

    if (!fromLs.success || !fromLs.payload) return new JGraphStagePathSettings();
    return fromLs.payload;
  }
}
