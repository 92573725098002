import React, { RefObject, useEffect, useCallback } from 'react';
import { KonvaEventObject } from 'konva/lib/Node';
import Konva from 'konva';
import { useDebounceFn } from '@just-ai/just-ui';

import { EditMenuBlock, JGraphTheme, JStateWithId } from '../../../reducers/JGraph.reducer/types';
import { animationFrames, endWith, map, scan, shareReplay, Subject, takeWhile, BehaviorSubject } from 'rxjs';
import { Vector2d } from 'konva/lib/types';
import { GlobalScrollToTarget } from '../contexts/types';
import { StageObservablesContextType } from '../contexts/StageObservablesProvider';
import { findParentByPathId, findScreenByPathId } from '../../../reducers/JGraph.reducer/Graph';
import { clamp } from 'lodash';
import { getMinMaxScreensCoords } from './common';
import { StickerInfo } from '../view/Sticker/types';
import { amplitudeInstance } from 'pipes/functions';
import { Cords, Vector2D } from './2DVector';
import { useAppDispatch } from 'storeHooks';
import { setSelectedTheme, openThemesPage, JGLS } from 'reducers/JGraph.reducer';
import { JGraphHudInfo } from '../view/JGraphHud';
import { Rect, scaleFromCenterRect } from './rects';

const SCALE_STEP = 0.03;
const MIN_SCALE = 0.05;
const MAX_SCALE = 6;

export const setScale = (e: KonvaEventObject<WheelEvent>): [number, { x: number; y: number }] => {
  e.evt.preventDefault();
  // const bgSize = 112;
  const stageStage = getStageFromEvent(e);

  if (stageStage) {
    const oldScale = stageStage.scaleX();

    const stagePointerPosition = stageStage.getPointerPosition() || { x: 0, y: 0 };

    const mousePointTo = {
      x: stagePointerPosition.x / oldScale - stageStage.x() / oldScale,
      y: stagePointerPosition.y / oldScale - stageStage.y() / oldScale,
    };

    const scaleBy = 1 + SCALE_STEP;
    let newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
    newScale = clamp(newScale, MIN_SCALE, MAX_SCALE);

    // bg.style.backgroundSize = bgSize*newScale + 'px';

    stageStage.scale({ x: newScale, y: newScale });

    const newPos = {
      x: -(mousePointTo.x - stagePointerPosition.x / newScale) * newScale,
      y: -(mousePointTo.y - stagePointerPosition.y / newScale) * newScale,
    };
    stageStage.position(newPos);

    return [newScale, newPos];
  }
  return [1, { x: 0, y: 0 }];
};

export const setCursorOnMouseEnterAndLeave = (fn?: () => unknown) => (e: KonvaEventObject<MouseEvent>) => {
  const stage = getStageFromEvent(e);
  if (stage) {
    const evtType = e.type;
    const parent = stage.content ? (stage.content.parentNode as HTMLDivElement) : null;
    if (parent) {
      parent.style.cursor = evtType === 'mouseenter' ? 'pointer' : '';
      if (evtType === 'mouseenter') {
        parent.style.cursor = 'pointer';
      } else {
        parent.style.cursor = '';
      }
    }

    if (fn) {
      fn();
    }
  }
};
export const setTitleOnMouseEnterAndLeave = (title?: string) => (e: KonvaEventObject<MouseEvent>) => {
  const stage = getStageFromEvent(e);
  if (stage) {
    const evtType = e.type;
    const parent = stage.content ? (stage.content.parentNode as HTMLDivElement) : null;
    if (parent) {
      if (evtType === 'mouseenter') {
        parent.title = title || '';
      } else {
        parent.title = '';
      }
    }
  }
};

export type StageActions = {
  toggleAddingActionsMenu: (event: Konva.KonvaEventObject<MouseEvent>, screenId?: string) => unknown;
  showStateNameEditField: (event: Konva.KonvaEventObject<MouseEvent>, stateName: string) => unknown;
  setEditMenuBlock: (editMenuBlock?: EditMenuBlock) => unknown;
};

export const getStageActionsFromEvent = (event: KonvaEventObject<MouseEvent>): StageActions => {
  return event.currentTarget.getStage()?.attrs.actions;
};
export const getStageFromEvent = (event: KonvaEventObject<MouseEvent>): Konva.Stage | null => {
  return event.currentTarget.getStage();
};
export const getStageActionsFromRef = (ref: RefObject<Konva.Node>): StageActions => {
  return ref.current?.getStage()?.attrs.actions;
};
export const getStageFromRef = (ref: RefObject<Konva.Node>): Konva.Stage | null | undefined => {
  return ref.current?.getStage();
};
export const getStageContextFromRef = (ref: RefObject<Konva.Node>): StageObservablesContextType => {
  return ref.current?.getStage()?.attrs.ctx || {};
};
export const getStageContextFromEvent = (event: KonvaEventObject<MouseEvent>): StageObservablesContextType => {
  return event.currentTarget.getStage()?.attrs.ctx || {};
};

function tween(start: Vector2d, end: Vector2d, duration: number) {
  const diffX = end.x - start.x;
  const diffY = end.y - start.y;
  return animationFrames().pipe(
    // Figure out what percentage of time has passed
    map(({ elapsed }) => elapsed / duration),
    // Take the vector while less than 100%
    takeWhile(v => v < 1),
    // Finish with 100%
    endWith(1),
    // Calculate the distance traveled between start and end
    map(v => ({
      x: v * diffX + start.x,
      y: v * diffY + start.y,
    }))
  );
}

export const stateWidth = 280;
export const headerHeight = 20;

export const getTargetAndHeightByPathId = (scrollToPathId: string, stage: Konva.Stage) => {
  const target = stage.findOne(`.${scrollToPathId}`) as Konva.Group;
  let targetHeight = 0;
  if (target && target.hasChildren()) {
    target.children!.forEach(group => {
      if (group instanceof Konva.Group) {
        const FirstAutosizeRectInGroup = (group as Konva.Group).findOne((node: { name: () => string }) => {
          return node.name().startsWith('AutosizeRect');
        });
        if (FirstAutosizeRectInGroup) {
          targetHeight += FirstAutosizeRectInGroup.height();
        }
      }
    });
    return {
      target,
      targetHeight,
    };
  }
  return {
    target: null,
    targetHeight: 0,
  };
};

export const getCenterOfStage = (stage: Konva.Stage): Vector2d => {
  const stageScale = stage.scale() ?? { x: 1, y: 1 };
  const stageViewPortCenter = {
    width: stage.width() / 2 / stageScale.x,
    height: stage.height() / 2 / stageScale.y,
  };
  let targetPosition = stage.getAbsolutePosition();

  return {
    x: -targetPosition.x / stageScale.x + stageViewPortCenter.width,
    y: -targetPosition.y / stageScale.y + stageViewPortCenter.height,
  };
};

export const scrollToTargetGlobal$ = new Subject<GlobalScrollToTarget>();
export const scrollToTargetGlobalPipe$ = scrollToTargetGlobal$.pipe(
  scan((prevValue, newValue) => {
    return newValue;
  }, {} as GlobalScrollToTarget),
  shareReplay(1)
);

export const useScrollToTargetGlobal = (
  isActive: boolean,
  stage: React.MutableRefObject<Konva.Stage | null>,
  isCurrentStageRendered$: BehaviorSubject<boolean>,
  selectedTheme: JGraphTheme | undefined,
  themes: JGraphTheme[],
  screens: JStateWithId[],
  allScreens: JStateWithId[],
  setSelectedGroupToTop: (group: { path?: string; pathId?: string }) => unknown,
  currentOpenScreenGroup?: { path?: string; pathId?: string }
) => {
  const dispatch = useAppDispatch();

  const scrollToStageNode = useDebounceFn(
    useCallback(
      (targetPathId: string, instantly?: boolean) => {
        if (!stage.current) return;
        const { target } = getTargetAndHeightByPathId(targetPathId, stage.current);
        if (!target) return;

        const stageScale = stage.current.scale() ?? { x: 1, y: 1 };
        let targetPosition = target.getAbsolutePosition(stage.current);
        targetPosition.x = targetPosition.x * stageScale.x;
        targetPosition.y = targetPosition.y * stageScale.y;

        const hubContainer = JGraphHudInfo.getValue();
        if (!hubContainer) return;
        const stageViewPortCenter = {
          x: hubContainer.position.x + hubContainer.size.width / 2,
          y: hubContainer.position.y + hubContainer.size.height / 2,
        };

        const fromPosition = stage.current.position();

        const toPosition = {
          x: -targetPosition.x + stageViewPortCenter.x - (stateWidth * stageScale.x) / 2,
          y: -targetPosition.y + stageViewPortCenter.y - headerHeight * stageScale.y,
        };

        void JGLS.store.saveStagePathSettings(
          {
            stagePosition: toPosition,
            stageScale: stageScale.x,
          },
          stage.current.attrs.stagePath
        );
        if (instantly) {
          stage.current?.position(toPosition);
        } else {
          tween(fromPosition, toPosition, 1000).subscribe(positionValue => {
            stage.current?.position({
              x: positionValue.x,
              y: positionValue.y,
            });
          });
        }
      },
      [stage]
    ),
    100
  );

  useEffect(() => {
    if (!isActive) return;
    const sub = scrollToTargetGlobalPipe$.subscribe(props => {
      const { targetPathId, parentPathId, type = 'state', instantly } = props;
      if (!targetPathId) return;

      if (type === 'theme') {
        if (selectedTheme) {
          dispatch(openThemesPage());
          return;
        }

        isCurrentStageRendered$.subscribe(() => scrollToStageNode(targetPathId, instantly));
        scrollToTargetGlobal$.next({ targetPathId: undefined });
        return;
      }

      let parentState: JStateWithId | undefined;
      let isTargetOnCurrentOpenedGroup = false;
      let targetThemeValue = '';
      if (type === 'state') {
        isTargetOnCurrentOpenedGroup = !!screens.find(screen => screen.pathId === targetPathId);
        parentState = findParentByPathId(allScreens, targetPathId);
        const targetState = findScreenByPathId(targetPathId, allScreens);
        targetThemeValue = targetState?.theme ?? '';
      } else if (type === 'sticker' && parentPathId) {
        isTargetOnCurrentOpenedGroup =
          currentOpenScreenGroup?.pathId === parentPathId || selectedTheme?.pathId === parentPathId;
        parentState = findParentByPathId(allScreens, parentPathId);
        if (parentState) {
          targetThemeValue = parentState?.theme ?? '';
        } else {
          const theme = themes.find(theme => theme.pathId === parentPathId);
          targetThemeValue = theme?.value ?? '';
        }
      } else return;

      if (targetThemeValue && targetThemeValue !== selectedTheme?.value) {
        dispatch(setSelectedTheme({ value: targetThemeValue }));
        return;
      }

      if (isTargetOnCurrentOpenedGroup) {
        isCurrentStageRendered$.subscribe(isRendered => {
          if (!isRendered) return;
          scrollToStageNode(targetPathId, instantly);
        });
        scrollToTargetGlobal$.next({ targetPathId: undefined });
        return;
      }

      if (!parentState) {
        setSelectedGroupToTop({ path: undefined, pathId: undefined });
        return;
      } else if (parentState.states && parentState.states.length > 0) {
        setSelectedGroupToTop({ path: parentState.path, pathId: parentState.pathId });
        return;
      }

      setSelectedGroupToTop({ path: undefined, pathId: undefined });
      scrollToTargetGlobal$.next({ targetPathId: undefined });
    });
    return () => sub.unsubscribe();
  }, [
    allScreens,
    currentOpenScreenGroup,
    dispatch,
    isActive,
    isCurrentStageRendered$,
    screens,
    scrollToStageNode,
    selectedTheme,
    selectedTheme?.pathId,
    selectedTheme?.value,
    setSelectedGroupToTop,
    themes,
  ]);
};

export const getInnerScreensPathIdMap = (screens: JStateWithId[]): string[] => {
  return screens
    .map(screen => {
      let value: string[] = [];
      if (screen.states) value = getInnerScreensPathIdMap(screen.states);
      value.push(screen.pathId);
      return value;
    })
    .flat(1);
};

export function isPathInValid(name: string) {
  const valueToCheckSplitPath = name.split('/');
  return valueToCheckSplitPath.reduce((prevValue, currentItem, index, initialArr) => {
    if (prevValue) return prevValue;
    if (initialArr.length >= 2 && index < initialArr.length - 1) {
      prevValue = !currentItem.replace(/\W/gu, '');
    }
    if (index > 0) {
      prevValue = currentItem.replace(/\W/gu, '').length === 0;
    }
    return prevValue;
  }, false);
}

export function isScreenNameValid(name: string): boolean {
  name = name.trim();
  if (!name) return false;
  return !isTextHasSlash(name);
}

export function isTextHasSlash(name: string): boolean {
  return /\//.test(name);
}

export function joinPaths(path: string, parentPath?: string): string {
  return normalizePath(parentPath ? `${parentPath}/${path.trim()}` : `/${path.trim()}`);
}

export function normalizePath(path: string): string {
  return path.replace(/\/+/g, '/');
}

export function isNameAlreadyExist(name: string, allPaths: string[], parentPath?: string) {
  return allPaths.includes(joinPaths(name, parentPath));
}

export type CanvasNodePosition = Cords & { pathId: string };
export const createPng = async (
  fileName: string,
  projectShortName: string,
  stage: Konva.Stage,
  nodes: CanvasNodePosition[],
  stickers: StickerInfo[]
) => {
  debugger;
  if (!stage) return;
  const MAX_PNG_SIZE = 16380 * 16380;
  const elements: CanvasNodePosition[] = [
    ...nodes,
    ...stickers.map(el => ({ x: el.position.x, y: el.position.y, pathId: el.id })),
  ];
  let { minX, minY, maxX, maxY } = getMinMaxScreensCoords(elements);

  const incoming = stage.findOne('#IncomingOutgoing_incoming');
  const outgoing = stage.findOne('#IncomingOutgoing_outgoing');
  if (incoming) {
    if (incoming.x() < minX) minX = incoming.x();
    if (incoming.y() < minY) minY = incoming.y();
  }
  if (outgoing) {
    if (outgoing.x() > maxX) maxX = outgoing.x();
    if (outgoing.y() > maxY) maxY = outgoing.y();
  }

  const prevStagePosition = stage?.position();
  const prevStageScale = stage?.scale();

  stage?.position({ x: 0, y: 0 });
  stage?.scale({ x: 1, y: 1 });

  const nodeWithMaxY = elements.find(node => node.y === maxY);
  if (!nodeWithMaxY) return;
  const maxYKonvaNode = stage?.findOne(`.${nodeWithMaxY.pathId}`);

  if (!maxYKonvaNode) return;

  const maxYHeight = maxYKonvaNode.getClientRect({ skipTransform: true });

  let canvasWidth = Math.abs(maxX - minX) + 380 + 100;
  let canvasHeight = Math.abs(maxY - minY) + maxYHeight.height + 200;
  let pixelRatio = 1;
  if (canvasHeight * canvasWidth > MAX_PNG_SIZE) {
    pixelRatio = MAX_PNG_SIZE / (canvasHeight * canvasWidth);
  }

  const imageSize = `${(canvasWidth * canvasHeight) / 1e6} Mp`;
  const timeStart = Date.now();

  amplitudeInstance.logEvent('J-Graph Image export start.', { imageSize: imageSize, projectShortName });

  const dataURL = stage.toDataURL({
    quality: 1,
    pixelRatio: pixelRatio,
    x: minX - 100,
    y: minY - 100,
    width: canvasWidth,
    height: canvasHeight,
    mimeType: 'image/png',
  });

  amplitudeInstance.logEvent('J-Graph Image export finish.', {
    imageSize: imageSize,
    projectShortName,
    generationTime: (Date.now() - timeStart) / 1000,
  });

  const link = document.createElement('a');
  link.download = fileName;
  link.href = dataURL;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  stage?.position(prevStagePosition);
  stage?.scale(prevStageScale);
};

export function getNewNameWithIndex(path: string, existsPaths: string[], iterator = 1): string {
  let name = path;
  if (existsPaths.includes(name) || existsPaths.includes('/' + name)) {
    name = `${path}_${iterator}`;
    if (existsPaths.includes(name) || existsPaths.includes('/' + name)) {
      return getNewNameWithIndex(path, existsPaths, iterator + 1);
    }
  }
  return name;
}

export const traverseScreen = (screen: JStateWithId, cb: (state: JStateWithId) => void) => {
  cb(screen);
  (screen.states ?? []).forEach(state => traverseScreen(state, cb));
};

export function getViewportStageRect(stage: Konva.Stage) {
  const hubContainer = JGraphHudInfo.getValue();
  if (hubContainer.size.height === 0) return;

  const scale = stage.scale() ?? Vector2D.fromScalar(1);
  const stagePosRel = Vector2D.fromObj(stage.position()).inverse();
  const rectPos = stagePosRel.divide(scale);

  return {
    x: rectPos.x + hubContainer.position.x / scale.x,
    y: rectPos.y + hubContainer.position.y / scale.x,
    width: hubContainer.size.width / scale.x,
    height: hubContainer.size.height / scale.y,
  };
}

export function isUserSeeNodesInViewport<TYPE extends Rect>(stage: Konva.Stage, nodes: TYPE[]): boolean {
  let viewportStageRect = getViewportStageRect(stage);
  if (!viewportStageRect) return false;
  viewportStageRect = scaleFromCenterRect(viewportStageRect, { x: 0.95, y: 0.9 });

  const nodesInViewport = nodes.filter(
    node =>
      node.x + node.width > viewportStageRect.x &&
      node.x < viewportStageRect.x + viewportStageRect.width &&
      node.y + node.height > viewportStageRect.y &&
      node.y < viewportStageRect.y + viewportStageRect.height
  );
  return nodesInViewport.length > 0;
}
