import { Vector2d } from 'konva/lib/types';
import React, { FC, useCallback, useEffect, useRef, useMemo } from 'react';
import { Group, Rect, Text } from 'react-konva';
import { useToggle } from '@just-ai/just-ui';
import { KonvaEventObject } from 'konva/lib/Node';
import { TConnector, GroupedConnector, JGraphTheme } from '../../../../reducers/JGraph.reducer/types';
import { IconNames, KIcon } from '../parts/KIcons';
import { AutosizeRect } from '../parts/AutosizeRect';
import { t } from 'localization';

import { recalculateAffectedConnections } from '../../utils/connectionLayerUtils';
import { getStageContextFromRef, getStageFromEvent, getStageFromRef } from '../../utils/stageUtils';
import { Observable, Subject } from 'rxjs';
import { ConnectorStore } from '../../contexts/types';
import { getValidKonvaName } from '../../../../reducers/JGraph.reducer/Graph';
import Konva from 'konva';
import { TagNames } from '../../utils/types';
import { TransitionCircle } from '../parts/TransitionCircle';
import { AutopositionGroup } from '../parts/AutopositionGroup';

import GroupView from './GroupView';
import IncomingConnectionsList, { PathContext } from './IncomingConnectionsList';
import OutgoingConnectionsList from './OutgoingConnectionsList';

type IncomingOutgoingConnectionProps = {
  type: 'incoming' | 'outgoing';
  position: Vector2d;
  groupedConnections: GroupedConnector[];
  connectorsFromStore$: Observable<ConnectorStore>;
  selectedTheme?: JGraphTheme;
  onSelectTheme: (theme: JGraphTheme) => void;
};

const fullWidth = 226;

export const IncomingOutgoingConnections: FC<IncomingOutgoingConnectionProps> = React.memo(
  ({ type, position, groupedConnections, connectorsFromStore$, selectedTheme, onSelectTheme }) => {
    const [collapsedToggle, , , toggleCollapsedToggle] = useToggle(false);
    const StateRef = useRef<Konva.Group | null>(null);

    const groupedSortedConnections = useMemo(() => {
      return [...groupedConnections].sort((a, b) => -(a.theme.value === selectedTheme?.value));
    }, [groupedConnections, selectedTheme?.value]);

    const recalculateConnections = useCallback(
      (event: KonvaEventObject<DragEvent>) => {
        const stage = getStageFromEvent(event);
        if (!stage) return;
        const connectionsLayer = stage.children && stage.children[0];
        if (!connectionsLayer) return;
        const connections = connectionsLayer.children
          ?.map(child => child.attrs?.connector)
          .filter(v => v) as TConnector[];
        const actionNodeIds = connections.map(connection =>
          type === 'incoming' ? connection.fromNode : String(connection.to)
        );
        recalculateAffectedConnections(connections, connectorsFromStore$, stage, actionNodeIds);
      },
      [connectorsFromStore$, type]
    );

    const allConnections = useMemo(() => groupedConnections.flatMap(group => group.connectors), [groupedConnections]);

    useEffect(() => {
      let connectorsFromPipeSave$: Subject<ConnectorStore> | null = null;
      const uniqStatePaths = Array.from(
        new Set(allConnections.map(c => (type === 'incoming' ? c.fromNodeOriginalPath : c.toNodeOriginalPath)))
      ).filter(Boolean);
      let valueToStore: Record<
        string,
        {
          fromRef?: Konva.Circle | Konva.Group;
          fromRefFallBack?: Konva.Circle;
          toRef?: Konva.Group | null;
        }
      > = {};
      uniqStatePaths.forEach(statePath => {
        valueToStore = {
          ...valueToStore,
          [getValidKonvaName(statePath)]: {
            toRef: StateRef.current,
          },
        };
      });

      if (StateRef.current && collapsedToggle) {
        const { connectorsFromPipe$ } = getStageContextFromRef(StateRef);
        connectorsFromPipeSave$ = connectorsFromPipe$;
        if (type === 'outgoing') {
          connectorsFromPipe$.next(valueToStore);
        }
      }
      return () => {
        if (connectorsFromPipeSave$ && type === 'outgoing') {
          uniqStatePaths.forEach(statePath => {
            valueToStore = {
              ...valueToStore,
              [getValidKonvaName(statePath)]: {
                toRef: null,
              },
            };
          });
          connectorsFromPipeSave$!.next(valueToStore);
        }
      };
    }, [collapsedToggle, allConnections, type]);

    useEffect(() => {
      const stage = getStageFromRef(StateRef);
      if (!stage) return;
      recalculateAffectedConnections(allConnections, connectorsFromStore$, stage);
    }, [allConnections, connectorsFromStore$, position]);

    const isCollapsed = allConnections.length === 0 || collapsedToggle;

    return (
      <Group
        id={`IncomingOutgoing_${type}`}
        x={position.x || 0}
        y={position.y || 0}
        isScreen={false}
        onDragMove={recalculateConnections}
      >
        <AutosizeRect
          width={fullWidth}
          fill='#F4F5F5'
          stroke='#E0E1E3'
          cornerRadius={8}
          bottomPadding={isCollapsed ? 4 : 12}
        >
          <Group
            x={12}
            y={8}
            ref={StateRef}
            isCollapsed={isCollapsed}
            isInIncomingConnectionsBlock={type === 'incoming'}
            isInOutgoingConnectionsBlock={type !== 'incoming'}
          >
            <KIcon width={16} height={16} x={0} y={0} icon={IconNames[type]} />
            <Text
              x={24}
              width={154}
              ellipsis
              wrap='none'
              text={t(`IncomingOutgoingConnections:${type}Title`)}
              fill='#0D1822'
              fontSize={12}
              lineHeight={18 / 12}
              letterSpacing={0.01}
            />

            <AutopositionGroup width={fullWidth} yOffset={-8}>
              {isCollapsed &&
                type === 'incoming' &&
                allConnections.map(connection => (
                  <Group key={connection.toNodeOriginalPath + connection.fromNodeOriginalPath}>
                    <PathContext.Provider value={{ path: connection.fromNodeOriginalPath }}>
                      <TransitionCircle
                        key={connection.from}
                        isInIncomingConnectionsBlock={type === 'incoming'}
                        isInOutgoingConnectionsBlock={type !== 'incoming'}
                        isCollapsed={isCollapsed}
                        x={178}
                        y={0}
                        name={connection.from}
                        tagName={connection.tagName as TagNames}
                        transitionTo={connection.toNodeOriginalPath}
                        hasTransition={true}
                        canStartConnection={false}
                        debugActive={connection.debugActive}
                      />
                    </PathContext.Provider>
                    <Rect width={16} height={16} x={170} y={-8} fill='#F4F5F5' />
                  </Group>
                ))}
            </AutopositionGroup>

            <KIcon
              width={16}
              height={16}
              x={198 - 12}
              y={0}
              icon={collapsedToggle ? IconNames.collapsible_open : IconNames.collapsible_close}
              onClick={toggleCollapsedToggle}
            />
          </Group>
          {!isCollapsed && (
            <Group y={32} x={0} key={allConnections.length}>
              {type === 'incoming' &&
                groupedSortedConnections.map(group => {
                  if (selectedTheme?.value === group.theme.value) {
                    return <IncomingConnectionsList key={group.theme.value} group={group} width={fullWidth - 24} />;
                  }
                  return (
                    <GroupView key={group.theme.value} group={group} onSelectTheme={onSelectTheme} width={fullWidth}>
                      <IncomingConnectionsList
                        key={group.theme.value}
                        group={group}
                        width={fullWidth - 24}
                        xOffset={0}
                      />
                    </GroupView>
                  );
                })}
              {type === 'outgoing' &&
                groupedSortedConnections.map(group => {
                  if (selectedTheme?.value === group.theme.value) {
                    return <OutgoingConnectionsList key={group.theme.value} group={group} width={fullWidth} />;
                  }
                  return (
                    <GroupView key={group.theme.value} group={group} onSelectTheme={onSelectTheme} width={fullWidth}>
                      <OutgoingConnectionsList group={group} width={fullWidth} xOffset={0} />
                    </GroupView>
                  );
                })}
            </Group>
          )}
        </AutosizeRect>
      </Group>
    );
  }
);
IncomingOutgoingConnections.displayName = 'IncomingOutgoingConnections';
