import {
  FETCH_CHANNELS_FULFILLED,
  FETCH_CHANNELS_PENDING,
  FETCH_CHANNELS_REJECTED,
  FETCH_DIALOGS_STATISTICS_FULFILLED,
  FETCH_MESSAGE_STATISTICS_FULFILLED,
  FETCH_SESSION_STATISTICS_FULFILLED,
  FETCH_STATISTICS_FULFILLED,
  FETCH_STATISTICS_PENDING,
  FETCH_STATISTICS_REJECTED,
  FETCH_SWITCHED_STATISTICS_FULFILLED,
  FETCH_TELEPHONY_STATISTICS_FULFILLED,
  FETCH_UNIQ_USERS_STATISTICS_FULFILLED,
  FETCH_UNRECOGNIZED_STATISTICS_FULFILLED,
  FETCH_USER_MESSAGE_STATISTICS_FULFILLED,
  FILTER_FULFILLED,
  FILTER_PENDING,
  FILTER_REJECTED,
  GET_TELEPHONY_REPORT_DETAIL_FULFILLED,
  GET_TELEPHONY_REPORT_DETAIL_PENDING,
  GET_TELEPHONY_REPORT_DETAIL_REJECTED,
  SAVE_REPORT_PARAMETERS_FULFILLED,
  SAVE_REPORT_PARAMETERS_PENDING,
  SAVE_REPORT_PARAMETERS_REJECTED,
  SET_CHANNELS_AS_FETCHED,
  UPDATE_FILTER_PARAMS,
} from '../../constants/projectReportFilter.actions';

import localize from '../../localization';
import { parseErrors } from '../../pipes/functions';

import { LOGOUT_FULFILLED } from '../../constants/currentUser.actions';

import { LOGIN_TO_ACCOUNT_FULFILLED, LOGOUT_FROM_ACCOUNT_FULFILLED } from '../../constants/accounts.actions';

import moment from 'moment';
import { joinStatsWithUniqueName } from './utils';
import { SET_CURRENT_PROJECT } from '../../constants/projects.actions';

const dateFormat = 'DD.MM.YYYY';

export const getChartMonths = dates => {
  //todo get current locale
  const locale = localStorage.getItem('USER_LANGUAGE') || 'ru';
  if (dates.length > 0) {
    let startMonthDate = new Date(dates[0]);
    let startMonthName = startMonthDate.toLocaleString(locale, { month: 'long' });
    let endMonthDate = new Date(dates[dates.length - 1]);
    let endMonthName = endMonthDate.toLocaleString(locale, { month: 'long' });

    return { startMonthName, endMonthName };
  }
  return null;
};

const InitialState = {
  reportType: 'review',
  telephonyReportType: 'telephony',
  transferReportType: 'transfer',
  filterParametersInitialized: false,
  channels: [],
  selectedChannels: ['all'],
  dateFrom: moment().subtract(7, 'days').format(dateFormat),
  dateTo: moment().format(dateFormat),
  fetching: false,
  fetchingReportParameters: false,
  errorsReportParameters: [],
  fetched: false,
  fetchedChannels: false,
  errors: [],
  clientActivityChartData: null,
  developmentActivityChartData: null,
  messagesChartData: null,
  dialogsChartData: null,
  switchedSessionsChartData: null,
  unrecognizedRequestsChartData: null,
  unrecognizedPercentChartData: null,
  projectName: '',
  chartMonths: {
    startMonthName: '',
    endMonthName: '',
  },
  messagesCount: 0,
  sessionsCount: 0,
  uniqueClientsCount: 0,
  issuesCreatedCount: 0,
  issuesFinishedCount: 0,
  dialogsCount: 0,
  switchedSessionsTotalCount: 0,
  unrecognizedRequestsTotalCount: 0,
  userMessagesTotalCount: 0,

  telephonyBillingTimeTotalCount: 0,
  telephonyOutgoingCallsTotalCount: 0,
  telephonyIncomingCallsTotalCount: 0,
  telephonyAverageCallDurationInSeconds: 0,
  telephonyBillingTimeChartData: null,
  telephonyCallsChartData: null,
  fetchingReportDownload: false,
};

/**
 * @param response
 * @return {string[]}
 */
const getErrors = response => {
  const responseData = response?.data;
  if (!responseData) return [];
  if (responseData.error) return [responseData.error];
  if (Array.isArray(responseData.errors)) return responseData.errors;
  return [];
};

export default function ProjectReportFilterReducer(state = InitialState, action) {
  switch (action.type) {
    case SET_CHANNELS_AS_FETCHED: {
      return {
        ...state,
        fetchedChannels: true,
      };
    }
    case UPDATE_FILTER_PARAMS: {
      return {
        ...state,
        filterParametersInitialized: true,
        selectedChannels: action.params.hasOwnProperty('channels') ? action.params.channels : state.selectedChannels,
        dateFrom: action.params.hasOwnProperty('dateFrom') ? action.params.dateFrom : state.dateFrom,
        dateTo: action.params.hasOwnProperty('dateTo') ? action.params.dateTo : state.dateTo,
        reportType: action.params.hasOwnProperty('reportType') ? action.params.reportType : state.reportType,
        telephonyReportType: state.telephonyReportType,
      };
    }

    case FETCH_STATISTICS_PENDING:
    case FILTER_PENDING: {
      return {
        ...state,
        fetching: true,
        fetched: false,
      };
    }

    case SAVE_REPORT_PARAMETERS_PENDING: {
      return {
        ...state,
        errorsReportParameters: InitialState.errorsReportParameters,
        fetchingReportParameters: true,
      };
    }

    case SAVE_REPORT_PARAMETERS_REJECTED: {
      const errors = parseErrors(action.payload.response);
      return {
        ...state,
        errorsReportParameters: errors,
        fetchingReportParameters: false,
      };
    }

    case SAVE_REPORT_PARAMETERS_FULFILLED: {
      return {
        ...state,
        fetchingReportParameters: false,
      };
    }

    case FETCH_CHANNELS_PENDING: {
      return {
        ...state,
        fetching: true,
        fetchedChannels: false,
      };
    }

    case FETCH_STATISTICS_REJECTED:
    case FILTER_REJECTED: {
      const errors = getErrors(action.payload.response);

      return {
        ...state,
        fetching: false,
        fetched: true,
        errors: errors,
      };
    }

    case FETCH_CHANNELS_REJECTED: {
      const errors = getErrors(action.payload.response);

      return {
        ...state,
        fetching: false,
        fetchedChannels: true,
        errors: errors,
      };
    }

    case FILTER_FULFILLED: {
      return {
        ...state,
        fetching: false,
        fetched: true,
      };
    }

    case FETCH_CHANNELS_FULFILLED: {
      return {
        ...state,
        fetching: false,
        fetchedChannels: true,
        channels: action.payload.data,
      };
    }

    case LOGIN_TO_ACCOUNT_FULFILLED:
    case LOGOUT_FROM_ACCOUNT_FULFILLED:
    case LOGOUT_FULFILLED: {
      return {
        ...InitialState,
      };
    }

    case FETCH_MESSAGE_STATISTICS_FULFILLED: {
      const { payload } = action;
      const data = payload.stats ? payload.stats : payload;
      const messagesChartData = {
        labels: data.map(item => new Date(item.ts).getDate()),
        datasets: [
          {
            label: localize.translate('Messages'),
            data: data.map(item => item.value),
            fill: false,
            borderColor: 'rgb(255, 127, 80)',
            lineTension: 0.1,
          },
        ],
      };
      const messagesCount = payload.totalCount || data.reduce((sum, current) => sum + current.value, 0);

      return {
        ...state,
        messagesChartData,
        messagesCount,
        chartMonths: getChartMonths(data.map(item => item.value)),
      };
    }
    case FETCH_SESSION_STATISTICS_FULFILLED: {
      let datasets = state.clientActivityChartData?.datasets ? [...state.clientActivityChartData.datasets] : [];
      const { payload } = action;
      const data = payload.stats ? payload.stats : action.payload;
      datasets = joinStatsWithUniqueName(datasets, {
        label: localize.translate('Sessions'),
        data: data.map(item => item.value),
        fill: false,
        borderColor: 'rgb(220, 20, 60)',
        lineTension: 0.1,
      });

      const clientActivityChartData = {
        labels: data.map(item => new Date(item.ts).getDate()),
        datasets,
      };

      const sessionsCount = payload.totalCount || data.reduce((sum, current) => sum + current.value, 0);

      return {
        ...state,
        clientActivityChartData,
        sessionsCount,
        chartMonths: getChartMonths(data.map(item => item.ts)),
      };
    }
    case FETCH_UNIQ_USERS_STATISTICS_FULFILLED: {
      let datasets = state.clientActivityChartData?.datasets ? [...state.clientActivityChartData.datasets] : [];
      const { payload } = action;
      const data = payload.stats ? payload.stats : payload;
      datasets = joinStatsWithUniqueName(datasets, {
        label: localize.translate('Unique users'),
        data: data.map(item => item.value),
        fill: false,
        borderColor: 'rgb(255, 215, 0)',
        lineTension: 0,
      });

      const clientActivityChartData = {
        labels: data.map(item => new Date(item.ts).getDate()),
        datasets,
      };
      const uniqueClientsCount = payload.totalCount || data.reduce((sum, current) => sum + current.value, 0);

      return {
        ...state,
        clientActivityChartData,
        uniqueClientsCount,
        chartMonths: getChartMonths(data.map(item => item.ts)),
      };
    }
    case FETCH_DIALOGS_STATISTICS_FULFILLED: {
      const data = action.payload.stats ? action.payload.stats : action.payload;
      const dialogsChartData = {
        labels: data.map(dateMilliseconds => new Date(dateMilliseconds.ts).getDate()),
        datasets: [
          {
            label: localize.translate('Dialogs'),
            data: data.map(values => values.value),
            fill: false,
            borderColor: '#8000FF',
            lineTension: 0.1,
          },
        ],
      };
      const dialogsCount = data.reduce((sum, current) => sum + current.value, 0);
      return {
        ...state,
        dialogsChartData,
        dialogsCount,
        chartMonths: getChartMonths(data.map(item => item.ts)),
      };
    }
    case FETCH_SWITCHED_STATISTICS_FULFILLED: {
      const data = action.payload.stats ? action.payload.stats : action.payload;

      const switchedSessionsChartData = {
        labels: data.map(dateMilliseconds => new Date(dateMilliseconds.ts).getDate()),
        datasets: [
          {
            label: localize.translate('Transferred sessions'),
            data: data.map(dateValue => dateValue.value),
            fill: false,
            borderColor: '#8000FF',
            lineTension: 0.1,
          },
          {
            ...state.clientActivityChartData?.datasets[0],
            label: localize.translate('Sessions count'),
          },
        ],
      };
      const switchedSessionsTotalCount = data.reduce((sum, current) => sum + current.value, 0);
      return {
        ...state,
        switchedSessionsChartData,
        switchedSessionsTotalCount,
        chartMonths: getChartMonths(data.map(item => item.ts)),
      };
    }

    case FETCH_UNRECOGNIZED_STATISTICS_FULFILLED: {
      let newState = { ...state };
      const data = action.payload.stats ? action.payload.stats : action.payload;

      const unrecognizedRequestsLabels = data.map(data => {
        return new Date(data.ts).getDate();
      });

      const unrecognizedRequestsTotalCount = data.reduce((sum, current) => {
        return sum + parseInt(current.value);
      }, 0);
      const userMessages = state.messagesChartData.datasets.find(
        dataset => dataset.label === localize.translate('Messages')
      );
      let userMessagesTotalCount = 0;
      if (userMessages) {
        userMessagesTotalCount = userMessages.data.reduce((sum, current) => {
          return sum + current;
        }, 0);
      }

      const unrecognizedRequestsChartData = {
        labels: unrecognizedRequestsLabels,
        datasets: [
          {
            label: localize.translate('Requests count'),
            data: userMessages.data,
            fill: false,
            borderColor: 'rgb(255, 127, 80)',
            lineTension: 0.1,
          },
          {
            label: localize.translate('Unrecognized requests count'),
            data: data.map(value => value.value),
            fill: false,
            borderColor: '#8000FF',
            lineTension: 0.1,
          },
        ],
      };

      const unrecognizedPercent = data.map((value, index) => {
        return userMessages.data[index] === 0 ? 0 : Math.round((value.value / userMessages.data[index]) * 100);
      });

      const unrecognizedPercentChartData = {
        labels: unrecognizedRequestsLabels,
        datasets: [
          {
            label: localize.translate('Percent unrecognized requests'),
            data: unrecognizedPercent,
            fill: false,
            borderColor: '#D9534F',
            lineTension: 0.1,
          },
        ],
      };

      newState.unrecognizedRequestsChartData = unrecognizedRequestsChartData;
      newState.unrecognizedPercentChartData = unrecognizedPercentChartData;
      newState.unrecognizedRequestsTotalCount = unrecognizedRequestsTotalCount;
      newState.userMessagesTotalCount = userMessagesTotalCount;
      newState.chartMonths = getChartMonths(data.map(data => data.ts));

      return {
        ...state,
        ...newState,
      };
    }
    case FETCH_USER_MESSAGE_STATISTICS_FULFILLED: {
      return {
        ...state,
      };
    }

    case FETCH_STATISTICS_FULFILLED: {
      const {
        messagesCount,
        sessionsCount,
        uniqueClientsCount,
        issuesCreatedCount,
        issuesFinishedCount,
        dialogsCount,
        projectName,
        switchedSessionsCount,
        unrecognizedRequests,
        userMessagesCount,
        telephonyBillingTimeCount,
        telephonyOutgoingCallsCount,
        telephonyIncomingCallsCount,
        telephonyAverageCallDurationInSeconds,
      } = action.payload.data;

      const newState = {
        ...state,
        projectName: projectName,
        fetching: false,
        fetched: true,
        errorsReportParameters: InitialState.errorsReportParameters,
      };
      if (Boolean(messagesCount)) {
        const messagesLabels = messagesCount.dates.map(dateMilliseconds => {
          return new Date(dateMilliseconds).getDate();
        });

        const messagesTotalCount = messagesCount.values.reduce((sum, current) => {
          return sum + current;
        }, 0);

        const messagesChartData = {
          labels: messagesLabels,
          datasets: [
            {
              label: localize.translate('Messages'),
              data: messagesCount.values,
              fill: false,
              borderColor: 'rgb(255, 127, 80)',
              lineTension: 0.1,
            },
          ],
        };

        newState.messagesChartData = messagesChartData;
        newState.messagesCount = messagesTotalCount;
        newState.chartMonths = getChartMonths(messagesCount.dates);
      }
      if (Boolean(sessionsCount) && Boolean(uniqueClientsCount)) {
        const clientActivityLabels = sessionsCount.dates.map(dateMilliseconds => {
          return new Date(dateMilliseconds).getDate();
        });

        const sessionsTotalCount = sessionsCount.values.reduce((sum, current) => {
          return sum + current;
        }, 0);

        const uniqueClientsTotalCount = uniqueClientsCount.values.reduce((sum, current) => {
          return sum + current;
        }, 0);

        const clientActivityChartData = {
          labels: clientActivityLabels,
          datasets: [
            {
              label: localize.translate('Sessions'),
              data: sessionsCount.values,
              fill: false,
              borderColor: 'rgb(220, 20, 60)',
              lineTension: 0.1,
            },
            {
              label: localize.translate('Unique users'),
              data: uniqueClientsCount.values,
              fill: false,
              borderColor: 'rgb(255, 215, 0)',
              lineTension: 0.1,
            },
          ],
        };

        newState.clientActivityChartData = clientActivityChartData;
        newState.sessionsCount = sessionsTotalCount;
        newState.uniqueClientsCount = uniqueClientsTotalCount;
        newState.chartMonths = getChartMonths(sessionsCount.dates);
      }

      if (Boolean(issuesFinishedCount) && Boolean(issuesCreatedCount)) {
        const developmetActivityLabels = issuesFinishedCount.dates.map(dateMilliseconds => {
          return new Date(dateMilliseconds).getDate();
        });

        const issuesFinishedTotalCount = issuesFinishedCount.values.reduce((sum, current) => {
          return sum + current;
        }, 0);

        const issuesCreatedTotalCount = issuesCreatedCount.values.reduce((sum, current) => {
          return sum + current;
        }, 0);

        const developmentActivityChartData = {
          labels: developmetActivityLabels,
          datasets: [
            {
              label: localize.translate('Number of completed tasks'),
              data: issuesFinishedCount.values,
              fill: false,
              borderColor: '#D9534F',
              lineTension: 0.1,
            },
            {
              label: localize.translate('Number of tasks created'),
              data: issuesCreatedCount.values,
              fill: false,
              borderColor: '#5cb85c',
              lineTension: 0.1,
            },
          ],
        };

        newState.developmentActivityChartData = developmentActivityChartData;
        newState.issuesFinishedCount = issuesFinishedTotalCount;
        newState.issuesCreatedCount = issuesCreatedTotalCount;
        newState.chartMonths = getChartMonths(issuesFinishedCount.dates);
      }

      if (Boolean(dialogsCount)) {
        const dialogsActivityLabels = dialogsCount.dates.map(dateMilliseconds => {
          return new Date(dateMilliseconds).getDate();
        });

        const dialogsTotalCount = dialogsCount.values.reduce((sum, current) => {
          return sum + current;
        }, 0);

        const dialogsChartData = {
          labels: dialogsActivityLabels,
          datasets: [
            {
              label: localize.translate('Dialogs'),
              data: dialogsCount.values,
              fill: false,
              borderColor: '#8000FF',
              lineTension: 0.1,
            },
          ],
        };

        newState.dialogsChartData = dialogsChartData;
        newState.dialogsCount = dialogsTotalCount;
        newState.chartMonths = getChartMonths(dialogsCount.dates);
      }

      if (Boolean(switchedSessionsCount) && Boolean(sessionsCount)) {
        const switchedSessionsLabels = switchedSessionsCount.dates.map(dateMilliseconds => {
          return new Date(dateMilliseconds).getDate();
        });

        const sessionsTotalCount = sessionsCount.values.reduce((sum, current) => {
          return sum + current;
        }, 0);

        const switchedSessionsTotalCount = switchedSessionsCount.values.reduce((sum, current) => {
          return sum + current;
        }, 0);

        const switchedSessionsChartData = {
          labels: switchedSessionsLabels,
          datasets: [
            {
              label: localize.translate('Sessions count'),
              data: sessionsCount.values,
              fill: false,
              borderColor: 'rgb(220, 20, 60)',
              lineTension: 0.1,
            },
            {
              label: localize.translate('Transferred sessions'),
              data: switchedSessionsCount.values,
              fill: false,
              borderColor: '#8000FF',
              lineTension: 0.1,
            },
          ],
        };

        newState.switchedSessionsChartData = switchedSessionsChartData;
        newState.sessionsCount = sessionsTotalCount;
        newState.switchedSessionsTotalCount = switchedSessionsTotalCount;
        newState.chartMonths = getChartMonths(sessionsCount.dates);
      }

      if (Boolean(userMessagesCount) && Boolean(unrecognizedRequests)) {
        const unrecognizedRequestsLabels = unrecognizedRequests.dates.map(dateMilliseconds => {
          return new Date(dateMilliseconds).getDate();
        });

        const unrecognizedRequestsTotalCount = unrecognizedRequests.values.reduce((sum, current) => {
          return sum + current;
        }, 0);

        const userMessagesTotalCount = userMessagesCount.values.reduce((sum, current) => {
          return sum + current;
        }, 0);

        const unrecognizedRequestsChartData = {
          labels: unrecognizedRequestsLabels,
          datasets: [
            {
              label: localize.translate('Requests count'),
              data: userMessagesCount.values,
              fill: false,
              borderColor: 'rgb(255, 127, 80)',
              lineTension: 0.1,
            },
            {
              label: localize.translate('Unrecognized requests count'),
              data: unrecognizedRequests.values,
              fill: false,
              borderColor: '#8000FF',
              lineTension: 0.1,
            },
          ],
        };

        const unrecognizedPercent = unrecognizedRequests.values.map((value, index) => {
          return userMessagesCount.values[index] === 0
            ? 0
            : Math.round((value / userMessagesCount.values[index]) * 100);
        });

        const unrecognizedPercentChartData = {
          labels: unrecognizedRequestsLabels,
          datasets: [
            {
              label: localize.translate('Percent unrecognized requests'),
              data: unrecognizedPercent,
              fill: false,
              borderColor: '#D9534F',
              lineTension: 0.1,
            },
          ],
        };

        newState.unrecognizedRequestsChartData = unrecognizedRequestsChartData;
        newState.unrecognizedPercentChartData = unrecognizedPercentChartData;
        newState.unrecognizedRequestsTotalCount = unrecognizedRequestsTotalCount;
        newState.userMessagesTotalCount = userMessagesTotalCount;
        newState.chartMonths = getChartMonths(userMessagesCount.dates);
      }

      if (Boolean(telephonyBillingTimeCount)) {
        const telephonyBillingTimeLabels = telephonyBillingTimeCount.dates.map(dateMilliseconds => {
          return new Date(dateMilliseconds).getDate();
        });

        const telephonyBillingTimeCountMinutes = telephonyBillingTimeCount.values.map(timeInSec =>
          Math.floor(timeInSec / 60)
        );

        const telephonyBillingTimeChartData = {
          labels: telephonyBillingTimeLabels,
          datasets: [
            {
              label: localize.translate('telephony report telephonyBillingTimeCount label'),
              data: telephonyBillingTimeCountMinutes,
              fill: false,
              borderColor: 'rgb(255, 127, 80)',
              lineTension: 0.1,
            },
          ],
        };

        const telephonyBillingTimeTotalCount = telephonyBillingTimeCount.values.reduce((sum, current) => {
          return sum + current;
        }, 0);

        newState.telephonyBillingTimeTotalCount = telephonyBillingTimeTotalCount;
        newState.telephonyBillingTimeChartData = telephonyBillingTimeChartData;
        newState.chartMonths = getChartMonths(telephonyBillingTimeCount.dates);
      }

      if (Boolean(telephonyOutgoingCallsCount)) {
        const telephonyOutgoingCallsTotalCount = telephonyOutgoingCallsCount.values.reduce((sum, current) => {
          return sum + current;
        }, 0);
        newState.telephonyOutgoingCallsTotalCount = telephonyOutgoingCallsTotalCount;
      }
      if (Boolean(telephonyIncomingCallsCount)) {
        const telephonyIncomingCallsTotalCount = telephonyIncomingCallsCount.values.reduce((sum, current) => {
          return sum + current;
        }, 0);
        newState.telephonyIncomingCallsTotalCount = telephonyIncomingCallsTotalCount;
      }

      if (Boolean(telephonyOutgoingCallsCount) && Boolean(telephonyIncomingCallsCount)) {
        const telephonyCallsLabels = telephonyOutgoingCallsCount.dates.map(dateMilliseconds => {
          return new Date(dateMilliseconds).getDate();
        });

        const telephonyCallsChartData = {
          labels: telephonyCallsLabels,
          datasets: [
            {
              label: localize.translate('telephony report telephonyOutgoingCallsCount label'),
              data: telephonyOutgoingCallsCount.values,
              fill: false,
              borderColor: 'rgb(255, 215, 0)',
              lineTension: 0.1,
            },
            {
              label: localize.translate('telephony report telephonyIncomingCallsCount label'),
              data: telephonyIncomingCallsCount.values,
              fill: false,
              borderColor: 'rgb(220, 20, 60)',
              lineTension: 0.1,
            },
          ],
        };

        newState.telephonyCallsChartData = telephonyCallsChartData;
      }

      if (Boolean(telephonyAverageCallDurationInSeconds) || telephonyAverageCallDurationInSeconds === 0) {
        newState.telephonyAverageCallDurationInSeconds = telephonyAverageCallDurationInSeconds;
      }

      return {
        ...state,
        ...newState,
      };
    }

    case FETCH_TELEPHONY_STATISTICS_FULFILLED: {
      const { averageCallDurationInSeconds, billingCount, incomingCount, outgoingCount } = action.payload;

      const newState = { ...state };

      const telephonyBillingTimeLabels = billingCount.map(timestampToValue => {
        return new Date(timestampToValue.ts).getDate();
      });

      const telephonyBillingTimeCountMinutes = billingCount.map(timestampToValue =>
        Math.floor(timestampToValue.value / 60)
      );

      newState.telephonyBillingTimeTotalCount = billingCount.reduce((sum, current) => sum + current.value, 0);
      newState.telephonyBillingTimeChartData = {
        labels: telephonyBillingTimeLabels,
        datasets: [
          {
            label: localize.translate('telephony report telephonyBillingTimeCount label'),
            data: telephonyBillingTimeCountMinutes,
            fill: false,
            borderColor: 'rgb(255, 127, 80)',
            lineTension: 0.1,
          },
        ],
      };
      newState.chartMonths = getChartMonths(billingCount.map(timestampToValue => timestampToValue.ts));

      if (Boolean(incomingCount)) {
        newState.telephonyIncomingCallsTotalCount = incomingCount.reduce((sum, current) => sum + current.value, 0);
      }
      if (Boolean(outgoingCount)) {
        newState.telephonyOutgoingCallsTotalCount = outgoingCount.reduce((sum, current) => sum + current.value, 0);
      }

      if (Boolean(outgoingCount) && Boolean(incomingCount)) {
        const telephonyCallsLabels = outgoingCount.map(timestampToValue => {
          return new Date(timestampToValue.ts).getDate();
        });

        newState.telephonyCallsChartData = {
          labels: telephonyCallsLabels,
          datasets: [
            {
              label: localize.translate('telephony report telephonyOutgoingCallsCount label'),
              data: outgoingCount.map(timestampToValue => timestampToValue.value),
              fill: false,
              borderColor: 'rgb(255, 215, 0)',
              lineTension: 0.1,
            },
            {
              label: localize.translate('telephony report telephonyIncomingCallsCount label'),
              data: incomingCount.map(timestampToValue => timestampToValue.value),
              fill: false,
              borderColor: 'rgb(220, 20, 60)',
              lineTension: 0.1,
            },
          ],
        };
      }

      return {
        ...state,
        ...newState,
        telephonyAverageCallDurationInSeconds: averageCallDurationInSeconds,
      };
    }

    case GET_TELEPHONY_REPORT_DETAIL_PENDING: {
      return {
        ...state,
        fetchingReportDownload: true,
      };
    }

    case GET_TELEPHONY_REPORT_DETAIL_FULFILLED:
    case GET_TELEPHONY_REPORT_DETAIL_REJECTED: {
      return {
        ...state,
        fetchingReportDownload: false,
      };
    }

    case SET_CURRENT_PROJECT: {
      return {
        ...InitialState,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
