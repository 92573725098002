interface Locator {
  column: number;
  row: number;
  fileName: string;
}

export interface TokenizerToken {
  tagName: string;
  locator: Locator;
  tagValue: string;
}

type Document = {
  fileName: string;
  content: string;
};

export class ScTokenizer {
  tokenize(document: Document): TokenizerToken[] {
    const result: TokenizerToken[] = [];
    if (!document.content) return result;

    const lines = document.content.split('\n');

    lines.forEach((line, rowIndex) => {
      const tagRegex = /^(\s*)((theme|state|go|go!):\s*)(.+?)\s*(\|\||$)/;
      const match = line.match(tagRegex);
      if (!match) return;
      const [, indent, tagName, , tagValue] = match;

      const startColumn = indent.length + 1;

      result.push({
        tagName,
        tagValue,
        locator: {
          column: startColumn,
          row: rowIndex,
          fileName: document.fileName,
        },
      });
    });

    return result;
  }
}
