export default class AccountInProductVerifier {
    errorInterceptor(error, triggerErrors = []) {
        var _a, _b;
        const requestErrorObj = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data;
        const errorStatus = (_b = error.response) === null || _b === void 0 ? void 0 : _b.status;
        const errorCode = requestErrorObj instanceof Object && "error" in requestErrorObj
            ? requestErrorObj.error
            : "";
        if (errorStatus === 403 &&
            (triggerErrors.length === 0 || triggerErrors.includes(errorCode))) {
            this.redirectToSelectAccount();
        }
    }
    checkAccountInfo(data) {
        const { accountData, domainData, allowedAccountsData } = data;
        const internalUiAvailable = domainData === null || domainData === void 0 ? void 0 : domainData.internalUserUiAvailable;
        const isInternalUser = accountData === null || accountData === void 0 ? void 0 : accountData.internal;
        const accountId = accountData === null || accountData === void 0 ? void 0 : accountData.accountId;
        if (isInternalUser && internalUiAvailable)
            return;
        if (!accountId &&
            (!isInternalUser || (isInternalUser && !internalUiAvailable))) {
            this.redirectToSelectAccount();
            return;
        }
        const allowedAccountsLength = allowedAccountsData === null || allowedAccountsData === void 0 ? void 0 : allowedAccountsData.length;
        const currentAccountFromAllowedAccounts = allowedAccountsData === null || allowedAccountsData === void 0 ? void 0 : allowedAccountsData.find((account) => account.id === accountId);
        const isCurrentUserOwner = accountData === null || accountData === void 0 ? void 0 : accountData.accountOwner;
        if (!accountId ||
            !allowedAccountsLength ||
            !(currentAccountFromAllowedAccounts === null || currentAccountFromAllowedAccounts === void 0 ? void 0 : currentAccountFromAllowedAccounts.availableToSelect) ||
            (domainData.ownerOnly && !isCurrentUserOwner)) {
            this.redirectToSelectAccount();
        }
    }
    redirectToSelectAccount() {
        window.location.href =
            window.isDev || process.env.NODE_ENV === "development"
                ? "https://localhost:3001/c/select-account"
                : "/c/select-account";
    }
}
