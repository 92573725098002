import { createContext, FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AccountInvitationDto, AllowedAccountListItem } from '@just-ai/api/dist/generated/Accountsadmin';
import ProjectGroupsService from '@just-ai/api/dist/services/AccountsadminService';
import AccountInProductVerifier from '@just-ai/domain-services/dist/AccountInProductVerifier';
import { axios, isDev } from '../../../pipes/functions';
import { cloudDomainsService } from 'services/CloudDomainsService';
import { Spinner } from '@just-ai/just-ui';
import { AppLogger } from '@just-ai/logger';

export type ProjectGroupsContextType = {
  projectGroupList: AllowedAccountListItem[];
  invitations: AccountInvitationDto[];
  currentProjectGroupObject: AllowedAccountListItem;
  ProjectGroupsService: ProjectGroupsService;
  projectShortName: string;
};

interface ProjectGroupsContextProviderType {
  projectShortName: string;
  currentUserId: number;
  currentUserInternal: boolean;
  currentUserAccountId: number;
  currentUserAccountOwner: boolean;
}

const accountInProductVerifier = new AccountInProductVerifier();
export const ProjectGroupsContext = createContext({} as ProjectGroupsContextType);

const ProjectGroupsContextProvider: FunctionComponent<ProjectGroupsContextProviderType> = ({
  children,
  currentUserId,
  currentUserInternal,
  currentUserAccountId,
  currentUserAccountOwner,
  projectShortName,
}) => {
  const ProjectGroupServiceInstance = useMemo(() => new ProjectGroupsService(axios), []);
  const [projectGroupList, setProjectGroups] = useState<AllowedAccountListItem[]>([]);
  const [invitations, setInvitations] = useState<AccountInvitationDto[]>([]);
  const [fetching, setFetching] = useState(true);

  const currentDomainData = isDev()
    ? cloudDomainsService.getProductDomainData('jaicp')
    : cloudDomainsService.getCurrentDomainData();

  const getProjectGroups = useCallback(async () => {
    return await ProjectGroupServiceInstance.getAllowedAccounts(currentUserId, currentDomainData?.product);
  }, [ProjectGroupServiceInstance, currentDomainData?.product, currentUserId]);

  const getInvitations = useCallback(async () => {
    return await ProjectGroupServiceInstance.getInvitationsByUser(currentUserId);
  }, [currentUserId, ProjectGroupServiceInstance]);

  const getAndCheckAccountData = useCallback(async () => {
    try {
      if (!currentUserId || !currentDomainData) return;
      setFetching(true);
      const allowedAccountsData = await getProjectGroups();
      const invitations = await getInvitations();

      accountInProductVerifier.checkAccountInfo({
        accountData: {
          internal: currentUserInternal,
          accountId: currentUserAccountId,
          accountOwner: currentUserAccountOwner,
        },
        domainData: currentDomainData,
        allowedAccountsData: allowedAccountsData || [],
      });

      setInvitations(invitations);
      setProjectGroups(allowedAccountsData);
    } catch (e) {
      AppLogger.error({
        message: 'Error in getting available accounts or invitations.',
        exception: e as Error,
      });
    } finally {
      setFetching(false);
    }
  }, [
    currentUserId,
    currentUserAccountId,
    currentUserAccountOwner,
    currentUserInternal,
    currentDomainData,
    getProjectGroups,
    getInvitations,
  ]);

  useEffect(() => {
    getAndCheckAccountData();
  }, [getAndCheckAccountData]);

  const currentProjectGroupObject = useMemo(
    () =>
      projectGroupList.find(group => {
        return group.id === currentUserAccountId;
      }) || ({} as AllowedAccountListItem),
    [currentUserAccountId, projectGroupList]
  );

  if (fetching) return <Spinner />;
  return (
    <ProjectGroupsContext.Provider
      value={{
        projectGroupList,
        invitations,
        currentProjectGroupObject,
        projectShortName,
        ProjectGroupsService: ProjectGroupServiceInstance,
      }}
    >
      {children}
    </ProjectGroupsContext.Provider>
  );
};

export const useProjectGroupsContext = () => useContext(ProjectGroupsContext);

export default ProjectGroupsContextProvider;
