import { t } from 'localization';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useJGraphContext } from '../../../../../../contexts/JGraphContext';
import { useToggle } from '@just-ai/just-ui/dist/utils';
import classes from './PhrasesAddingToggle.module.scss';
import cn from 'classnames';
import PhraseList from '@just-ai/nlu-modules/dist/views/FAQTreePage/components/PhrasesBlock/PhraseList';
import { IntentItem, IntentItemType } from '../../../../../../../Caila/model';
import { FilteredIntentItem } from '@just-ai/nlu-modules/dist/views/FAQTreePage/components/PhrasesBlock/types';
import { IntentData, PhraseMarkupData } from '@just-ai/api/dist/generated/Caila';
import { UncontrolledTooltipProps } from 'reactstrap/lib/Tooltip';
import { CustomRxTooltip } from '@just-ai/just-ui';

export const PhrasesAdding: FC<{ intent: string }> = ({ intent }) => {
  const { IntentsService, currentBotLanguage } = useJGraphContext();
  const [isAddingPhrases, , , toggleAddingPhrases] = useToggle(false);
  const [items, setItems] = useState<FilteredIntentItem[]>([]);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const isSystemIntent = useMemo(
    () => IntentsService.isSystemIntent(currentBotLanguage, intent),
    [IntentsService, currentBotLanguage, intent]
  );

  const intentDetailData = useRef<IntentData | undefined>(undefined);

  const setItemsHandler = useCallback((intentData: IntentData) => {
    setItems([
      ...(intentData.patterns || []).map((pattern, index) => ({
        id: `${intentData.id || 0}_pattern_${index}`,
        text: pattern!,
        type: IntentItemType.pattern,
      })),
      ...(intentData.phrases || []).map((phrase, index) => ({
        id: `${intentData.id || 0}_phrase_${index}`,
        text: phrase.text!,
        type: IntentItemType.phrase,
      })),
    ]);
  }, []);

  const updateData = useCallback(() => {
    const intentData = IntentsService.intents.find(storedIntent => storedIntent.path === intent);
    if (intentData && intentData.id) {
      return IntentsService.getIntent(intentData.id).then(payload => {
        intentDetailData.current = payload.data;
        return payload;
      });
    }
    return Promise.resolve<{ data: IntentData | null }>({ data: null });
  }, [IntentsService, intent]);

  useEffect(() => {
    if (!isSystemIntent) {
      const intentData = IntentsService.intents.find(storedIntent => storedIntent.path === intent);
      if (!intentData) {
        IntentsService.listIntents().then(() => {
          updateData().then(() => {
            if (intentDetailData.current) {
              setItemsHandler(intentDetailData.current);
            }
          });
        });
        return;
      }
      updateData().then(() => {
        if (intentDetailData.current) {
          setItemsHandler(intentDetailData.current);
        }
      });
    }
  }, [IntentsService, intent, isSystemIntent, setItemsHandler, updateData]);

  const onUpdate = useCallback(
    (intentItems: IntentItem[]) => {
      if (intentDetailData.current) {
        const intentPhrasesDataForSave = intentItems.reduce(
          (acc, currItem, index) => {
            switch (currItem.type) {
              case IntentItemType.pattern:
                acc.patterns.push(currItem.text);
                break;
              case IntentItemType.phrase:
                acc.phrases.push({ text: currItem.text });
                break;
            }
            return acc;
          },
          { phrases: [], patterns: [] } as { phrases: PhraseMarkupData[]; patterns: string[] }
        );
        setItemsHandler({
          ...intentDetailData.current,
          ...intentPhrasesDataForSave,
        });
        if (timeout.current) clearTimeout(timeout.current);
        const intentId = intentDetailData.current.id!;
        timeout.current = setTimeout(() => {
          IntentsService.saveIntentData(intentId, {
            ...intentDetailData.current,
            ...intentPhrasesDataForSave,
          }).then(() => {
            updateData();
          });
        }, 500);
      }
    },
    [IntentsService, setItemsHandler, updateData]
  );

  if (isSystemIntent)
    return (
      <div className='mt-3'>
        <PhrasesAddingToggle disabled={isSystemIntent} toggle={toggleAddingPhrases} />
      </div>
    );

  return (
    <div className='mt-3'>
      {!isAddingPhrases && <PhrasesAddingToggle disabled={isSystemIntent} toggle={toggleAddingPhrases} />}
      {isAddingPhrases && <PhraseList isSearching={false} filteredItems={[]} items={items} onChange={onUpdate} />}
    </div>
  );
};

const PhrasesAddingToggle: FC<{ disabled: boolean; toggle: () => unknown }> = ({ disabled, toggle }) => {
  return (
    <>
      <div
        id='PhrasesAddingToggle'
        className={cn('pseudoLink', classes.PhrasesAddingToggle, { [classes.disabled]: disabled })}
        onClick={() => (disabled ? undefined : toggle())}
      >
        {t('PhrasesAddingToggle:phrase')}
      </div>
      <PhrasesExtendDisabled
        show={disabled}
        targetId='#PhrasesAddingToggle'
        text={t('PhrasesAddingToggle:systemIntentTooltip')}
      />
    </>
  );
};

export const PhrasesExtendDisabled: FC<{
  show: boolean;
  targetId: string;
  text: string;
  placement?: UncontrolledTooltipProps['placement'];
}> = ({ show, targetId, placement = 'top', text }) => {
  return show ? (
    <CustomRxTooltip
      target={targetId}
      innerClassName={classes.tooltipCustomWidth}
      placement={placement}
      textAlign='left'
    >
      {text}
    </CustomRxTooltip>
  ) : null;
};
