//@ts-nocheck
// tslint:disable
/**
 * API Gateway
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as globalImportUrl from 'url';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, BaseAPI, RequiredError } from './base';
/**
 *
 * @export
 * @enum {string}
 */
export var TokenApiType;
(function (TokenApiType) {
    TokenApiType["PROJECTAPI"] = "PROJECT_API";
    TokenApiType["CHANNELAPI"] = "CHANNEL_API";
    TokenApiType["CALLSAPI"] = "CALLS_API";
    TokenApiType["IMPUTERAPI"] = "IMPUTER_API";
    TokenApiType["REPORTSAPI"] = "REPORTS_API";
    TokenApiType["CAILAAPI"] = "CAILA_API";
})(TokenApiType || (TokenApiType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var TokenSort;
(function (TokenSort) {
    TokenSort["CREATEDATASC"] = "CREATED_AT_ASC";
    TokenSort["CREATEDATDESC"] = "CREATED_AT_DESC";
    TokenSort["EXPIREDATASC"] = "EXPIRED_AT_ASC";
    TokenSort["EXPIREDATDESC"] = "EXPIRED_AT_DESC";
    TokenSort["NAMEASC"] = "NAME_ASC";
    TokenSort["NAMEDESC"] = "NAME_DESC";
})(TokenSort || (TokenSort = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var TokenStoppedType;
(function (TokenStoppedType) {
    TokenStoppedType["BYTIME"] = "BY_TIME";
    TokenStoppedType["MANUALLY"] = "MANUALLY";
})(TokenStoppedType || (TokenStoppedType = {}));
/**
 * ActiveTokenApi - axios parameter creator
 * @export
 */
export const ActiveTokenApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary create token
         * @param {CreateTokenDto} createTokenDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActiveToken(createTokenDto, options = {}) {
            // verify required parameter 'createTokenDto' is not null or undefined
            if (createTokenDto === null || createTokenDto === undefined) {
                throw new RequiredError('createTokenDto', 'Required parameter createTokenDto was null or undefined when calling createActiveToken.');
            }
            const localVarPath = `/api/api-gateway/tokens`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = (typeof createTokenDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(createTokenDto !== undefined ? createTokenDto : {}) : (createTokenDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary delete active token
         * @param {string} tokenId Token numeric identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteToken(tokenId, options = {}) {
            // verify required parameter 'tokenId' is not null or undefined
            if (tokenId === null || tokenId === undefined) {
                throw new RequiredError('tokenId', 'Required parameter tokenId was null or undefined when calling deleteToken.');
            }
            const localVarPath = `/api/api-gateway/tokens/{tokenId}`
                .replace(`{${"tokenId"}}`, encodeURIComponent(String(tokenId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary get active token by id
         * @param {string} tokenId Token numeric identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveToken(tokenId, options = {}) {
            // verify required parameter 'tokenId' is not null or undefined
            if (tokenId === null || tokenId === undefined) {
                throw new RequiredError('tokenId', 'Required parameter tokenId was null or undefined when calling getActiveToken.');
            }
            const localVarPath = `/api/api-gateway/tokens/{tokenId}`
                .replace(`{${"tokenId"}}`, encodeURIComponent(String(tokenId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary get active token types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveTypes(options = {}) {
            const localVarPath = `/api/api-gateway/tokens/active-types`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary get all active tokens
         * @param {string} [searchByIdNameAndLogin] text for find by tokenId, name, login
         * @param {Array<TokenApiType>} [apiTypes] token types
         * @param {TokenSort} [sort] token sort
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActiveTokens(searchByIdNameAndLogin, apiTypes, sort, page, size, options = {}) {
            const localVarPath = `/api/api-gateway/tokens`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (searchByIdNameAndLogin !== undefined) {
                localVarQueryParameter['searchByIdNameAndLogin'] = searchByIdNameAndLogin;
            }
            if (apiTypes) {
                localVarQueryParameter['apiTypes'] = apiTypes;
            }
            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary get available types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableTypes(options = {}) {
            const localVarPath = `/api/api-gateway/tokens/available-types`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary refresh token
         * @param {string} tokenId Token numeric identifier
         * @param {RefreshTokenDto} refreshTokenDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(tokenId, refreshTokenDto, options = {}) {
            // verify required parameter 'tokenId' is not null or undefined
            if (tokenId === null || tokenId === undefined) {
                throw new RequiredError('tokenId', 'Required parameter tokenId was null or undefined when calling refreshToken.');
            }
            // verify required parameter 'refreshTokenDto' is not null or undefined
            if (refreshTokenDto === null || refreshTokenDto === undefined) {
                throw new RequiredError('refreshTokenDto', 'Required parameter refreshTokenDto was null or undefined when calling refreshToken.');
            }
            const localVarPath = `/api/api-gateway/tokens/refresh/{tokenId}`
                .replace(`{${"tokenId"}}`, encodeURIComponent(String(tokenId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = (typeof refreshTokenDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(refreshTokenDto !== undefined ? refreshTokenDto : {}) : (refreshTokenDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary update active token
         * @param {string} tokenId Token numeric identifier
         * @param {UpdateTokenDto} updateTokenDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActiveToken(tokenId, updateTokenDto, options = {}) {
            // verify required parameter 'tokenId' is not null or undefined
            if (tokenId === null || tokenId === undefined) {
                throw new RequiredError('tokenId', 'Required parameter tokenId was null or undefined when calling updateActiveToken.');
            }
            // verify required parameter 'updateTokenDto' is not null or undefined
            if (updateTokenDto === null || updateTokenDto === undefined) {
                throw new RequiredError('updateTokenDto', 'Required parameter updateTokenDto was null or undefined when calling updateActiveToken.');
            }
            const localVarPath = `/api/api-gateway/tokens/{tokenId}`
                .replace(`{${"tokenId"}}`, encodeURIComponent(String(tokenId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = (typeof updateTokenDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(updateTokenDto !== undefined ? updateTokenDto : {}) : (updateTokenDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * ActiveTokenApi - functional programming interface
 * @export
 */
export const ActiveTokenApiFp = function (configuration) {
    return {
        /**
         *
         * @summary create token
         * @param {CreateTokenDto} createTokenDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActiveToken(createTokenDto, options) {
            const localVarAxiosArgs = ActiveTokenApiAxiosParamCreator(configuration).createActiveToken(createTokenDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary delete active token
         * @param {string} tokenId Token numeric identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteToken(tokenId, options) {
            const localVarAxiosArgs = ActiveTokenApiAxiosParamCreator(configuration).deleteToken(tokenId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary get active token by id
         * @param {string} tokenId Token numeric identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveToken(tokenId, options) {
            const localVarAxiosArgs = ActiveTokenApiAxiosParamCreator(configuration).getActiveToken(tokenId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary get active token types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveTypes(options) {
            const localVarAxiosArgs = ActiveTokenApiAxiosParamCreator(configuration).getActiveTypes(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary get all active tokens
         * @param {string} [searchByIdNameAndLogin] text for find by tokenId, name, login
         * @param {Array<TokenApiType>} [apiTypes] token types
         * @param {TokenSort} [sort] token sort
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActiveTokens(searchByIdNameAndLogin, apiTypes, sort, page, size, options) {
            const localVarAxiosArgs = ActiveTokenApiAxiosParamCreator(configuration).getAllActiveTokens(searchByIdNameAndLogin, apiTypes, sort, page, size, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary get available types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableTypes(options) {
            const localVarAxiosArgs = ActiveTokenApiAxiosParamCreator(configuration).getAvailableTypes(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary refresh token
         * @param {string} tokenId Token numeric identifier
         * @param {RefreshTokenDto} refreshTokenDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(tokenId, refreshTokenDto, options) {
            const localVarAxiosArgs = ActiveTokenApiAxiosParamCreator(configuration).refreshToken(tokenId, refreshTokenDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary update active token
         * @param {string} tokenId Token numeric identifier
         * @param {UpdateTokenDto} updateTokenDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActiveToken(tokenId, updateTokenDto, options) {
            const localVarAxiosArgs = ActiveTokenApiAxiosParamCreator(configuration).updateActiveToken(tokenId, updateTokenDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * ActiveTokenApi - factory interface
 * @export
 */
export const ActiveTokenApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary create token
         * @param {CreateTokenDto} createTokenDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActiveToken(createTokenDto, options) {
            return ActiveTokenApiFp(configuration).createActiveToken(createTokenDto, options)(axios, basePath);
        },
        /**
         *
         * @summary delete active token
         * @param {string} tokenId Token numeric identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteToken(tokenId, options) {
            return ActiveTokenApiFp(configuration).deleteToken(tokenId, options)(axios, basePath);
        },
        /**
         *
         * @summary get active token by id
         * @param {string} tokenId Token numeric identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveToken(tokenId, options) {
            return ActiveTokenApiFp(configuration).getActiveToken(tokenId, options)(axios, basePath);
        },
        /**
         *
         * @summary get active token types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveTypes(options) {
            return ActiveTokenApiFp(configuration).getActiveTypes(options)(axios, basePath);
        },
        /**
         *
         * @summary get all active tokens
         * @param {string} [searchByIdNameAndLogin] text for find by tokenId, name, login
         * @param {Array<TokenApiType>} [apiTypes] token types
         * @param {TokenSort} [sort] token sort
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActiveTokens(searchByIdNameAndLogin, apiTypes, sort, page, size, options) {
            return ActiveTokenApiFp(configuration).getAllActiveTokens(searchByIdNameAndLogin, apiTypes, sort, page, size, options)(axios, basePath);
        },
        /**
         *
         * @summary get available types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableTypes(options) {
            return ActiveTokenApiFp(configuration).getAvailableTypes(options)(axios, basePath);
        },
        /**
         *
         * @summary refresh token
         * @param {string} tokenId Token numeric identifier
         * @param {RefreshTokenDto} refreshTokenDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(tokenId, refreshTokenDto, options) {
            return ActiveTokenApiFp(configuration).refreshToken(tokenId, refreshTokenDto, options)(axios, basePath);
        },
        /**
         *
         * @summary update active token
         * @param {string} tokenId Token numeric identifier
         * @param {UpdateTokenDto} updateTokenDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActiveToken(tokenId, updateTokenDto, options) {
            return ActiveTokenApiFp(configuration).updateActiveToken(tokenId, updateTokenDto, options)(axios, basePath);
        },
    };
};
/**
 * ActiveTokenApi - object-oriented interface
 * @export
 * @class ActiveTokenApi
 * @extends {BaseAPI}
 */
export class ActiveTokenApi extends BaseAPI {
    /**
     *
     * @summary create token
     * @param {CreateTokenDto} createTokenDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActiveTokenApi
     */
    createActiveToken(createTokenDto, options) {
        return ActiveTokenApiFp(this.configuration).createActiveToken(createTokenDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary delete active token
     * @param {string} tokenId Token numeric identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActiveTokenApi
     */
    deleteToken(tokenId, options) {
        return ActiveTokenApiFp(this.configuration).deleteToken(tokenId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary get active token by id
     * @param {string} tokenId Token numeric identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActiveTokenApi
     */
    getActiveToken(tokenId, options) {
        return ActiveTokenApiFp(this.configuration).getActiveToken(tokenId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary get active token types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActiveTokenApi
     */
    getActiveTypes(options) {
        return ActiveTokenApiFp(this.configuration).getActiveTypes(options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary get all active tokens
     * @param {string} [searchByIdNameAndLogin] text for find by tokenId, name, login
     * @param {Array<TokenApiType>} [apiTypes] token types
     * @param {TokenSort} [sort] token sort
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActiveTokenApi
     */
    getAllActiveTokens(searchByIdNameAndLogin, apiTypes, sort, page, size, options) {
        return ActiveTokenApiFp(this.configuration).getAllActiveTokens(searchByIdNameAndLogin, apiTypes, sort, page, size, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary get available types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActiveTokenApi
     */
    getAvailableTypes(options) {
        return ActiveTokenApiFp(this.configuration).getAvailableTypes(options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary refresh token
     * @param {string} tokenId Token numeric identifier
     * @param {RefreshTokenDto} refreshTokenDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActiveTokenApi
     */
    refreshToken(tokenId, refreshTokenDto, options) {
        return ActiveTokenApiFp(this.configuration).refreshToken(tokenId, refreshTokenDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary update active token
     * @param {string} tokenId Token numeric identifier
     * @param {UpdateTokenDto} updateTokenDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActiveTokenApi
     */
    updateActiveToken(tokenId, updateTokenDto, options) {
        return ActiveTokenApiFp(this.configuration).updateActiveToken(tokenId, updateTokenDto, options)(this.axios, this.basePath);
    }
}
/**
 * E2ECheckApi - axios parameter creator
 * @export
 */
export const E2ECheckApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Method for E2ECheck.
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {string} [zRequestId] Request id header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck(loggingEnabledE2ECheck, zRequestId, options = {}) {
            // verify required parameter 'loggingEnabledE2ECheck' is not null or undefined
            if (loggingEnabledE2ECheck === null || loggingEnabledE2ECheck === undefined) {
                throw new RequiredError('loggingEnabledE2ECheck', 'Required parameter loggingEnabledE2ECheck was null or undefined when calling e2ECheck.');
            }
            const localVarPath = `/api/api-gateway/e2e-check`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (zRequestId !== undefined && zRequestId !== null) {
                localVarHeaderParameter['Z-requestId'] = String(zRequestId);
            }
            if (loggingEnabledE2ECheck !== undefined && loggingEnabledE2ECheck !== null) {
                localVarHeaderParameter['loggingEnabledE2ECheck'] = String(JSON.stringify(loggingEnabledE2ECheck));
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * E2ECheckApi - functional programming interface
 * @export
 */
export const E2ECheckApiFp = function (configuration) {
    return {
        /**
         * Method for E2ECheck.
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {string} [zRequestId] Request id header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck(loggingEnabledE2ECheck, zRequestId, options) {
            const localVarAxiosArgs = E2ECheckApiAxiosParamCreator(configuration).e2ECheck(loggingEnabledE2ECheck, zRequestId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * E2ECheckApi - factory interface
 * @export
 */
export const E2ECheckApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Method for E2ECheck.
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {string} [zRequestId] Request id header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck(loggingEnabledE2ECheck, zRequestId, options) {
            return E2ECheckApiFp(configuration).e2ECheck(loggingEnabledE2ECheck, zRequestId, options)(axios, basePath);
        },
    };
};
/**
 * E2ECheckApi - object-oriented interface
 * @export
 * @class E2ECheckApi
 * @extends {BaseAPI}
 */
export class E2ECheckApi extends BaseAPI {
    /**
     * Method for E2ECheck.
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {string} [zRequestId] Request id header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof E2ECheckApi
     */
    e2ECheck(loggingEnabledE2ECheck, zRequestId, options) {
        return E2ECheckApiFp(this.configuration).e2ECheck(loggingEnabledE2ECheck, zRequestId, options)(this.axios, this.basePath);
    }
}
/**
 * RemovedTokenApi - axios parameter creator
 * @export
 */
export const RemovedTokenApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary get all removed tokens
         * @param {string} [searchByIdNameAndLogin] text for find by tokenId, name, login
         * @param {Array<TokenApiType>} [apiTypes] token types
         * @param {TokenSort} [sort] token sort
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRemovedTokens(searchByIdNameAndLogin, apiTypes, sort, page, size, options = {}) {
            const localVarPath = `/api/api-gateway/removed-tokens`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (searchByIdNameAndLogin !== undefined) {
                localVarQueryParameter['searchByIdNameAndLogin'] = searchByIdNameAndLogin;
            }
            if (apiTypes) {
                localVarQueryParameter['apiTypes'] = apiTypes;
            }
            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * RemovedTokenApi - functional programming interface
 * @export
 */
export const RemovedTokenApiFp = function (configuration) {
    return {
        /**
         *
         * @summary get all removed tokens
         * @param {string} [searchByIdNameAndLogin] text for find by tokenId, name, login
         * @param {Array<TokenApiType>} [apiTypes] token types
         * @param {TokenSort} [sort] token sort
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRemovedTokens(searchByIdNameAndLogin, apiTypes, sort, page, size, options) {
            const localVarAxiosArgs = RemovedTokenApiAxiosParamCreator(configuration).getAllRemovedTokens(searchByIdNameAndLogin, apiTypes, sort, page, size, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * RemovedTokenApi - factory interface
 * @export
 */
export const RemovedTokenApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary get all removed tokens
         * @param {string} [searchByIdNameAndLogin] text for find by tokenId, name, login
         * @param {Array<TokenApiType>} [apiTypes] token types
         * @param {TokenSort} [sort] token sort
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRemovedTokens(searchByIdNameAndLogin, apiTypes, sort, page, size, options) {
            return RemovedTokenApiFp(configuration).getAllRemovedTokens(searchByIdNameAndLogin, apiTypes, sort, page, size, options)(axios, basePath);
        },
    };
};
/**
 * RemovedTokenApi - object-oriented interface
 * @export
 * @class RemovedTokenApi
 * @extends {BaseAPI}
 */
export class RemovedTokenApi extends BaseAPI {
    /**
     *
     * @summary get all removed tokens
     * @param {string} [searchByIdNameAndLogin] text for find by tokenId, name, login
     * @param {Array<TokenApiType>} [apiTypes] token types
     * @param {TokenSort} [sort] token sort
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RemovedTokenApi
     */
    getAllRemovedTokens(searchByIdNameAndLogin, apiTypes, sort, page, size, options) {
        return RemovedTokenApiFp(this.configuration).getAllRemovedTokens(searchByIdNameAndLogin, apiTypes, sort, page, size, options)(this.axios, this.basePath);
    }
}
/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options = {}) {
            const localVarPath = `/healthCheck`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Service version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(options = {}) {
            const localVarPath = `/version`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options) {
            const localVarAxiosArgs = SystemApiAxiosParamCreator(configuration).healthCheck(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Service version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(options) {
            const localVarAxiosArgs = SystemApiAxiosParamCreator(configuration).version(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options) {
            return SystemApiFp(configuration).healthCheck(options)(axios, basePath);
        },
        /**
         *
         * @summary Service version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(options) {
            return SystemApiFp(configuration).version(options)(axios, basePath);
        },
    };
};
/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     *
     * @summary Service health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    healthCheck(options) {
        return SystemApiFp(this.configuration).healthCheck(options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Service version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    version(options) {
        return SystemApiFp(this.configuration).version(options)(this.axios, this.basePath);
    }
}
