import React, { useRef, useState, useEffect } from 'react';
import { useToggle } from '@just-ai/just-ui';
import cn from 'classnames';

import icon from './assets/icon.svg';
import iconOutlined from './assets/iconOutlined.svg';

import { useAppSelector } from 'storeHooks';
import { RootState } from 'storeTypes';

import { buildIsolatedWidgetComponents } from './IsolatedWidget';

import styles from './styles.module.scss';

export const IWC = buildIsolatedWidgetComponents({
  iframePageUrl: '/llm-assistant-widget',
  iframeAdditionalContent: () => (
    <>
      <div id='widget-root'></div>
      <div id='drag-edge-horizontal' className={cn(styles.dragEdge, styles.dragEdge__horizontal)} />
      <div id='drag-edge-vertical' className={cn(styles.dragEdge, styles.dragEdge__vertical)} />
      <style type='text/css'>
        {`
          #widget-root .justwidget .justwidget--inner {
            padding-bottom: 8px;
          }
          #widget-root .justwidget .justwidget--inner .justwidget--headline-height-button-container {
            display: none;
          }
          #widget-root .justwidget .justwidget--messages .justwidget--message_content {
            max-width: max(280px, calc(50% - var(--avatar-width)));
          }
       `}
      </style>
    </>
  ),
});

type BoundingBox = { top: number; right: number; bottom: number; left: number };
type Rect = { x: number; y: number; width: number; height: number };

function isCorrectRectForBoundingBox(rect: Rect, boundingBox: BoundingBox): boolean {
  const viewPortWidth = window.innerWidth;
  const viewPortHeight = window.innerHeight;

  const maxHeight = viewPortHeight - boundingBox.top - boundingBox.bottom;
  if (rect.height > maxHeight) return false;
  const maxWidth = viewPortWidth - boundingBox.left - boundingBox.right;
  if (rect.width > maxWidth) return false;

  if (boundingBox.top - rect.y > 1) return false;
  if (boundingBox.left - rect.x > 1) return false;
  const bottom = viewPortHeight - rect.y - rect.height;
  if (boundingBox.bottom - bottom > 1) return false;
  const right = viewPortWidth - rect.x - rect.width;
  if (boundingBox.right - right > 1) return false;

  return true;
}

function applyBoundingBoxConstraints(
  iframe: HTMLIFrameElement,
  boundingBox: BoundingBox,
  rectConstraints?: { minWidth?: number; minHeight?: number }
) {
  const iframeRect = iframe.getBoundingClientRect();

  const rect = { x: iframeRect.x, y: iframeRect.y, width: iframeRect.width, height: iframeRect.height };
  const viewPortWidth = window.innerWidth;
  const viewPortHeight = window.innerHeight;

  if (rect.height > viewPortHeight - boundingBox.top - boundingBox.bottom) {
    rect.height = viewPortHeight - boundingBox.top - boundingBox.bottom;
  }
  if (rect.width > viewPortWidth - boundingBox.left - boundingBox.right) {
    rect.width = viewPortWidth - boundingBox.left - boundingBox.right;
  }
  if (rectConstraints) {
    if (rectConstraints.minHeight && rect.height < rectConstraints.minHeight) {
      rect.height = rectConstraints.minHeight;
    }
    if (rectConstraints.minWidth && rect.width < rectConstraints.minWidth) {
      rect.width = rectConstraints.minWidth;
    }
  }

  if (viewPortHeight - rect.height - rect.y < boundingBox.bottom) {
    rect.y = viewPortHeight - rect.height - boundingBox.bottom;
  }
  if (rect.y < boundingBox.top) {
    rect.y = boundingBox.top;
  }
  if (rect.x < boundingBox.left) {
    rect.x = boundingBox.left;
  }
  if (viewPortWidth - rect.x - rect.width < boundingBox.right) {
    rect.x = viewPortWidth - rect.width - boundingBox.right;
  }

  iframe.style.transform = `translate(${rect.x}px, ${rect.y}px)`;
  iframe.style.height = `${rect.height}px`;
  iframe.style.width = `${rect.width}px`;
}

function addFreeDragAbility(iframe: HTMLIFrameElement, grabbableNode: HTMLDivElement, boundingBox: BoundingBox) {
  const widgetNode = iframe.contentDocument!.getElementById('widget-root');
  if (!widgetNode) return;

  grabbableNode.addEventListener('mousedown', (downEvent: MouseEvent) => {
    const originalUserSelect = widgetNode.style.userSelect;
    widgetNode.style.userSelect = 'none';
    const recalculatePosition = (event: MouseEvent) => {
      const pos = {
        x: event.clientX - downEvent.clientX,
        y: event.clientY - downEvent.clientY,
      };
      iframe.style.transform = `translate(${pos.x}px, ${pos.y}px)`;
      applyBoundingBoxConstraints(iframe, boundingBox);
    };
    document.addEventListener('mousemove', recalculatePosition);

    iframe.contentDocument!.addEventListener(
      'mouseup',
      () => {
        widgetNode.style.userSelect = originalUserSelect;
        document.removeEventListener('mousemove', recalculatePosition);
      },
      { once: true }
    );
  });
}

function clearSelections(window: Window) {
  return window.getSelection()?.removeAllRanges();
}

function addResizeAbility(
  iframe: HTMLIFrameElement,
  options: { minWidth?: number; minHeight?: number; boundingBox: BoundingBox }
) {
  const widgetNode = iframe.contentDocument!.getElementById('widget-root');
  if (!widgetNode) return;

  const horizontal = iframe.contentDocument!.getElementById('drag-edge-horizontal');
  horizontal?.addEventListener('mousedown', (downEvent: MouseEvent) => {
    clearSelections(window);
    clearSelections(iframe.contentWindow!);
    const originalUserSelect = widgetNode.style.userSelect;
    widgetNode.style.userSelect = 'none';
    const frameRect = iframe.getBoundingClientRect();

    const recalculatePosition = (event: MouseEvent) => {
      const diffHeight = event.clientY - (downEvent.clientY + frameRect.y);
      let newHeight = frameRect.height + diffHeight;

      const isHeightGrowing = diffHeight > 0;
      if (options.minHeight !== undefined && newHeight < options.minHeight && !isHeightGrowing) return;
      const isCorrectChange = isCorrectRectForBoundingBox(
        {
          x: frameRect.x,
          y: frameRect.y,
          width: frameRect.width,
          height: newHeight,
        },
        options.boundingBox
      );
      if (!isCorrectChange) return;
      iframe.style.height = `${newHeight}px`;
    };
    document.addEventListener('mousemove', recalculatePosition);

    iframe.contentDocument!.addEventListener(
      'mouseup',
      () => {
        widgetNode.style.userSelect = originalUserSelect;
        document.removeEventListener('mousemove', recalculatePosition);
      },
      { once: true }
    );
  });

  const vertical = iframe.contentDocument!.getElementById('drag-edge-vertical');
  vertical?.addEventListener('mousedown', (downEvent: MouseEvent) => {
    clearSelections(window);
    clearSelections(iframe.contentWindow!);
    const originalUserSelect = widgetNode.style.userSelect;
    widgetNode.style.userSelect = 'none';
    const frameRect = iframe.getBoundingClientRect();

    const recalculatePosition = (event: MouseEvent) => {
      const diffWidth = downEvent.clientX + frameRect.x - event.clientX;

      let newWidth = frameRect.width + diffWidth;

      const isWidthGrowing = diffWidth > 0;
      if (options.minWidth !== undefined && newWidth < options.minWidth && !isWidthGrowing) return;
      const isCorrectChange = isCorrectRectForBoundingBox(
        {
          height: frameRect.height,
          width: newWidth,
          x: frameRect.x - diffWidth,
          y: frameRect.y,
        },
        options.boundingBox
      );
      if (!isCorrectChange) return;
      iframe.style.width = `${newWidth}px`;
      iframe.style.transform = `translate(${frameRect.x - diffWidth}px, ${frameRect.y}px)`;
    };
    document.addEventListener('mousemove', recalculatePosition);

    iframe.contentDocument!.addEventListener(
      'mouseup',
      () => {
        widgetNode.style.userSelect = originalUserSelect;
        document.removeEventListener('mousemove', recalculatePosition);
      },
      { once: true }
    );
  });
}

function applyInitialPosition(iframe: HTMLIFrameElement, rect: Rect) {
  iframe.style.transform = `translate(${rect.x}px, ${rect.y}px)`;
  iframe.style.height = `${rect.height}px`;
  iframe.style.width = `${rect.width}px`;
}

function modifyBoundingBoxInlineByTargetElement(element: HTMLElement, boundingBox: BoundingBox) {
  const rect = element.getBoundingClientRect();
  boundingBox.top = rect.top + 40;
}

export const GlobalLLmAssistantWidgetToggleButton = React.memo(() => {
  const documentationBotConfig = useAppSelector((store: RootState) => store.AppConfigReducer.documentationBotConfig);
  const widgetUrl = documentationBotConfig?.scriptUrl ?? '';
  const minRect = useRef({ minWidth: 320, minHeight: 400 });
  const boundingBox = useRef<BoundingBox>({ top: 52, right: 24, bottom: 24, left: 24 });
  const initialRect = useRef<Rect>({ x: 9999, y: 0, width: 320, height: 500 });
  const buttonRef = useRef<HTMLDivElement | null>(null);

  const [isShowIframeWithWidget, showIframeWithWidget, closeIframeWithWidget] = useToggle(false);
  const [isNeedToShowWidgetOpened, setWidgetNeedToShow] = useState(false);
  const [widgetRealOpened, setWidgetRealOpened] = useState(false);

  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState({ count: 0, lastUpdateTime: 0 });

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (!iframeRef.current) return;
      if (buttonRef.current) modifyBoundingBoxInlineByTargetElement(buttonRef.current, boundingBox.current);
      applyBoundingBoxConstraints(iframeRef.current, boundingBox.current, minRect.current);
    });
  }, []);

  const isLoading = isNeedToShowWidgetOpened && !widgetRealOpened;
  return (
    <>
      <div
        ref={buttonRef}
        className={cn(styles.GlobalLLmAssistantWidgetButton, {
          [styles.GlobalLLmAssistantWidgetButton__isLoading]: isLoading,
        })}
        data-test-id='GlobalLLmAssistantWidgetButton'
        onClick={() => {
          if (!isShowIframeWithWidget) {
            showIframeWithWidget();
            setWidgetNeedToShow(true);
            return;
          }
          setWidgetNeedToShow(!widgetRealOpened);
        }}
      >
        <img src={widgetRealOpened ? iconOutlined : icon} alt='Global LLm assistant widget' />
        {unreadMessagesCount.count > 0 && <span className={styles.GlobalLLmAssistantWidgetButton__unreadMessages} />}
      </div>
      {isShowIframeWithWidget && (
        <IWC.IsolatedWidget
          ref={iframeRef}
          isOpened={isNeedToShowWidgetOpened}
          widgetUrl={widgetUrl}
          onMount={iframe => {
            const widgetHeader = iframe.contentDocument?.querySelector(
              '.justwidget--headline'
            ) as HTMLDivElement | null;
            if (!widgetHeader) return;
            addFreeDragAbility(iframe, widgetHeader, boundingBox.current);
            addResizeAbility(iframe, { ...minRect.current, boundingBox: boundingBox.current });
          }}
          onOpen={iframe => {
            if (buttonRef.current) modifyBoundingBoxInlineByTargetElement(buttonRef.current, boundingBox.current);
            applyInitialPosition(iframe, initialRect.current);
            applyBoundingBoxConstraints(iframe, boundingBox.current, minRect.current);
            setUnreadMessagesCount({ count: 0, lastUpdateTime: 0 });
            setWidgetRealOpened(true);
          }}
          onClose={() => {
            setWidgetNeedToShow(false);
            setWidgetRealOpened(false);
          }}
          onMessage={(_, ev) => {
            if (widgetRealOpened) return;
            setUnreadMessagesCount(prev => {
              if (prev.lastUpdateTime >= ev.text.timestamp) return prev;
              return { count: ev.text.unreadMessagesCount, lastUpdateTime: Date.now() };
            });
          }}
          onError={() => {
            setWidgetRealOpened(false);
            closeIframeWithWidget();
          }}
        />
      )}
    </>
  );
});
GlobalLLmAssistantWidgetToggleButton.displayName = 'GlobalLLmAssistantWidgetToggleButton';
