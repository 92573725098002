import { ProjectData } from '../../modules/Caila/components/AppContext';
import { SET_CHANNELS_FOR_LOGS_MASKING } from '../../constants/logsMasking.actions';

type InitialStateType = {
  allChannelList: ProjectData[];
};

const InitialState: InitialStateType = {
  allChannelList: [],
};

export default function LogsMaskingReducer(
  state: InitialStateType = InitialState,
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case SET_CHANNELS_FOR_LOGS_MASKING:
      return {
        ...state,
        allChannelList: action.payload,
      };

    default:
      return state;
  }
}
