import React, {
  createContext,
  FunctionComponent,
  ReactChildren,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { usePrevious, useWindowControl } from '@just-ai/just-ui';

import { forceMountWidget } from '../utils';
import { without } from 'lodash';
import { useAppContext } from 'modules/Caila/components/AppContext';
import localize, { t } from '../../../localization';
import { ErrorModal } from '../components/ErrorModal';
import { TrainingStatusEnum } from '@just-ai/api/dist/generated/Caila';
import { getErrorMessageFromReason, getNLUErrorFromResponse } from 'modules/Caila/utils/errors';
import { useAppDispatch } from '../../../storeHooks';
import { getGraphLocators } from '../../../reducers/JGraph.reducer/JGraphAsyncActions';
import { stopDebug } from 'reducers/JGraph.reducer';
import { TextToSpeechProviderDetail } from '../../TestTtsWidget/api/client';
import { SpeechId } from '../../TestTtsWidget/types';

type RequestedWidget = {
  url?: string;
  forceDeploy?: boolean;
  skipTests?: boolean;
};

const TEST_WIDGET_THEME = {
  headlineBackgroundColor: 'var(--primary)',
  headlineTextColor: 'dark',
  chatBackgroundColor: '#FFFFFF',
  chatBackgroundOpacity: 100,
  userMessageBackgroundColor: 'var(--primary-100)',
  userMessageTextColor: 'dark',
  botMessageBackgroundColor: 'var(--primary-100)',
  botMessageTextColor: 'dark',
  formButtonsColor: 'var(--primary)',
  formTextColor: 'var(--gray-950)',
};

export type TestWidgetContextType = {
  isTestWidgetShown: boolean;
  isLoads: boolean;
  showTestWidget: (
    forceDeploy?: boolean,
    url?: string,
    skipTests?: boolean,
    testWidgetType?: TestWidgetContextType['testWidgetType']
  ) => void;
  hideTestWidget: () => void;
  loadingWidgetUrl?: string;
  shownWidgetUrl?: string;
  requestedWidget?: RequestedWidget;
  onWidgetLoad: (url?: string) => void;
  onWidgetError: (url?: string, error?: any) => void;

  isNluWidgetShown: boolean;
  showNluWidget: () => void;
  hideNluWidget: () => void;
  clearError: () => void;
  setErrorHandler: () => void;
  toggleNluWidget: () => void;
  isRetraining: boolean;
  setIsRetraining: (value: boolean) => void;

  isTestTtsWidgetShown: boolean;
  showTestTtsWidget: (lineText: string) => void;
  hideTestTtsWidget: () => void;
  textForTestTts: string;
  ttsProvider: TextToSpeechProviderDetail | SpeechId;
  ttsVoice: string;
  setTtsProvider: (provider: TextToSpeechProviderDetail | SpeechId) => void;
  setTtsVoice: (voice: string) => void;

  erroredWidgetUrls: string[];
  trainNlu: (withWidget?: boolean) => void;
  trainingNlu: boolean;
  getTrainingNluStatus: (withWidget?: boolean) => Promise<void>;

  testWidgetType: 'textWidget' | 'voiceWidget';
};

export const TestWidgetContext = createContext({} as TestWidgetContextType);

const TARIFF_FREE = ['free', 'jacp_free', 'developer', 'jacp_developer', 'skillmaster'];

export const TestWidgetContextProvider: FunctionComponent = ({
  children,
  language,
}: {
  language?: string;
  children?: ReactNode | ReactChildren;
}) => {
  const needToRedeploy = useRef(false);

  const testWidgetControl = useWindowControl();
  const nluWidgetControl = useWindowControl();
  const testTtsWidgetControl = useWindowControl();

  const { projectShortName, accountId, TrainingApi, tariffUniqueName } = useAppContext();
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState(true);
  const [isRetraining, setIsRetraining] = useState(false);
  const [trainingNlu, setTrainingNlu] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [shownWidgetUrl, setShownWidgetUrl] = useState<string>();
  const [testWidgetType, setTestWidgetType] = useState<TestWidgetContextType['testWidgetType']>('textWidget');
  const [loadingWidgetUrl, setLoadingWidgetUrl] = useState<string>();
  const requestedWidget = useRef<RequestedWidget | undefined>();
  const [erroredWidgetUrls, setErroredWidgetUrls] = useState<string[]>([]);
  const [textForTestTts, setTextForTestTts] = useState('');
  const [ttsProvider, setTtsProvider] = useState<TextToSpeechProviderDetail | SpeechId>(
    TextToSpeechProviderDetail.AIMYVOICE
  );
  const [ttsVoice, setTtsVoice] = useState('');

  const setRequestedWidget = useCallback((widget: RequestedWidget | undefined) => {
    requestedWidget.current = widget;
  }, []);

  const trainStatusTimeout = useRef<NodeJS.Timeout | number>();

  const prevRequestedWidget = usePrevious<RequestedWidget | undefined>(requestedWidget.current);

  const clearError = useCallback(() => setError(null), []);

  const setErrorHandler = useCallback(() => {
    //@ts-ignore
    if (window.JustWidget) {
      //@ts-ignore
      window.JustWidget.showErrorOverlay = setError;
    }
  }, []);

  const hideTestTtsWidget = useCallback(() => {
    testTtsWidgetControl.hide();
  }, [testTtsWidgetControl]);

  const hideTestWidget = useCallback(
    (redeploy?: {
      forceDeploy?: boolean;
      widgetUrl?: string;
      skipTests?: boolean;
      testWidgetType: TestWidgetContextType['testWidgetType'];
    }) => {
      if (!testWidgetControl.isShown) return;
      setLoading(true);
      setShownWidgetUrl(undefined);
      testWidgetControl.hide();
      clearError();
      requestAnimationFrame(() => {
        document.dispatchEvent(new Event('justwidget_unmounted'));
      });
      dispatch(stopDebug());
    },
    [clearError, dispatch, testWidgetControl]
  );

  const hideNluWidget = useCallback(() => {
    if (!nluWidgetControl.isShown) return;
    setShownWidgetUrl(undefined);
    nluWidgetControl.hide();
    clearError();
  }, [clearError, nluWidgetControl]);

  const showTestWidget = useCallback(
    (
      forceDeploy?: boolean,
      widgetUrl?: string,
      skipTests?: boolean,
      testWidgetType: TestWidgetContextType['testWidgetType'] = 'textWidget'
    ) => {
      if (testWidgetControl.isShown) {
        hideTestWidget();
        needToRedeploy.current = true;
        return;
      }
      hideNluWidget();
      hideTestTtsWidget();
      setTestWidgetType(testWidgetType);
      setLoading(true);
      if (widgetUrl) setErroredWidgetUrls(without(erroredWidgetUrls, widgetUrl));
      if (prevRequestedWidget && prevRequestedWidget.skipTests !== skipTests) {
        forceDeploy = true;
      }
      setRequestedWidget({ url: widgetUrl, forceDeploy: forceDeploy, skipTests });
      setLoadingWidgetUrl(widgetUrl);
      dispatch(getGraphLocators({}));
      needToRedeploy.current = false;
      testWidgetControl.show();
    },
    [
      dispatch,
      erroredWidgetUrls,
      hideNluWidget,
      hideTestTtsWidget,
      hideTestWidget,
      prevRequestedWidget,
      setRequestedWidget,
      testWidgetControl,
    ]
  );

  const addStyleForElement = useCallback(
    (counter: number) => {
      setTimeout(() => {
        if (counter === 1000) {
          return;
        }
        const restartButton = document.querySelector(`[data-test-id='Justwidget.SendStartButton']`);
        if (restartButton) {
          restartButton.setAttribute('title', localize.translate('TestWidget: restart title'));
        }

        if (!window.JustWidgetAttributes) return addStyleForElement(++counter);
        window.JustWidgetAttributes.options.palette = TEST_WIDGET_THEME;
        window.JustWidgetAttributes.options.voiceMessages = undefined;
        if (language) window.JustWidgetAttributes.options.forcedLanguage = language.toLowerCase();
        window.JustWidget?.setNewAttributes && window.JustWidget.setNewAttributes();
        if (window.JustWidget) {
          window.JustWidget.showErrorOverlay = (error: any) => setError(error);
        }

        setErrorHandler();
        setLoading(false);
      }, 0);
    },
    [language, setErrorHandler]
  );

  const onWidgetLoad = useCallback(
    (url?: string) => {
      setLoading(false);
      setShownWidgetUrl(url);
      setLoadingWidgetUrl(undefined);
      let counter = 0;
      addStyleForElement(counter);
    },
    [addStyleForElement]
  );

  const onWidgetError = useCallback(
    (url?: string) => {
      hideTestWidget();
      setLoadingWidgetUrl(undefined);
      if (url) setErroredWidgetUrls([...erroredWidgetUrls, url]);
    },
    [erroredWidgetUrls, hideTestWidget]
  );

  const showNluWidget = useCallback(() => {
    hideTestWidget();
    hideTestTtsWidget();
    nluWidgetControl.show();
  }, [hideTestTtsWidget, hideTestWidget, nluWidgetControl]);

  const toggleNluWidget = useCallback(() => {
    if (nluWidgetControl.isShown) hideNluWidget();
    else showNluWidget();
  }, [hideNluWidget, nluWidgetControl.isShown, showNluWidget]);

  const getTrainingNluStatus = useCallback(
    () =>
      TrainingApi.getNLUStatus(accountId, projectShortName)
        .then(payload => {
          switch (payload.data.trainingStatus) {
            case TrainingStatusEnum.NONE:
            case TrainingStatusEnum.TRAINING:
              trainStatusTimeout.current = setTimeout(() => {
                getTrainingNluStatus();
              }, 200);
              break;
            case TrainingStatusEnum.FAILED:
              const parsedData = getNLUErrorFromResponse(payload);
              Promise.reject(parsedData);
              setError(getErrorMessageFromReason(parsedData, t, true));
              return setTrainingNlu(false);
            default:
              return setTrainingNlu(false);
          }
        })
        .catch(reason => {
          console.error('Error training FAQ', reason);
          setTrainingNlu(false);
          if (trainStatusTimeout.current) {
            window.clearTimeout(trainStatusTimeout.current as number);
          }
        }),
    [TrainingApi, accountId, projectShortName]
  );

  const trainNlu = useCallback(
    (withWidget?: boolean) => {
      setTrainingNlu(true);
      TrainingApi.trainNLU(accountId, projectShortName)
        .then(() => {
          trainStatusTimeout.current = window.setTimeout(() => {
            getTrainingNluStatus();
          }, 200);
        })
        .catch(reason => {
          setTrainingNlu(false);
          setError(getErrorMessageFromReason(reason, t, true));
        })
        .finally(() => {
          withWidget && showNluWidget();
        });
    },
    [TrainingApi, accountId, getTrainingNluStatus, projectShortName, showNluWidget]
  );

  const showTestTtsWidget = useCallback(
    (lineText: string) => {
      hideTestWidget();
      hideNluWidget();

      setTextForTestTts(lineText);
      testTtsWidgetControl.show();
    },
    [hideNluWidget, hideTestWidget, testTtsWidgetControl]
  );

  useEffect(() => {
    if (!projectShortName || !accountId || accountId === -1) {
      if (testWidgetControl.isShown) hideTestWidget();
      if (nluWidgetControl.isShown) hideNluWidget();
      if (testTtsWidgetControl.isShown) hideTestTtsWidget();
      clearError();
    }
  }, [
    projectShortName,
    accountId,
    testWidgetControl.isShown,
    nluWidgetControl.isShown,
    hideNluWidget,
    hideTestWidget,
    clearError,
    testTtsWidgetControl.isShown,
    hideTestTtsWidget,
  ]);

  //@ts-ignore
  const elementWatcher = useMemo(() => new window.DOMElementWatcher(), []);

  useEffect(() => {
    elementWatcher.when('div.justwidget', -1, () => forceMountWidget());
    return () => elementWatcher.stop();
  }, [elementWatcher]);

  useEffect(() => {
    const regex = /^.*(?=(_archived))/;
    let tariffValue;

    if (regex.test(tariffUniqueName)) {
      const groups = tariffUniqueName.match(regex);
      tariffValue = groups && groups[0];
    } else {
      tariffValue = tariffUniqueName;
    }

    if (tariffValue && TARIFF_FREE.includes(tariffValue)) {
      setTtsProvider(TextToSpeechProviderDetail.AIMYVOICE);
    }
  }, [tariffUniqueName]);

  useEffect(() => {
    if (needToRedeploy.current && !testWidgetControl.isShown) {
      showTestWidget();
    }
  }, [showTestWidget, testWidgetControl.isShown]);

  return (
    <TestWidgetContext.Provider
      value={{
        isLoads: isLoading,
        isTestWidgetShown: testWidgetControl.isShown,
        showTestWidget,
        hideTestWidget,
        requestedWidget: requestedWidget.current,
        shownWidgetUrl,
        loadingWidgetUrl,
        onWidgetLoad,
        onWidgetError,

        isNluWidgetShown: nluWidgetControl.isShown,
        showNluWidget,
        hideNluWidget,
        toggleNluWidget,
        isRetraining,
        setIsRetraining,
        clearError,
        setErrorHandler,
        erroredWidgetUrls,
        trainNlu,
        trainingNlu,
        getTrainingNluStatus,

        isTestTtsWidgetShown: testTtsWidgetControl.isShown,
        showTestTtsWidget,
        hideTestTtsWidget,
        textForTestTts,
        ttsProvider,
        setTtsProvider,
        ttsVoice,
        setTtsVoice,

        testWidgetType,
      }}
    >
      {children}
      {error && <ErrorModal text={error} toggle={clearError} />}
    </TestWidgetContext.Provider>
  );
};

export const useTestWidgetContext = () => useContext(TestWidgetContext);

export const withTestWidgetContext = (Component: any) => (props: any) => (
  <TestWidgetContext.Consumer>
    {contexts => <Component {...props} widgetContext={contexts} />}
  </TestWidgetContext.Consumer>
);
