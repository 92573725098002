import { Ace } from 'ace-builds';

import { PubSub } from 'services/PubSub';
import { isMac } from 'utils/platform';

import { AceEditorExtended } from './AceEditorExtended';

type RangePosition = {
  start: Pos;
  end: Pos;
};

type LinkableNode = {
  value: string;
  position: RangePosition;
};

interface Pos {
  column: number;
  row: number;
}

type NodeSlice = {
  nodes: HTMLElement[];
  startCol: number;
  endCol: number;
};

const HTMLNodeType = {
  ELEMENT_NODE: 1,
  TEXT_NODE: 3,
};

type Subscriptions<LINK_NODE> = {
  onLinkClick: {
    linkNode: LINK_NODE;
  };
};

type LinkClickAcePayload = { position: Pos; token?: Ace.Token; preventDefault: Function; stopPropagation: Function };

export class LinkHighlight<NODE extends LinkableNode = LinkableNode> extends PubSub<Subscriptions<NODE>> {
  private editor?: AceEditorExtended;
  private currentOverride?: {
    linkNode: NODE;
    actual: HTMLElement[];
    override: HTMLElement[];
  };
  private linkableNodes: NODE[] = [];
  private linkClickUnsub?: Function;
  private linkHoverOutUnsub?: Function;
  private linkHoverUnsub?: Function;
  private rollbackOverrideFn?: Function;

  connect(editor: AceEditorExtended) {
    this.editor = editor;

    this.linkClickUnsub = this.editor.on('linkClick', (el: LinkClickAcePayload) => {
      if (!el) return;
      this.onLinkClick(el.position);
      this.restoreHoveredToken();
    });
    this.linkHoverOutUnsub = this.editor.on('linkHoverOut', this.restoreHoveredToken.bind(this));
    this.linkHoverUnsub = this.editor.on('linkHover', this.onTokenHovered.bind(this));
    document.addEventListener('keyup', this.onKeyUp);
  }

  disconnect() {
    this.restoreHoveredToken();
    this.linkClickUnsub?.();
    this.linkHoverOutUnsub?.();
    this.linkHoverUnsub?.();
    document.removeEventListener('keyup', this.onKeyUp);
    this.editor = undefined;
    this.unsubscribeAll();
  }

  onLinkClick(position: Pos) {
    if (!this.currentOverride) return;
    if (!this.isPosInRange(position, this.currentOverride.linkNode.position)) return;
    this.notify('onLinkClick', { linkNode: this.currentOverride.linkNode });
    this.rollbackOverride();
  }

  loadLinkableNodes(nodes: NODE[]) {
    this.linkableNodes = nodes;
  }

  private getLinkableNodeByPosition(pos: Pos) {
    return this.linkableNodes.find(el => {
      return this.isPosInRange(pos, el.position);
    });
  }

  private isPosInRange(pos: Pos, range: RangePosition) {
    return (
      range.start.column <= pos.column &&
      range.end.column >= pos.column &&
      range.start.row <= pos.row &&
      range.end.row >= pos.row
    );
  }

  private onTokenHovered(el: { position: Ace.Point; token?: Ace.Token }) {
    if (!el || !this.editor) return;
    const node = this.editor.getHTMLElementByPosition(el.position);
    if (!node) return;
    if (this.currentOverride?.override?.includes(node)) return;

    const linkNode = this.getLinkableNodeByPosition(el.position);
    if (!linkNode) return;

    const originalNodeSlice = this.editor.getHTMLElementsByRange(linkNode.position);
    const overriddenNodes = this.generateOverriddenNode(linkNode, originalNodeSlice);
    if (!overriddenNodes) return;

    this.restoreHoveredToken();
    this.currentOverride = {
      actual: originalNodeSlice.nodes,
      override: overriddenNodes,
      linkNode,
    };
    this.applyOverride();
    this.editor.changeCursorType('pointer');
    this.editor.setOption('enableMultiselect', false);
  }

  private generateOverriddenNode(linkNode: LinkableNode, originalNodeSlice: NodeSlice): HTMLElement[] | null {
    let prependNode: HTMLElement | undefined;
    let appendNode: HTMLElement | undefined;

    const nodes = originalNodeSlice.nodes.concat();

    const firstNode = nodes[0];
    if (!firstNode) return null;
    // first node
    if (originalNodeSlice.startCol < linkNode.position.start.column) {
      const diff = linkNode.position.start.column - originalNodeSlice.startCol;

      const outNode = firstNode.cloneNode(true) as HTMLElement;
      outNode.textContent = firstNode.textContent?.slice(0, diff) ?? null;
      prependNode = outNode;

      const inNode = firstNode.cloneNode(true) as HTMLElement;
      inNode.textContent = firstNode.textContent?.slice(diff) ?? null;
      nodes[0] = inNode;
    }

    // last node
    const lastNode = nodes[nodes.length - 1];
    if (originalNodeSlice.endCol > linkNode.position.end.column) {
      const diff = linkNode.position.end.column - originalNodeSlice.endCol;

      const inNode = lastNode.cloneNode(true) as HTMLElement;
      inNode.textContent = lastNode.textContent?.slice(0, diff) ?? null;
      nodes[nodes.length - 1] = inNode;

      const outNode = lastNode.cloneNode(true) as HTMLElement;
      outNode.textContent = lastNode.textContent?.slice(diff) ?? null;
      appendNode = outNode;
    }

    const overriddenNodes: HTMLElement[] = [];
    if (prependNode) overriddenNodes.push(prependNode);
    // middle nodes
    for (let i = 0; i < nodes.length; i++) {
      const decoratedNode = this.generateHighLightedNode(nodes[i]);
      if (!decoratedNode) continue;
      overriddenNodes.push(decoratedNode);
    }
    if (appendNode) overriddenNodes.push(appendNode);

    return overriddenNodes;
  }

  private generateHighLightedNode(node: Node): HTMLElement | null {
    let newNode: HTMLElement;
    switch (node.nodeType) {
      case HTMLNodeType.ELEMENT_NODE:
        newNode = node.cloneNode() as HTMLElement;
        break;
      case HTMLNodeType.TEXT_NODE:
        newNode = document.createElement('span');
        break;
      default:
        return null;
    }

    newNode.textContent = node.textContent;
    newNode.classList.add('decorated');
    newNode.style.color = 'var(--blue-500)';
    newNode.style.textDecoration = 'underline';
    return newNode;
  }

  private restoreHoveredToken() {
    if (!this.currentOverride) return;
    this.rollbackOverride();
    this.currentOverride = undefined;
    this.editor?.changeCursorType('default');
    this.editor?.setOption('enableMultiselect', true);
  }

  private onKeyUp = (event: KeyboardEvent) => {
    const targetButtonKey = isMac() ? 'Meta' : 'Control';
    if (event.key === targetButtonKey) {
      this.restoreHoveredToken();
    }
  };

  private applyOverride() {
    if (!this.currentOverride) return;
    this.rollbackOverrideFn = this.replaceNodes(this.currentOverride.actual, this.currentOverride.override);
  }

  private rollbackOverride() {
    if (!this.rollbackOverrideFn) return;
    this.rollbackOverrideFn();
    this.rollbackOverrideFn = undefined;
  }

  /**
   * Заменяет последовательные HTML-ноды на другие и предоставляет метод отката.
   *
   * @param {HTMLElement[]} source - Последовательные исходные ноды для замены.
   * @param {HTMLElement[]} target - Ноды для вставки на место исходных.
   * @returns {Function} undo - Функция для отката изменений.
   */
  private replaceNodes(source: HTMLElement[], target: HTMLElement[]): Function {
    if (!source.length) throw new Error('Source nodes array is empty.');

    const parent = source[0].parentNode;
    if (!parent) throw new Error('Source nodes must have a parent node.');

    const startIndex = Array.from(parent.childNodes).indexOf(source[0]);
    if (startIndex === -1) throw new Error('Source nodes not found in parent.');

    // Проверяем, что все исходные ноды идут подряд
    for (let i = 0; i < source.length; i++) {
      if (parent.childNodes[startIndex + i] !== source[i]) {
        throw new Error('Source nodes are not consecutive siblings.');
      }
    }

    // Сохраняем ссылку на следующую ноду после исходных для корректной вставки
    const nextSibling = source[source.length - 1]?.nextSibling || null;

    source.forEach(node => parent.removeChild(node));
    target.forEach(node => parent.insertBefore(node, nextSibling));

    return () => {
      const isNodesCanBeRolledBack = target.every(node => parent.contains(node));
      if (!isNodesCanBeRolledBack) return;
      target.forEach(node => parent.removeChild(node));
      source.forEach(node => parent.insertBefore(node, nextSibling));
    };
  }
}
