import { IntentData } from '@just-ai/api/dist/generated/Caila';
import { CustomIntentItem } from '../../../../../../Caila/pages/IntentsPage/IntentItemsEdit';

export type IntentWithItems = {
  intent: IntentData;
  items: CustomIntentItem[];
};

export class ComplexErrorsType {
  isPathInvalid: boolean = false;
  duplicateSlotNames: string[] = [];
  emptySlotNames: string[] = [];
  isIntentValid: boolean = true;
}
