import { ProjectSettingsData, SettingsApi } from '@just-ai/api/dist/generated/Caila';
import { axios } from '../../../pipes/functions';
import isAccess from 'isAccessFunction';

export default class SettingsService {
  static readonly BASE_PATH = '/cailapub';
  private accountId: number;
  private projectId: string;
  private settingsApi: SettingsApi;

  public projectSettingsData?: ProjectSettingsData;

  constructor(accountId: number, projectId: string) {
    this.accountId = accountId;
    this.projectId = projectId;
    this.settingsApi = new SettingsApi({}, SettingsService.BASE_PATH, axios);
    if (this.accountId && this.projectId) {
      this.getProjectSettings();
    }
  }

  getProjectSettings = () => {
    if (isAccess(['nlu', 'NLU_READ']))
      return this.settingsApi.getProjectSettings(this.accountId, this.projectId).then(payload => {
        this.projectSettingsData = payload.data;
        return payload;
      });
  };
  setProjectSettings = (projectSettingsData: ProjectSettingsData, mergeSettings?: boolean) => {
    return this.settingsApi
      .setProjectSettings(this.accountId, this.projectId, projectSettingsData, mergeSettings)
      .then(payload => {
        this.projectSettingsData = payload.data;
        return payload;
      });
  };
}
