import { JBlock, JGraphData, JTheme } from '@just-ai/api/dist/generated/Editorbe';
import { v4 as uuid } from 'uuid';
import { groupBy, uniq } from 'lodash';
import { FromStateTransition, JGraphStateData, JStateWithId, TConnector, TJState, JGraphTheme } from './types';
import {
  mapStringTagNameToEnum,
  TActivationParameters,
  TagNames,
  TButtonParameters,
  TJBlock,
  TTagParameters,
} from '../../modules/JGraph/utils/types';
import { t } from 'localization';
import { Draft } from '@reduxjs/toolkit';
import { tagParametersToObj } from './utils';
import { CustomTagsStore$ } from './customTags.store';
import { tWithCheck } from '../../localization';
import { ScreenBlockPath } from 'reducers/JGraph.reducer/ScreenBlockPath';
import { isStateType } from 'modules/JGraph/utils/isStateType';
import safeJsonParse from 'utils/safeJsonParse';

export function getMainFileNameForTheme(fileNames: string[], entryPointFileName: string) {
  return fileNames.some(filename => filename === entryPointFileName) ? entryPointFileName : fileNames[0];
}

function mergeThemesByValue(themes: Omit<JTheme, 'states'>[], entryPointFileName: string): JGraphTheme[] {
  const themesGroups = groupBy(themes, el => el.value);
  return Object.values(themesGroups).map(themes => {
    return {
      ...themes[0],
      filenames: uniq(themes.map(theme => theme.filename)),
      canRender: false,
      pathId: getValidKonvaName(themes[0].value),
      mainFileName: getMainFileNameForTheme(
        themes.map(theme => theme.filename),
        entryPointFileName
      ),
    };
  });
}

export function dtoToGraph(dto: JGraphData): JGraphStateData {
  const screens =
    dto.jgraph?.themes?.reduce((prevValue, newValue) => {
      const states: JStateWithId[] = [];
      for (let i = 0; i < newValue.states.length; i += 1) {
        states.push(getBlocksFromState({ ...newValue.states[i], filename: newValue.filename, theme: newValue.value }));
      }
      return prevValue.concat(states);
    }, [] as JStateWithId[]) || [];

  const { fromStateTransitions } = getConnectionsFromBlocks(screens);
  return {
    themes: mergeThemesByValue(dto.jgraph?.themes ?? [], dto.entrypoint.filename),
    blocks: screens,
    files: dto.jgraph.files,
    fromStateTransitions: fromStateTransitions,
    reverting: false,
  };
}

export function rebuildGraph(payload: { themes: JGraphTheme[]; states: JStateWithId[]; entryPointFileName: string }) {
  const groupedThemesByValue = groupBy(payload.themes, theme => theme.value);
  const screens = payload.states.filter(state => groupedThemesByValue[state.theme]);

  return {
    themes: Object.values(groupedThemesByValue).map(themes => {
      if (themes.length === 1) return themes[0];
      const filenames = themes.reduce((acc, theme) => uniq([...acc, ...theme.filenames]), [] as string[]);
      return {
        ...themes[0],
        filenames,
        mainFileName: getMainFileNameForTheme(filenames, payload.entryPointFileName),
      };
    }),
    blocks: screens,
    fromStateTransitions: getConnectionsFromBlocks(screens).fromStateTransitions,
  };
}

export function findStartStateInStates(screens: JStateWithId[]) {
  return screens.find(screen => isStateType(screen, 'start'));
}
export const findScreenByPathId = (pathId: string, screens: Draft<JStateWithId[]>): JStateWithId | undefined => {
  let foundScreen = undefined;
  for (let i = 0; i < screens.length; i += 1) {
    if (screens[i].pathId === pathId) {
      foundScreen = screens[i];
      break;
    }
    if (screens[i].states) {
      foundScreen = findScreenByPathId(pathId, screens[i].states!);
      if (foundScreen) break;
    }
  }
  return foundScreen;
};

export const findScreenByCondition = (
  screens: Draft<JStateWithId[]>,
  cb: (state: JStateWithId) => boolean
): JStateWithId | undefined => {
  let foundScreen = undefined;
  for (let screen of screens) {
    if (cb(screen)) {
      foundScreen = screen;
      break;
    }
    if (screen.states) {
      foundScreen = findScreenByCondition(screen.states, cb);
      if (foundScreen) break;
    }
  }
  return foundScreen;
};
export const findScreenByPath = (path: string, screens: Draft<JStateWithId[]>): JStateWithId | undefined => {
  let foundScreen = undefined;
  for (let i = 0; i < screens.length; i += 1) {
    if (screens[i].path === path) {
      foundScreen = screens[i];
      break;
    }
    if (screens[i].states) {
      foundScreen = findScreenByPath(path, screens[i].states!);
      if (foundScreen) break;
    }
  }
  return foundScreen;
};

function createTypedBlocks(blocks: JBlock[]): TJBlock[] {
  return blocks.reduce((typedBlocks, currentBlock) => {
    const tagName = mapStringTagNameToEnum[currentBlock.tagName || ''] || (currentBlock.tagName as TagNames);
    switch (tagName) {
      case TagNames.HttpRequest: {
        (currentBlock.tagParameters as TJBlock<TagNames.HttpRequest>['tagParameters']).forEach(tagParam => {
          if (['vars', 'headers'].includes(tagParam.name)) {
            tagParam.value =
              typeof tagParam.value === 'string' ? safeJsonParse(tagParam.value || '[]') : tagParam.value;
          }
        });
        break;
      }
    }
    typedBlocks.push({
      ...currentBlock,
      jblocks: createTypedBlocks(currentBlock.jblocks || []),
      tagParameters: currentBlock.tagParameters as TJBlock<typeof tagName>['tagParameters'],
      tagName: tagName,
    } as TJBlock<typeof tagName>);
    return typedBlocks;
  }, [] as TJBlock[]);
}

export function getBlocksFromState(currentJState: TJState): JStateWithId {
  return {
    ...currentJState,
    //@ts-ignore
    states: currentJState.states
      ? currentJState.states.map(
          innState =>
            getBlocksFromContent([
              {
                ...innState,
                filename: currentJState.filename,
                theme: currentJState.theme,
              } as TJState,
            ])[0]
        )
      : null,
    blocks: createTypedBlocks(currentJState.blocks || []),
    id: uuid(),
    canRender: false,
    pathId: getValidKonvaName(currentJState.path || ''),
  };
}

function getBlocksFromContent(states: TJState[]): JStateWithId[] {
  return states.map(currentJState => getBlocksFromState(currentJState));
}

const russianLetters: Record<string, string> = {
  Ё: 'YO',
  Й: 'I',
  Ц: 'TS',
  У: 'U',
  К: 'K',
  Е: 'E',
  Н: 'N',
  Г: 'G',
  Ш: 'SH',
  Щ: 'SCH',
  З: 'Z',
  Х: 'H',
  Ъ: '_',
  ё: 'yo',
  й: 'i',
  ц: 'ts',
  у: 'u',
  к: 'k',
  е: 'e',
  н: 'n',
  г: 'g',
  ш: 'sh',
  щ: 'sch',
  з: 'z',
  х: 'h',
  ъ: '_',
  Ф: 'F',
  Ы: 'I',
  В: 'V',
  А: 'a',
  П: 'P',
  Р: 'R',
  О: 'O',
  Л: 'L',
  Д: 'D',
  Ж: 'ZH',
  Э: 'E',
  ф: 'f',
  ы: 'i',
  в: 'v',
  а: 'a',
  п: 'p',
  р: 'r',
  о: 'o',
  л: 'l',
  д: 'd',
  ж: 'zh',
  э: 'e',
  Я: 'Ya',
  Ч: 'CH',
  С: 'S',
  М: 'M',
  И: 'I',
  Т: 'T',
  Ь: '_',
  Б: 'B',
  Ю: 'YU',
  я: 'ya',
  ч: 'ch',
  с: 's',
  м: 'm',
  и: 'i',
  т: 't',
  ь: "'",
  б: 'b',
  ю: 'yu',
};

function transliterate(word: string) {
  return word
    .split('')
    .map(char => russianLetters[char] || char)
    .join('');
}
export function getValidKonvaName(statePath: string = ''): string {
  return transliterate(statePath).replace(/\W/g, '_');
}

export function transformStateNameToPath(stateName: string): string {
  if (stateName[0] === '/') return stateName;
  return '/' + stateName;
}

export function getConnectorFrom(
  screenJBlockIterator: number,
  blockPathId: string,
  jBlockTagName: TagNames | string,
  index: number = 0
): string {
  switch (jBlockTagName) {
    case 'inlineButtons':
    case 'buttons':
    case 'go!':
    case 'go': {
      return `${screenJBlockIterator}__${blockPathId}__${getValidKonvaName(jBlockTagName)}__${index}`;
    }
    default:
      return `${screenJBlockIterator}__${blockPathId}__${getValidKonvaName(jBlockTagName)}__${index}`;
  }
}

export function getConnectorPropsFromName(connectorName: string): {
  screenJBlockIterator: number;
  blockPathId: string;
  tagName: string;
  indexInTagName: number;
  isFromState: boolean;
} {
  const isFromState = connectorName.includes('fromState');
  const regex = isFromState ? /(\d+)__(_?\w+)__fromState_(\w+)__(\d+)/ : /(\d+)__(_?\w+)__(\w+)__(\d+)/;
  const matches = connectorName.match(regex);
  if (matches && matches.length === 5) {
    return {
      screenJBlockIterator: parseInt(matches[1], 10),
      blockPathId: matches[2],
      tagName: matches[3],
      indexInTagName: parseInt(matches[4], 10),
      isFromState: isFromState,
    };
  }
  return {
    screenJBlockIterator: 0,
    blockPathId: '',
    tagName: '',
    indexInTagName: 0,
    isFromState: isFromState,
  };
}

export class FromStateConnectionsStore {
  public fromStateTransitions: JGraphStateData['fromStateTransitions'] = {};
  constructor(fromStateTransitions: JGraphStateData['fromStateTransitions']) {
    if (fromStateTransitions) {
      this.fromStateTransitions = fromStateTransitions;
    }
  }
  setConnectorByPath = (path: string, fromStateConnector: FromStateTransition) => {
    if (!this.fromStateTransitions[path]) {
      this.fromStateTransitions[path] = [];
    }
    this.fromStateTransitions[path].push(fromStateConnector);
  };
  get(path: string) {
    return this.fromStateTransitions[path];
  }
}

function getRecursiveConnectors(state: JStateWithId, globalIterationPrefix: string, blocks: TJBlock[]): TConnector[] {
  const [connectors] = getBlockConnections(state, new FromStateConnectionsStore({}), blocks, globalIterationPrefix);
  return connectors;
}

export function getGroupStatesConnections(state: JStateWithId): TConnector[] {
  let [connectors] = getBlockConnections(state, new FromStateConnectionsStore({}), state.blocks);
  if (state.states?.length) {
    connectors = state.states.reduce((acc, state) => {
      const newConnectors = getGroupStatesConnections(state);
      return acc.concat(newConnectors);
    }, connectors);
  }
  return connectors || [];
}

export function resetConnections(blocks: TJBlock[]): TJBlock[] {
  const dereferencedBlocks: TJBlock[] = [];
  for (let stateBlock of blocks) {
    switch (stateBlock.tagName) {
      case TagNames.random:
      case TagNames.if:
      case TagNames.elseif:
      case TagNames.else: {
        stateBlock.jblocks = resetConnections(stateBlock.jblocks);
        break;
      }
      case TagNames.intent:
      case TagNames.intent_:
      case TagNames.q:
      case TagNames.event: {
        stateBlock.tagParameters = ((stateBlock.tagParameters || []) as TActivationParameters).map(tagParam => ({
          ...tagParam,
          value: null,
        }));
        break;
      }
      case TagNames.inlineButtons:
      case TagNames.buttons: {
        stateBlock.tagParameters = ((stateBlock.tagParameters || []) as TButtonParameters[]).map((button, index) => ({
          ...button,
          transition: '',
        }));
        break;
      }
      case TagNames.go_:
      case TagNames.go: {
        stateBlock.tagValue = '';
        break;
      }
      default:
        const descriptorExist = CustomTagsStore$.getValue().find(element => element.tagName === stateBlock.tagName);
        if (!descriptorExist) break;
        const statesFieldsName = (descriptorExist.parameters || [])
          .filter(param => param.type === 'state')
          .map(el => el.name)
          .filter(Boolean) as string[];
        stateBlock.tagParameters = (stateBlock.tagParameters as TTagParameters[]).map(param => {
          if (!statesFieldsName.includes(param.name)) return param;
          return {
            ...param,
            value: '',
          };
        });
        break;
    }
    dereferencedBlocks.push(stateBlock);
  }
  return dereferencedBlocks;
}

export function getBlockConnections(
  state: JStateWithId,
  existFromStateConnections: FromStateConnectionsStore,
  blocks: TJBlock[],
  prefix: string = ''
): [TConnector[], JGraphStateData['fromStateTransitions']] {
  let connectors: TConnector[] = [];
  function Iterator() {
    let iterator = -1;
    const changeIterator = () => {
      return (iterator += 1);
    };
    const getIterator = () => {
      return iterator;
    };
    return { changeIterator, getIterator };
  }
  let iterator = Iterator();
  for (let stateBlock of blocks) {
    iterator.changeIterator();

    switch (stateBlock.tagName) {
      case TagNames.random:
      case TagNames.if:
      case TagNames.elseif:
      case TagNames.else: {
        let isItElseOrElseIf = [TagNames.else, TagNames.elseif].includes(stateBlock.tagName);
        let innerPrefix = isItElseOrElseIf
          ? ScreenBlockPath.getAscendingBlockPath(blocks, iterator.getIterator(), TagNames.if)
          : '';
        const newConnectors = getRecursiveConnectors(
          state,
          `${prefix + innerPrefix}${iterator.getIterator()}_${stateBlock.tagName}_`,
          stateBlock.jblocks
        );
        newConnectors.forEach(connector => {
          connectors.push(connector);
        });
        break;
      }
      case TagNames.q:
      case TagNames.intent:
      case TagNames.intentGroup:
      case TagNames.event: {
        ((stateBlock.tagParameters || []) as TActivationParameters).forEach(tagParam => {
          if (tagParam.name === 'fromState' && tagParam.value && stateBlock.tagValue) {
            const index = existFromStateConnections.fromStateTransitions[tagParam.value]?.length || 0;
            const connection: TConnector = {
              from: getConnectorFrom(
                iterator.getIterator(),
                getValidKonvaName(tagParam.value),
                `fromState_${stateBlock.tagName}`,
                index
              ),
              to: state.pathId,
              fromNode: getValidKonvaName(tagParam.value),
              deferred: false,
              uuid: uuid(),
              fallback: `${getValidKonvaName(tagParam.value)}_events`,

              value: stateBlock.tagValue,
              fromNodeOriginalPath: tagParam.value,
              toNodeOriginalPath: state.path,
              tagName: stateBlock.tagName,
              debugActive: stateBlock.debugActive,
            };
            existFromStateConnections.setConnectorByPath(tagParam.value, {
              tagName: stateBlock.tagName,
              to: state.pathId,
              text: stateBlock.tagValue,
              connectionRef: connection,
            });
            connectors.push(connection);
          }
          if (tagParam.name === 'toState' && tagParam.value && tagParam.value !== './' && stateBlock.tagValue) {
            connectors.push({
              from: getConnectorFrom(
                iterator.getIterator(),
                state.pathId,
                `${stateBlock.tagName}`,
                iterator.getIterator()
              ),
              to: getValidKonvaName(tagParam.value),
              fromNode: state.pathId,
              deferred: false,
              uuid: uuid(),
              fallback: `${state.pathId}_events`,
              value: t(`toState ${stateBlock.tagName}`),
              fromNodeOriginalPath: state.path,
              toNodeOriginalPath: tagParam.value,
              tagName: stateBlock.tagName,
              debugActive: stateBlock.debugActive,
            });
          }
        });
        break;
      }
      case TagNames.inlineButtons:
      case TagNames.buttons: {
        ((stateBlock.tagParameters || []) as TButtonParameters[]).forEach((button, index) => {
          connectors.push({
            from: getConnectorFrom(iterator.getIterator(), state.pathId, `${prefix}${stateBlock.tagName}`, index),
            to: getValidKonvaName(button.transition || ''),
            fromNode: state.pathId,
            deferred: false,
            uuid: uuid(),
            fallback: `${state.pathId}_reactions`,
            value: t(`ReactionItem ${stateBlock.tagName}`),
            fromNodeOriginalPath: state.path,
            toNodeOriginalPath: button.transition || '',
            tagName: stateBlock.tagName,
          });
        });

        break;
      }
      case TagNames.go_:
      case TagNames.go: {
        if (stateBlock.tagValue) {
          connectors.push({
            from: getConnectorFrom(iterator.getIterator(), state.pathId, `${prefix}${stateBlock.tagName}`),
            to: getValidKonvaName(stateBlock.tagValue),
            fromNode: state.pathId,
            deferred: stateBlock.tagName === TagNames.go,
            uuid: uuid(),
            fallback: `${state.pathId}_reactions`,

            value: t(`ReactionItem ${stateBlock.tagName}`) + ` (${stateBlock.tagName})`,
            fromNodeOriginalPath: state.path,
            toNodeOriginalPath: stateBlock.tagValue,
            tagName: stateBlock.tagName,
          });
        }

        break;
      }
      case TagNames.HttpRequest:
      case TagNames.Email: {
        const okState = (stateBlock as TJBlock<TagNames.Email>).tagParameters.find(
          tagParam => tagParam.name === 'okState'
        );
        const errorState = (stateBlock as TJBlock<TagNames.Email>).tagParameters.find(
          tagParam => tagParam.name === 'errorState'
        );
        if (okState && okState.value) {
          connectors.push({
            from: getConnectorFrom(iterator.getIterator(), state.pathId, `${prefix}${stateBlock.tagName}`, 0),
            to: getValidKonvaName(okState.value as string),
            fromNode: state.pathId,
            deferred: false,
            uuid: uuid(),
            fallback: `${state.pathId}_reactions`,

            value: t(`ReactionItem ${stateBlock.tagName}`) + ' (okState)',
            fromNodeOriginalPath: state.path,
            toNodeOriginalPath: okState.value as string,
            tagName: stateBlock.tagName,
          });
        }
        if (errorState && errorState.value) {
          connectors.push({
            from: getConnectorFrom(iterator.getIterator(), state.pathId, `${prefix}${stateBlock.tagName}`, 1),
            to: getValidKonvaName(errorState.value as string),
            fromNode: state.pathId,
            deferred: false,
            uuid: uuid(),
            fallback: `${state.pathId}_reactions`,

            value: t(`ReactionItem ${stateBlock.tagName}`) + ' (errorState)',
            fromNodeOriginalPath: state.path,
            toNodeOriginalPath: errorState.value as string,
            tagName: stateBlock.tagName,
          });
        }
        break;
      }
      default:
        const descriptorExist = CustomTagsStore$.getValue().find(element => element.tagName === stateBlock.tagName);
        if (descriptorExist) {
          const tagParamsObj = tagParametersToObj(stateBlock.tagParameters);
          const statesFields = descriptorExist.parameters?.filter(param => param.type === 'state');
          statesFields?.forEach((stateField, index) => {
            if (tagParamsObj[stateField.name!].value) {
              connectors.push({
                from: getConnectorFrom(iterator.getIterator(), state.pathId, `${prefix}${stateBlock.tagName}`, index),
                to: getValidKonvaName(tagParamsObj[stateField.name!].value as string),
                fromNode: state.pathId,
                deferred: false,
                uuid: uuid(),
                fallback: `${state.pathId}_reactions`,
                value:
                  (tWithCheck(`ReactionItem ${stateBlock.tagName}`) || stateBlock.tagName) + ` (${stateField.name})`,
                fromNodeOriginalPath: state.path,
                toNodeOriginalPath: tagParamsObj[stateField.name!].value as string,
                tagName: stateBlock.tagName,
              });
            }
          });
        }
        break;
    }
  }
  return [connectors, existFromStateConnections.fromStateTransitions];
}

export function getConnectionsFromBlocks(
  screens: JStateWithId[],
  classToStoreFromState = new FromStateConnectionsStore({})
): { connections: TConnector[]; fromStateTransitions: JGraphStateData['fromStateTransitions'] } {
  let connections: TConnector[] = [];
  for (let i = 0; i < screens.length; i += 1) {
    const state = screens[i];
    const stateBlocks = state.blocks || [];
    let [newConnections] = getBlockConnections(state, classToStoreFromState, stateBlocks);
    newConnections.forEach(conn => connections.push(conn));

    if (state.states) {
      let { connections: addConnections } = getConnectionsFromBlocks(state.states, classToStoreFromState);
      addConnections.forEach(conn => connections.push(conn));
    }
  }
  return {
    connections,
    fromStateTransitions: classToStoreFromState.fromStateTransitions,
  };
}

export function getAllStatePaths(screen: JStateWithId) {
  let returnStates: string[] = [];
  if (screen.states && screen.states.length > 0) {
    screen.states.forEach(state => {
      returnStates.push(state.path);
      returnStates = [...returnStates, ...getAllStatePaths(state)];
    });
  }
  return returnStates;
}
export function getAllStatePathIds(screen: JStateWithId) {
  let returnStates: string[] = [];
  if (screen.states && screen.states.length > 0) {
    screen.states.forEach(state => {
      returnStates.push(state.pathId);
      returnStates = [...returnStates, ...getAllStatePathIds(state)];
    });
  }
  return returnStates;
}
export function getAllStates(states: JStateWithId[]) {
  let returnStates: string[] = [];
  states.forEach(screen => {
    returnStates.push(screen.path);
    if (screen.states && screen.states.length > 0) {
      screen.states.forEach(state => {
        returnStates.push(state.path);
        returnStates = [...returnStates, ...getAllStatePaths(state)];
      });
    }
  });

  return returnStates;
}
export function getAllInnerStates(screen: JStateWithId) {
  let returnStates: JStateWithId[] = [];
  if (screen.states && screen.states.length > 0) {
    screen.states.forEach(state => {
      returnStates.push(state);
      const innerStates = getAllInnerStates(state);
      innerStates.forEach(innState => {
        returnStates.push(innState);
      });
    });
  }
  return returnStates;
}
export function filterStatesRecursively(screens: JStateWithId[], cb: (state: JStateWithId) => boolean) {
  let returnStates: JStateWithId[] = [];
  for (let state of screens) {
    if (!cb(state)) continue;
    returnStates.push(state);
    returnStates = returnStates.concat(filterStatesRecursively(state.states || [], cb));
  }
  return returnStates;
}

export const findParentByPathId = (
  screens: JStateWithId[],
  pathId: string,
  parent?: JStateWithId
): JStateWithId | undefined => {
  let found: JStateWithId | undefined;
  for (let i = 0; i < screens.length; i++) {
    const screen = screens[i];
    if (parent && screens.find(state => state.pathId === pathId)) {
      found = parent;
      break;
    }
    if (screen.states && screen.states.length > 0) {
      const foundParent = findParentByPathId(screen.states, pathId, screen);
      if (foundParent) {
        found = foundParent;
        break;
      }
    }
  }
  return found;
};
