import { AppLogger } from '@just-ai/logger';
import { ScTokenizer, TokenizerToken } from './ScTokenizer';
import { ScenarioTokensSearch } from './ScenarioTokensSearch';

type Entity = 'state';

export interface EntityASTNode {
  entity: Entity;
  type: 'declaration' | 'usage';
  containerPath: string;
  value: string;
  position: {
    start: Locator;
    end: Locator;
  };
}

type FileName = string;

export interface Locator {
  column: number;
  row: number;
  fileName: string;
}

type Document = {
  fileName: string;
  content: string;
};

export class ScParser {
  private readonly indentLevelCount = 4;
  private symbolsMap: EntityASTNode[] = [];
  private declarationsMap: Record<string, EntityASTNode | undefined> = {};
  private usageMap: Record<string, EntityASTNode[]> = {};

  private currentTheme: string = '';
  private currentStatePath: string[] = [];

  private tokenizer = new ScTokenizer();
  private tokens: Record<FileName, TokenizerToken[]> = {};

  private activeLoadScenarioRequestId = 0;

  constructor(private wholeScenarioTokenizer: ScenarioTokensSearch) {}

  parse = (document: Document): void => {
    this.symbolsMap = [];
    this.declarationsMap = {};
    this.usageMap = {};
    this.tokens = {};

    const tokens = this.tokenizer.tokenize(document);
    this.tokens[document.fileName] = tokens;

    this.parseDocument(tokens);
  };

  async loadAndParseTokensFromWholeScenario() {
    const reqId = new Date().getTime();
    this.activeLoadScenarioRequestId = reqId;

    const filesTokens = await this.wholeScenarioTokenizer.getScenarioFilesTokens();
    if (!filesTokens) return;

    if (reqId !== this.activeLoadScenarioRequestId) {
      throw new Error('cancel');
    }

    for (const file of filesTokens) {
      if (this.tokens[file.fileName]) continue;
      this.tokens[file.fileName] = file.tokens;
      this.parseDocument(file.tokens);
    }
  }

  private parseDocument(tokens: TokenizerToken[]) {
    for (const tag of tokens) {
      const tagValue = tag.tagValue || '';

      let tagName = tag.tagName.trim();
      if (tagName.endsWith(':')) {
        tagName = tagName.slice(0, -1);
      }
      switch (tagName) {
        case 'theme':
          this.currentTheme = tagValue;
          this.currentStatePath = [this.currentTheme];
          continue;
        case 'state':
          this.parseStateDeclarations(tag);
          break;
        case 'go':
        case 'go!':
          this.parseStateUsage(tag);
          break;
        default:
          continue;
      }
    }
  }

  private parseStateDeclarations = (tagToken: TokenizerToken) => {
    const indentLength = tagToken.locator.column - 1;
    const level = this.getIndentLevel(indentLength);
    if (level < this.currentStatePath.length) {
      this.currentStatePath = this.currentStatePath.slice(0, level);
    }
    this.currentStatePath.push(tagToken.tagValue);
    const absoluteStatePath = this.joinPath(this.currentStatePath);
    const startColumn = indentLength + tagToken.tagName.length;
    const endPos = startColumn + tagToken.tagValue.length;

    const nodeDeclaration: EntityASTNode = {
      entity: 'state',
      type: 'declaration',
      value: absoluteStatePath,
      containerPath: this.parentPath(absoluteStatePath),
      position: {
        start: { column: startColumn, row: tagToken.locator.row, fileName: tagToken.locator.fileName },
        end: { column: endPos, row: tagToken.locator.row, fileName: tagToken.locator.fileName },
      },
    };
    this.addDeclarationToStore(nodeDeclaration);
  };

  private parseStateUsage(tagToken: TokenizerToken) {
    const absoluteSourceStatePath = this.joinPath(this.currentStatePath);

    let absoluteTargetStatePath = '';
    const statePath = tagToken.tagValue;
    if (statePath.startsWith('/')) {
      absoluteTargetStatePath = statePath;
    } else if (statePath.startsWith('.')) {
      // handel relative path
      absoluteTargetStatePath = this.relativePathToAbsolute(statePath);
    } else {
      return;
    }

    const indentLength = tagToken.locator.column - 1;

    const startColumn = indentLength + tagToken.tagName.length;
    const endPos = startColumn + tagToken.tagValue.length;

    const usageItem: EntityASTNode = {
      entity: 'state',
      type: 'usage',
      value: absoluteTargetStatePath,
      containerPath: absoluteSourceStatePath,
      position: {
        start: { column: startColumn, row: tagToken.locator.row, fileName: tagToken.locator.fileName },
        end: { column: endPos, row: tagToken.locator.row, fileName: tagToken.locator.fileName },
      },
    };
    this.addUsageToStore(usageItem);
  }

  public getEntities(forFile?: string) {
    if (!forFile) return this.symbolsMap;
    return this.symbolsMap.filter(entity => entity.position.start.fileName === forFile);
  }

  public getDeclaration(type: Entity, value: string): EntityASTNode | undefined {
    return this.declarationsMap[type + ':' + value];
  }

  public getUsage(type: Entity, value: string) {
    return this.usageMap[type + ':' + value] ?? [];
  }

  private joinPath(list: string[]): string {
    return list.join('/').replace(/\/{2,}/g, '/');
  }

  private getIndentLevel(indentLength: number): number {
    if (indentLength === 0) return 0;
    return Math.ceil(indentLength / this.indentLevelCount);
  }

  private addDeclarationToStore(nodeDeclaration: EntityASTNode) {
    this.symbolsMap.push(nodeDeclaration);
    this.declarationsMap[nodeDeclaration.entity + ':' + nodeDeclaration.value] = nodeDeclaration;
  }

  private addUsageToStore(nodeUsage: EntityASTNode) {
    this.symbolsMap.push(nodeUsage);

    const key = nodeUsage.entity + ':' + nodeUsage.value;
    if (this.usageMap[key]) {
      this.usageMap[key].push(nodeUsage);
    } else {
      this.usageMap[key] = [nodeUsage];
    }
  }

  private relativePathToAbsolute(statePath: string): string {
    const currentStatePath = this.currentStatePath.concat();

    let path = statePath;
    if (path.startsWith('./')) {
      path = path.slice('./'.length);
      return this.joinPath([...currentStatePath, ...path.split('/')]);
    }

    while (path.startsWith('../')) {
      path = path.slice('../'.length);
      if (currentStatePath.length === 0) return statePath;
      currentStatePath.pop();
    }
    return this.joinPath([...currentStatePath, ...path.split('/')]);
  }

  private parentPath(path: string): string {
    if (!path) return '';
    return path.substring(0, path.lastIndexOf('/'));
  }
}
