import {
  CHECK_TOKEN_FULFILLED,
  CHECK_TOKEN_REJECTED,
  DROP_CURRENT_USER,
  DROP_EDITABLE_USER,
  langBackMap,
  LOGIN_FULFILLED,
  LOGIN_PENDING,
  LOGIN_REJECTED,
  LOGOUT_FULFILLED,
  LOGOUT_REJECTED,
  GET_USER_ACCOUNT_PENDING,
  GET_USER_ACCOUNT_REJECTED,
  GET_USER_ACCOUNT_FULFILLED,
  GET_USER_SUBSCRIPTION_FULFILLED,
  GET_USER_SUBSCRIPTION_PENDING,
  GET_USER_SUBSCRIPTION_REJECTED,
  CHANGE_USER_SUBSCRIPTION_STATUS_REJECTED,
  CHANGE_USER_SUBSCRIPTION_STATUS_PENDING,
  CHANGE_USER_SUBSCRIPTION_STATUS_FULFILLED,
  ADD_USER_SUBSCRIPTION_REJECTED,
  ADD_USER_SUBSCRIPTION_PENDING,
  ADD_USER_SUBSCRIPTION_FULFILLED,
  SET_CURRENT_USER,
  SET_MARKUP_VIEW,
  CLEAN_LOGIN_ERRORS,
  GET_USER_ACCOUNT_PAYMENT_DATA_FULFILLED,
  GET_MANUAL_CONTROL_INFO_FULFILLED,
  GET_ALLOWED_ACCOUNTS_FULFILLED,
} from '../../constants/currentUser.actions';
import { getUserLanguage, setDefaultTimezone } from '../../pipes/functions';

import { updateLanguage } from '../../pipes/functions';

import localize from '../../localization';
import momentTimezone from 'moment-timezone';

import { LOGOUT_FROM_ACCOUNT_FULFILLED } from '../../constants/accounts.actions';
import { LocalStorageService } from '../../services/Storage/LocalStorageService';
import { RCurrentUser, UiMode } from '../../types';
import { AllowedAccountListItem, PaymentStatusInfoDto } from '@just-ai/api/dist/generated/Accountsadmin';

updateLanguage(getUserLanguage());

interface FeatureType {
  id: string;
  name: string;
  enabled: boolean;
}

type InitialStateType = {
  currentUser: RCurrentUser | null;
  account: any;
  logInAccount: number | null;
  language: string;
  markupView: string;
  features: FeatureType[];
  fetching: boolean;
  allPermissions: any[];
  roles: { name: string; [key: string]: any }[];
  error: string | string[];
  uiMode: UiMode;
  success: string;
  internal: boolean;
  isMultipleAvailable: null | boolean;
  timezones: any[];
  timezone: null | string;
  tariffData: null | any;
  adminUser: Record<string, any>;
  email: string;
  changeEmail: {
    fetched: boolean;
    fetching: boolean;
    errors: any[];
  };
  paymentProcessing: PaymentStatusInfoDto | null;
  allowedAccountsData: AllowedAccountListItem[] | null;
};

const InitialState: InitialStateType = {
  currentUser: null,
  account: null,
  logInAccount: null,
  language: getUserLanguage(),
  markupView: localStorage.getItem('USER_MARKUP_VIEW') || 'page',
  features: [],
  fetching: false,
  allPermissions: [],
  roles: [],
  error: '',
  uiMode: UiMode.DevX_user,
  success: '',
  internal: false,
  isMultipleAvailable: null,
  timezones: [],
  timezone: null,
  tariffData: null,
  adminUser: {},
  email: '',
  changeEmail: {
    fetched: false,
    fetching: false,
    errors: [],
  },
  paymentProcessing: null,
  allowedAccountsData: null,
};
export default function CurrentUserReducer(state = InitialState, action: any): InitialStateType {
  switch (action.type) {
    case SET_CURRENT_USER: {
      //todo ZB-2297
      let currUser = action.currentUser;
      if (!currUser.language) {
        currUser.language = 'RU';
      }
      if (!localStorage.CURRENT_USER) {
        localStorage.CURRENT_USER = JSON.stringify(currUser);
      }
      let settedLanguage = localStorage.getItem('USER_LANGUAGE');

      if (settedLanguage !== 'ru' && settedLanguage !== 'eng') {
        settedLanguage = 'eng';
      }

      localStorage.setItem('USER_LANGUAGE', settedLanguage);

      updateLanguage(settedLanguage);
      return {
        ...state,
        currentUser: action.currentUser,
        account: !!action.currentUser && action.currentUser.account ? action.currentUser.account : null,
        logInAccount: action.currentUser?.logInAccount?.accountId || null,
        allPermissions:
          !!action.currentUser && action.currentUser.allPermissions ? action.currentUser.allPermissions : null,
        features: !!action.currentUser && !!action.currentUser.features ? action.currentUser.features : null,
        roles: !!action.currentUser && action.currentUser.roles ? action.currentUser.roles : null,
        error: '',
        internal: !!action.currentUser && !!action.currentUser.internal ? action.currentUser.internal : false,
      };
    }
    case DROP_CURRENT_USER: {
      return {
        ...state,
        currentUser: InitialState.currentUser,
        account: InitialState.account,
        language: InitialState.language,
        features: InitialState.features,
        fetching: InitialState.fetching,
        allPermissions: InitialState.allPermissions,
        roles: InitialState.roles,
        error: InitialState.error,
        success: InitialState.success,
        internal: InitialState.internal,

        isMultipleAvailable: InitialState.isMultipleAvailable,
      };
    }

    case DROP_EDITABLE_USER: {
      return {
        ...state,
        language: localStorage.getItem('USER_LANGUAGE') || InitialState.language,
        markupView: localStorage.getItem('USER_MARKUP_VIEW') || InitialState.markupView,
        error: '',
      };
    }

    case LOGIN_FULFILLED: {
      if (!localStorage.CURRENT_USER) {
        localStorage.CURRENT_USER = JSON.stringify(action.payload.data);
      }
      // @ts-ignore
      const language = langBackMap[action.payload.data.language] || 'eng';
      localStorage.setItem('USER_LANGUAGE', language);
      updateLanguage(language);

      let timezones = momentTimezone.tz
        .names()
        .filter(d => d.includes('Etc/GMT'))
        .map(i => {
          return {
            label: i.replace(/(Etc\/GMT\+)(\d+)|(Etc\/GMT-)(\d+)/g, (match, g1, g2, g3, g4) => {
              if (g1) {
                return `UTC-${g2.length === 1 ? '0' + g2 : g2}:00`;
              }
              if (g3) {
                return `UTC+${g4.length === 1 ? '0' + g4 : g4}:00`;
              }
              return match;
            }),
            value: i,
          };
        });
      timezones = timezones.filter(t => !t.label.includes('Etc/GMT'));
      let timezonePlus = timezones.filter(t => !t.label.includes('+'));
      let timezoneMinus = timezones.filter(t => !t.label.includes('-'));

      timezonePlus.sort((a, b) => {
        // @ts-ignore
        return parseInt(a.label.match(/\d+/g)[0]) - parseInt(b.label.match(/\d+/g)[0]);
      });
      timezoneMinus.sort((a, b) => {
        // @ts-ignore
        return parseInt(a.label.match(/\d+/g)[0]) - parseInt(b.label.match(/\d+/g)[0]);
      });

      timezones = [...timezoneMinus, ...timezonePlus];

      timezones.splice(0, 0, {
        label: 'Get browser default time',
        value: null as any,
      });

      const timezone = timezones.find(t => t.value === action.payload.data.timeZone);

      setDefaultTimezone(action.payload.data.timeZone);

      const allPermissions = action.payload.data.allPermissions;

      const isAdmin =
        allPermissions.includes('ACCOUNTS_ADMIN_WRITE') ||
        (allPermissions.includes('ACCOUNTS_TARIFF_WRITE') && !allPermissions.includes('ACCOUNTS_ADMIN_WRITE'));

      const result: any = {
        currentUser: action.payload.data,
        account: action.payload.data.account,
        allPermissions,
        uiMode: UiMode.DevX_user,
        features: action.payload.data.features,
        roles: action.payload.data.roles,
        error: '',
        internal: action.payload.data.internal,
        fetching: false,
        timezones: timezones,
        timezone: timezone,
        language: language,
      };

      if (isAdmin) {
        result.adminUser = { ...result };
      }

      return {
        ...state,
        ...result,
      };
    }
    case LOGIN_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }
    case LOGIN_REJECTED: {
      const response = action.payload.response;
      return {
        ...state,
        error:
          response.data.status === 500
            ? localize.translate('Service not available')
            : response.data.message
              ? response.data.message
              : '',
        fetching: false,
      };
    }

    case CLEAN_LOGIN_ERRORS: {
      return {
        ...state,
        error: '',
      };
    }

    case CHECK_TOKEN_FULFILLED: {
      let onSuccessState = {
        ...state,
        success: localize.translate('Your Email has been successfully confirmed.'),
      };

      if (state.currentUser)
        onSuccessState.currentUser = {
          ...state.currentUser,
          emailVerified: true,
        };

      return onSuccessState;
    }
    case CHECK_TOKEN_REJECTED: {
      return {
        ...state,
        error: localize.translate('UserSettings user.changeEmailToken.wrong'),
      };
    }
    case LOGOUT_FULFILLED: {
      new LocalStorageService().clearStorage();
      return {
        ...InitialState,
        language: state.language,
      };
    }
    case LOGOUT_REJECTED: {
      return {
        ...state,
      };
    }

    case SET_MARKUP_VIEW: {
      localStorage.setItem('USER_MARKUP_VIEW', action.markupView);
      return {
        ...state,
        markupView: action.markupView,
      };
    }

    case GET_USER_ACCOUNT_FULFILLED: {
      return {
        ...state,
        fetching: false,
        tariffData: action.payload.data.optionsData,
      };
    }

    case ADD_USER_SUBSCRIPTION_PENDING:
    case CHANGE_USER_SUBSCRIPTION_STATUS_PENDING:
    case GET_USER_SUBSCRIPTION_PENDING:
    case GET_USER_ACCOUNT_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case ADD_USER_SUBSCRIPTION_REJECTED:
    case CHANGE_USER_SUBSCRIPTION_STATUS_REJECTED:
    case GET_USER_SUBSCRIPTION_REJECTED:
    case GET_USER_ACCOUNT_REJECTED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case ADD_USER_SUBSCRIPTION_FULFILLED:
    case CHANGE_USER_SUBSCRIPTION_STATUS_FULFILLED:
    case GET_USER_SUBSCRIPTION_FULFILLED: {
      let currentUser = {
        ...state.currentUser,
        subscription: action.payload.data,
      } as RCurrentUser;
      if (!localStorage.CURRENT_USER) {
        localStorage.CURRENT_USER = JSON.stringify(currentUser);
      }
      localStorage.CURRENT_USER = JSON.stringify({
        ...JSON.parse(localStorage.CURRENT_USER),
        subscription: currentUser.subscription,
      });
      return {
        ...state,
        currentUser: currentUser,
        fetching: false,
      };
    }

    case LOGOUT_FROM_ACCOUNT_FULFILLED:
      return {
        ...state,
        ...state.adminUser,
      };

    case GET_USER_ACCOUNT_PAYMENT_DATA_FULFILLED: {
      if (
        state.currentUser &&
        (!Boolean(state.currentUser.paymentProcessing) ||
          (Boolean(state.currentUser.paymentProcessing) &&
            state.currentUser.paymentProcessing.status !== action.payload.data.status))
      ) {
        let currentUser = {
          ...state.currentUser,
          paymentProcessing: action.payload.data,
        } as RCurrentUser;

        return {
          ...state,
          currentUser: currentUser,
          fetching: false,
        };
      } else {
        return state;
      }
    }

    case GET_MANUAL_CONTROL_INFO_FULFILLED: {
      let currentUser = {
        ...state.currentUser,
        tariff: {
          ...state.currentUser?.tariff,
          manualControlData: action.payload.data,
        },
      } as RCurrentUser;

      localStorage.CURRENT_USER = JSON.stringify(currentUser);
      return {
        ...state,
        currentUser: currentUser,
        fetching: false,
      };
    }

    case GET_ALLOWED_ACCOUNTS_FULFILLED: {
      return {
        ...state,
        allowedAccountsData: action.payload.data,
      };
    }

    default: {
      return state;
    }
  }
}
