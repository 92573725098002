import React, { useEffect, useState, PropsWithChildren, useRef } from 'react';
import { Switch, Route } from 'react-router-dom';
import ReactDom from 'react-dom';
import { useId } from '@just-ai/just-ui';

import { TestWidgetInstance } from 'services/TestWidget';
import { DeepPartial, JustWidgetOptions } from 'services/TestWidget/types';
import { getUserLanguage } from 'pipes/pureFunctions';

import { ChatWidgetMessageEvent } from '../../../domain/types';

import styles from './styles.module.scss';

function bindIFrameMousemove(iframe: HTMLIFrameElement) {
  if (!iframe.contentWindow) return;
  iframe.contentWindow.addEventListener('mousemove', function (event) {
    const clRect = iframe.getBoundingClientRect();
    const evt = new CustomEvent('mousemove', { bubbles: true, cancelable: false });
    // @ts-ignore
    evt.clientX = event.clientX + clRect.left;
    // @ts-ignore
    evt.clientY = event.clientY + clRect.top;
    iframe.dispatchEvent(evt);
  });
}

interface IsolatedWidgetProps {
  widgetUrl: string;
  isOpened?: boolean;
  onClose?: (iframe: HTMLIFrameElement) => void;
  onOpen?: (iframe: HTMLIFrameElement) => void;
  onMount?: (iframe: HTMLIFrameElement) => void;
  onError?: (iframe: HTMLIFrameElement, error: Error) => void;
  onMessage?: (iframe: HTMLIFrameElement, messagePayload: ChatWidgetMessageEvent) => void;
  widgetOptions?: DeepPartial<JustWidgetOptions>;
}

function sendErrorFromIframe(error: Error | string) {
  if (typeof error === 'string') {
    error = new Error(error);
  }
  window.parent.postMessage({ scope: 'IsolatedWidget', eventName: 'error', payload: error }, '*');
}

export function buildIsolatedWidgetComponents(config: {
  iframePageUrl: string;
  iframeAdditionalContent?: () => React.ReactNode;
}) {
  const IFrameWidgetContent = () => {
    const [testWidgetInstance] = useState(() => new TestWidgetInstance());

    useEffect(() => {
      const widgetUrl = window.frameElement?.getAttribute('data-widget-url');
      if (!widgetUrl) {
        sendErrorFromIframe('IFrameWidgetContent: widgetUrl not found');
        return;
      }
      const widgetOptions = window.frameElement?.getAttribute('data-widget-options');

      testWidgetInstance
        .openJustWidgetByUrl(widgetUrl, {
          language: '',
          options: JSON.parse(widgetOptions || '{}'),
        })
        .catch(sendErrorFromIframe);
      return () => void testWidgetInstance.closeTestWidget();
    }, [testWidgetInstance]);

    useEffect(() => {
      const widgetEvents = ['mounted', 'opened', 'toggled', 'getMessage'] as const;
      const unsubs: Function[] = [];
      for (const event of widgetEvents) {
        unsubs.push(
          testWidgetInstance.subscribe(event, val => {
            window.parent.postMessage(
              {
                scope: 'IsolatedWidget',
                eventName: event,
                payload: val,
              },
              '*'
            );
          })
        );
      }

      return () => unsubs?.forEach(cb => cb());
    }, [testWidgetInstance]);

    useEffect(() => {
      const listener = (event: MessageEvent) => {
        if (event.data?.scope !== 'IsolatedWidget') return;
        if (!testWidgetInstance.isMounded) return;
        switch (event.data.eventName) {
          case 'toggle':
            window.JustWidget?.toggleWidget(event.data.payload);
            break;
          default:
            return;
        }
      };
      window.addEventListener('message', listener);
      return () => window.removeEventListener('message', listener);
    }, [testWidgetInstance.isMounded]);

    return (
      <>
        <style type='text/css'>
          {`
          #widget-root .justwidget {
            max-width: none;
            max-height: none !important;
            height: 100%;
            width: 100%;
            bottom: 0 !important;
            right: 0 !important;
          }
          #widget-root .justwidget .justwidget--inner {
            max-width: none;
            max-height: none !important;
            margin: 0;
          }
          #widget-root .justwidget .justwidget--inner .justwidget--headline-height-button-container {
            display: none;
          }
          body { background-color: transparent !important; }
       `}
        </style>
        {config.iframeAdditionalContent?.() ?? null}
      </>
    );
  };

  return {
    RouterSwitchWrapper: ({ children }: PropsWithChildren<any>) => {
      return (
        <Switch>
          <Route exact path={config.iframePageUrl} component={IFrameWidgetContent} />;
          <Route render={() => children} />
        </Switch>
      );
    },
    IsolatedWidget: React.forwardRef(
      (
        { isOpened, widgetUrl, onMount, onOpen, onClose, onMessage, onError, widgetOptions }: IsolatedWidgetProps,
        ref: React.ForwardedRef<HTMLIFrameElement>
      ) => {
        const id = useId();
        const iframeRef = useRef<HTMLIFrameElement | null>(null);
        const [widgetRealOpened, setWidgetRealOpened] = useState(false);

        useEffect(() => {
          iframeRef.current?.contentWindow?.postMessage?.({
            scope: 'IsolatedWidget',
            eventName: 'toggle',
            payload: isOpened,
          });
        }, [isOpened]);

        useEffect(() => {
          const listener = (event: MessageEvent) => {
            const iframe = iframeRef.current;
            if (!iframe) return;
            if (event.data?.scope !== 'IsolatedWidget') return;
            switch (event.data.eventName) {
              case 'mounted':
                if (event.data.payload) {
                  bindIFrameMousemove(iframe);
                  onMount?.(iframe);
                  break;
                }
                onClose?.(iframe);
                break;
              case 'toggled':
                setWidgetRealOpened(event.data.payload);
                if (event.data.payload) {
                  onOpen?.(iframe);
                  break;
                }
                onClose?.(iframe);
                break;
              case 'getMessage':
                onMessage?.(iframe, event.data.payload as ChatWidgetMessageEvent);
                break;
              case 'error':
                setWidgetRealOpened(false);
                onError?.(iframe, event.data.payload);
                break;
              default:
                return;
            }
          };
          window.addEventListener('message', listener);
          return () => window.removeEventListener('message', listener);
        }, [onClose, id, onOpen, onMount, onMessage, onError]);

        return ReactDom.createPortal(
          <iframe
            title='Global LLM assistant widget'
            className={styles.IsolatedWidget}
            id={id}
            ref={iframeNode => {
              iframeRef.current = iframeNode;
              if (ref) {
                if (typeof ref === 'function') ref(iframeNode);
                else ref.current = iframeNode;
              }
            }}
            src={config.iframePageUrl}
            data-widget-url={widgetUrl}
            data-widget-options={JSON.stringify(widgetOptions ?? {})}
            style={{ display: widgetRealOpened ? 'block' : 'none' }}
          />,
          document.body
        );
      }
    ),
  };
}
