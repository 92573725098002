import React, { useEffect } from 'react';
import Konva from 'konva';
import { useDebounceFn } from '@just-ai/just-ui';

import { JGLS } from 'reducers/JGraph.reducer';
import { JStateWithId, JGraphTheme } from 'reducers/JGraph.reducer/types';

import { useSyncedRef } from 'utils/hooks/useSyncedRef';

import { ThemesNodeGraph } from '../services/NodeGraph/ThemesNodeGraph';
import { StatesNodeGraph } from '../services/NodeGraph/StatesNodeGraph';

import { Vector2D } from '../utils/2DVector';
import { scrollToTargetGlobal$, isUserSeeNodesInViewport } from '../utils/stageUtils';
import { getOutlineRect } from '../utils/rects';
import { findClosestPoint } from '../utils/vectors';

import RenderingBlockWrapper from '../view/RenderingModal/RenderingBlockWrapper';

export function useJGraphRestorePosition(
  isReadyToRestorePosition: boolean,
  isThemesStage: boolean,
  parentPath: string,
  states: JStateWithId[],
  themes: JGraphTheme[],
  stage: React.MutableRefObject<Konva.Stage | null>
) {
  const getNodeGraph = useSyncedRef(() => {
    if (!stage.current) return null;
    const nodeGraph = isThemesStage
      ? new ThemesNodeGraph(themes || [], stage.current)
      : new StatesNodeGraph(states || [], stage.current);

    const scale = stage.current.scale() ?? Vector2D.fromScalar(1);
    return nodeGraph.getNodes().map(el => ({ ...el, width: el.width / scale.x, height: el.height / scale.y }));
  });

  const restore = useDebounceFn(async () => {
    if (!isReadyToRestorePosition || !stage.current) return;

    const stageSettings = await JGLS.store.getStagePathSettings(parentPath);
    if (stageSettings) {
      stage.current.position(stageSettings.stagePosition);
      stage.current.scale({ x: stageSettings.stageScale, y: stageSettings.stageScale });
    }

    const nodes = getNodeGraph.current();
    if (!nodes || nodes.length === 0) return;

    const isNodesInViewportExisted = isUserSeeNodesInViewport(stage.current, nodes);
    if (isNodesInViewportExisted) return;

    const outlineRect = getOutlineRect(nodes);
    if (!outlineRect) return;
    const leftTopOutlineCorner = { x: outlineRect.sx, y: outlineRect.sy };
    const targetNode = findClosestPoint(leftTopOutlineCorner, nodes);

    stage.current.scale(Vector2D.fromScalar(0.6));
    scrollToTargetGlobal$.next({
      targetPathId: targetNode.id,
      type: isThemesStage ? 'theme' : 'state',
      instantly: true,
    });
  }, 100);

  useEffect(() => {
    if (!isReadyToRestorePosition) return;
    restore();
    const sub = RenderingBlockWrapper.RenderingState$.subscribe(ev => {
      if (ev.status !== 'done') return;
      restore();
    });
    return () => sub.unsubscribe();
  }, [isReadyToRestorePosition, restore, parentPath]);
}
