import { PhraseIndexWithConfidence, StagingIntentData } from '@just-ai/api/dist/generated/Caila';
import { Intents, uniquify, Phrases } from '.';
import { PhraseItem, PhrasesDataset } from './phrases';
import { ConfidenceThreshold } from '../pages/LogLabelingPage/reducers';

export type GroupInfo = {
  name?: string;
  size?: number;
  phrasesIndexes?: Array<PhraseIndexWithConfidence>;
  phrases?: Array<string>;
  intentId?: number;
};

export type GroupTreeNode<PhrasesType = PhraseIndexWithConfidence> = {
  name: string;
  nodeId: string;
  phrases: PhrasesType[];
  childrenPhrasesCount?: number;
  intentId?: number;
  children?: string[];
  parent?: string;
  isGhostNode?: boolean;
};

export type GroupsDataset<PhrasesType = PhraseIndexWithConfidence> = {
  [nodeId: string]: GroupTreeNode<PhrasesType>;
};

export const UNGROUPED_GROUP_NAME = '_sys.ungrouped_group_';
export const EMPTY_GROUP_NAME = '_sys.unnamed_group_';
const GROUP_PREFIX = 'group_';

const ungroupedGroupTreeNode = (phrases: PhraseItem[]): GroupTreeNode =>
  toNode({
    name: UNGROUPED_GROUP_NAME,
    phrasesIndexes: phrases.map(phrase => ({ phraseIdx: Number(phrase.id), confidence: 1 })),
  });

export const addNode = <PhraseType = PhraseIndexWithConfidence>(
  dataset: GroupsDataset<PhraseType>,
  node: GroupTreeNode<PhraseType>
): GroupsDataset<PhraseType> => Object.assign(dataset, { [node.nodeId]: node });

export const toNode = (group: GroupInfo): GroupTreeNode => ({
  name: normalizeName(group.name),
  nodeId: getNodeId(group.name),
  phrases: group.phrasesIndexes ? group.phrasesIndexes : [],
});

const normalizeName = (name?: string) => {
  const trimed = name ? name.trim() : '';
  return trimed !== '' ? trimed : EMPTY_GROUP_NAME;
};

const getNodeId = (groupName?: string) => {
  const normalizedName = normalizeName(groupName);
  return normalizedName === UNGROUPED_GROUP_NAME || normalizedName === EMPTY_GROUP_NAME
    ? normalizedName
    : GROUP_PREFIX + groupName;
};

export const toDatasetWithoutUngrouped = (groupsData: GroupInfo[]): GroupsDataset =>
  groupsData.reduce((groups, group) => addNode(groups, toNode(group)), {} as GroupsDataset);

export const toDatasetWithUngrouped = (groupsData: GroupInfo[], allPhrases: PhraseItem[]): GroupsDataset => {
  const [groups, phrasesAtLeastInOneGroup] = groupsData.reduce(
    ([groups, phrasesAtLeastInOneGroup], group) => {
      const node = toNode(group);
      addNode(groups, node);
      node.phrases.forEach(({ phraseIdx }) => phrasesAtLeastInOneGroup.add(phraseIdx));
      return [groups, phrasesAtLeastInOneGroup] as [GroupsDataset, Set<number>];
    },
    [{}, new Set<number>()] as [GroupsDataset, Set<number>]
  );

  const ungroupedPhraseIndexes = allPhrases.filter((_value, index) => !phrasesAtLeastInOneGroup.has(index));

  if (ungroupedPhraseIndexes.length > 0) {
    addNode(groups, ungroupedGroupTreeNode(ungroupedPhraseIndexes));
  }
  return groups;
};

export const toTreeDatasetForStaging = (groupsData: GroupInfo[]): GroupsDataset<string> => {
  const dataset = groupsData.map(toStagingTreeNode).reduce(addTreeNode, {});
  fillChildrenPhrasesCount(dataset);
  return dataset;
};

export const toTreeDatasetWithUngrouped = (groupsData: GroupInfo[], allPhrases: PhraseItem[]): GroupsDataset => {
  const phrasesAtLeastInOneGroup = new Set(groupsData.flatMap(getPhrases).map(getIndex));

  const ungroupedPhraseIndexes = allPhrases.filter((_value, index) => !phrasesAtLeastInOneGroup.has(index));

  const groups = groupsData.map(toTreeNode).reduce(addTreeNode, {});
  fillChildrenPhrasesCount(groups);

  if (ungroupedPhraseIndexes.length > 0) addNode(groups, ungroupedGroupTreeNode(ungroupedPhraseIndexes));
  return groups;
};

export const toTreeNode = (group: GroupInfo): GroupTreeNode => ({
  name: Intents.getNameFromPath(group.name),
  nodeId: group.name ? group.name : EMPTY_GROUP_NAME,
  parent: Intents.getParentPathFromPath(group.name),
  intentId: group.intentId,
  children: [],
  phrases: group.phrasesIndexes ? group.phrasesIndexes : [],
  isGhostNode: false,
});

export const toStagingTreeNode = (group: GroupInfo): GroupTreeNode<string> => ({
  name: Intents.getNameFromPath(group.name),
  nodeId: group.name ? group.name : EMPTY_GROUP_NAME,
  parent: Intents.getParentPathFromPath(group.name),
  intentId: group.intentId,
  children: [],
  phrases: group.phrases || [],
  isGhostNode: false,
});

const addTreeNode = <PhraseType = PhraseIndexWithConfidence>(
  dataset: GroupsDataset<PhraseType>,
  node: GroupTreeNode<PhraseType>
): GroupsDataset<PhraseType> => {
  Intents.getAllParentPathsReverse(node.nodeId).forEach((element, i, array) =>
    addChildToNode<PhraseType>(dataset, element, array[i - 1] || node.nodeId)
  );

  const optionalNode = dataset[node.nodeId];

  if (optionalNode) {
    node.children = optionalNode.children;
  }

  return addNode(dataset, node);
};

const addChildToNode = <PhraseType>(
  dataset: GroupsDataset<PhraseType>,
  path: string,
  childPath: string
): GroupsDataset<PhraseType> => {
  const optionalNode = dataset[path];

  const node = optionalNode ? optionalNode : createGhostTreeNode<PhraseType>(path);

  node.children = node.children ? uniquify([...node.children, childPath]) : [childPath];

  return addNode<PhraseType>(dataset, node);
};

const createGhostTreeNode = <PhraseType>(path: string): GroupTreeNode<PhraseType> => ({
  name: Intents.getNameFromPath(path),
  nodeId: path,
  parent: Intents.getParentPathFromPath(path),
  children: [],
  phrases: [],
  isGhostNode: true,
});

const toGroupWithFilteredPhrasesFrom = (
  phrases: PhrasesDataset,
  confidenceThreshold: ConfidenceThreshold,
  hideConflicts: boolean
) =>
  hideConflicts
    ? (group: GroupTreeNode) => ({
        ...group,
        phrases: Phrases.filterGroupPhrasesAndHideConflicts(group.phrases, phrases, confidenceThreshold, group.nodeId),
      })
    : (group: GroupTreeNode) => ({
        ...group,
        phrases: Phrases.filterGroupPhrases(group.phrases, phrases, confidenceThreshold),
      });

export const filterAndCountPhrases = (
  allGroups: GroupsDataset<PhraseIndexWithConfidence>,
  phrases: PhrasesDataset,
  hideConflicts: boolean,
  confidenceThreshold: ConfidenceThreshold,
  isTree: boolean
) => {
  let dataset: GroupsDataset<PhraseIndexWithConfidence> = Object.values(allGroups)
    .map(toGroupWithFilteredPhrasesFrom(phrases, confidenceThreshold, hideConflicts))
    .reduce(addNode, {});

  dataset = Object.values(dataset).filter(hasPhrasesOrChildrenPhrasesFrom(dataset)).reduce(addNode, {});

  if (!isTree) {
    return dataset;
  }

  dataset = Object.values(dataset)
    .map(toGroupWithCleanedChildrenFrom(dataset))
    .map(toGroupWithChildrenPhrasesCountFrom(dataset))
    .reduce(addNode, {});

  return dataset;
};

export const toNotLabeledDataset = (groups: Readonly<GroupsDataset>, allPhrases: PhraseItem[], isTree: boolean) => {
  const filteredPhrasesDataset: GroupsDataset = Object.values(groups)
    .map(group => ({
      ...group,
      phrases: group.phrases.filter(({ phraseIdx }) => Phrases.isNotLabeled(allPhrases[phraseIdx])),
    }))
    .reduce(addNode, {});

  const onlyWithPhrasesDataset: GroupsDataset = Object.values(filteredPhrasesDataset)
    .filter(group => hasPhrases(group.nodeId, filteredPhrasesDataset))
    .reduce(addNode, {});

  if (!isTree) {
    return onlyWithPhrasesDataset;
  }

  const cleanChildrenDataset = Object.values(onlyWithPhrasesDataset)
    .map(group => cleanChildren(group, onlyWithPhrasesDataset))
    .map(group => addChildrenPhrasesCount(group, onlyWithPhrasesDataset))
    .reduce(addNode, {});

  return cleanChildrenDataset;
};

const hasPhrases = (nodeId: string, dataset: GroupsDataset): boolean => {
  const group = dataset[nodeId];
  return (
    group.phrases.length > 0 || Boolean(group.children && group.children.some(childId => hasPhrases(childId, dataset)))
  );
};
const hasPhrasesOrChildrenPhrasesFrom = (dataset: GroupsDataset) => (group: GroupTreeNode) =>
  hasPhrases(group.nodeId, dataset);

const cleanChildren = (group: GroupTreeNode, dataset: GroupsDataset) => ({
  ...group,
  children: group.children ? group.children.filter(childPath => dataset[childPath]) : group.children,
});
const toGroupWithCleanedChildrenFrom = (dataset: GroupsDataset) => (group: GroupTreeNode) =>
  cleanChildren(group, dataset);

export const fillChildrenPhrasesCount = <PhraseType>(dataset: GroupsDataset<PhraseType>) =>
  Object.values(dataset).forEach(group => addNode(dataset, addChildrenPhrasesCount<PhraseType>(group, dataset)));

const addChildrenPhrasesCount = <PhraseType>(group: GroupTreeNode<PhraseType>, dataset: GroupsDataset<PhraseType>) => ({
  ...group,
  childrenPhrasesCount: countChildrenPhrases<PhraseType>(group, dataset),
});
const toGroupWithChildrenPhrasesCountFrom = (dataset: GroupsDataset) => (group: GroupTreeNode) =>
  addChildrenPhrasesCount(group, dataset);

const countChildrenPhrases = <PhraseType>(group: GroupTreeNode<PhraseType>, dataset: GroupsDataset<PhraseType>) =>
  Object.values(dataset)
    .filter(node => isInChildren(node, group))
    .map(({ phrases }) => phrases.length)
    .reduce((sum, count) => (sum += count), 0);

const isInChildren = <PhraseType>(group: GroupTreeNode<PhraseType>, possibleParent: GroupTreeNode<PhraseType>) =>
  group.nodeId.startsWith(`${possibleParent.nodeId}/`);

export const byPhraseCountDescending = (a: GroupTreeNode, b: GroupTreeNode) => b.phrases.length - a.phrases.length;

export const getCommonPhraseIdxs = (groups: GroupTreeNode[]) =>
  groups
    .map(({ phrases }) => phrases.map(({ phraseIdx }) => Number(phraseIdx)))
    .reduce(
      (commonPhraseIdxs, phraseIdxs, index) =>
        index === 0 ? phraseIdxs : commonPhraseIdxs.filter(index => phraseIdxs.includes(index)),
      [] as number[]
    );

export const getAllPhraseIdxs = (dataset: GroupsDataset) =>
  uniquify(
    Object.values(dataset)
      .flatMap(({ phrases }) => phrases.map(({ phraseIdx }) => Number(phraseIdx)))
      .map(Phrases.prefixed)
  );

export const filterPhrases = (group: GroupTreeNode, phraseIdxs: number[]) => ({
  ...group,
  phrases: group.phrases.filter(({ phraseIdx }) => phraseIdxs.includes(Number(phraseIdx))),
});

export const toGroupStagingIntent = (intent: StagingIntentData): GroupInfo => ({
  intentId: intent.intentId,
  name: intent.path,
  size: intent.stagedPhrases ? intent.stagedPhrases.length : 0,
  phrases: intent.stagedPhrases || [],
});

const getPhrases = (group: GroupInfo) => (group.phrasesIndexes ? group.phrasesIndexes : []);
const getIndex = (phrase: PhraseIndexWithConfidence) => phrase.phraseIdx;
