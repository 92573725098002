import React from 'react';
import Modal from 'components/Modal';

import { t } from 'localization';

import styles from './styles.module.scss';

interface PatternInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  intentPath: string;
  pattern: string;
  userPhrase: string;
}
const PatternInfoModal = ({ isOpen, onClose, intentPath, pattern, userPhrase }: PatternInfoModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      centered
      title={t('PatternInfoModal:Title')}
      className={styles.PatternInfoModal}
      buttonCancelTestId='PatternInfoModal.CloseButton'
      buttonSubmitTestId='PatternInfoModal.SubmitButton'
      buttonCancelText={t('PatternInfoModal:closeButton')}
      onCancelClick={onClose}
      size='md'
    >
      <div className={styles.form}>
        <div>
          <div className={styles.label}>{t('PatternInfoModal:Phrase')}</div>
          <div className={styles.field} data-test-id='PatternInfoModal.Field.Phrase'>
            {userPhrase}
          </div>
        </div>
        <div>
          <div className={styles.label}>{t('PatternInfoModal:Pattern')}</div>
          <div className={styles.field} data-test-id='PatternInfoModal.Field.Pattern'>
            {pattern}
          </div>
        </div>
        <div>
          <div className={styles.label}>{t('PatternInfoModal:IntentPath')}</div>
          <div className={styles.field} data-test-id='PatternInfoModal.Field.IntentPath'>
            {intentPath}
          </div>
        </div>
      </div>
    </Modal>
  );
};

PatternInfoModal.displayName = 'PatternInfoModal';

export default React.memo(PatternInfoModal);
