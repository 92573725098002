export interface Cords {
  x: number;
  y: number;
}

export class Vector2D implements Cords {
  private constructor(
    public x: number,
    public y: number
  ) {}

  static from(x: number, y: number) {
    return new Vector2D(x, y);
  }
  static fromObj(cords: Cords) {
    return new Vector2D(cords.x, cords.y);
  }
  static fromScalar(val: number) {
    return new Vector2D(val, val);
  }
  static zeros() {
    return new Vector2D(0, 0);
  }

  add(vec: Cords): Vector2D {
    return Vector2D.from(this.x + vec.x, this.y + vec.y);
  }

  addX(x: number): Vector2D {
    return Vector2D.from(this.x + x, this.y);
  }
  addY(y: number): Vector2D {
    return Vector2D.from(this.x, this.y + y);
  }

  subtract(vec: Cords): Vector2D {
    return Vector2D.from(this.x - vec.x, this.y - vec.y);
  }
  subtractX(x: number): Vector2D {
    return Vector2D.from(this.x - x, this.y);
  }
  subtractY(y: number): Vector2D {
    return Vector2D.from(this.x, this.y - y);
  }

  divide(val: Cords | number): Vector2D {
    if (typeof val === 'number') return Vector2D.from(this.x / val, this.y / val);
    return Vector2D.from(this.x / val.x, this.y / val.y);
  }

  multiply(vec: Cords): Vector2D {
    return Vector2D.from(this.x * vec.x, this.y * vec.y);
  }

  magnitude(): number {
    return Math.sqrt(Math.pow(this.x, 2) + Math.pow(this.y, 2));
  }

  isEqual(vec: Cords) {
    return this.x === vec.x && this.y === vec.y;
  }

  inverse() {
    return Vector2D.from(-this.x, -this.y);
  }
}
