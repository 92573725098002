import { useCallback } from 'react';
import cn from 'classnames';
import { Button } from '@just-ai/just-ui';

import { useProjectGroupsContext } from 'modules/ProjectGroups/context';
import localize, { t } from '../../localization';
import { selectAccountButtonLocalization } from './localization/selectAccountButton.loc';

import styles from './styles.module.scss';
import { isInternal } from 'isAccessFunction';
import { isDev } from 'pipes/pureFunctions';

localize.addTranslations(selectAccountButtonLocalization);

export const SelectAccountButton = () => {
  const { invitations, currentProjectGroupObject } = useProjectGroupsContext();

  const goToSelectAccount = useCallback(() => {
    window.location.href = `${isDev() ? 'https://localhost:3001' : window.location.origin}/c/select-account`;
  }, []);

  if (!currentProjectGroupObject || isInternal()) return null;
  return (
    <div className={cn(styles.SelectAccount, { [styles.notification]: invitations?.length })}>
      <Button
        outline
        color='secondary'
        className={styles.SelectAccount__button}
        iconRight='farArrowRight'
        onClick={goToSelectAccount}
        data-test-id='goToAccountSelect'
      >
        <small className={styles.SelectAccount__text}>
          {t('SelectAccount', { currentAccountName: currentProjectGroupObject?.name })}
        </small>
      </Button>
    </div>
  );
};
