import {
  DeleteStagingIntentPhrasesRequest,
  GroupingRequestData,
  LogLabelingApi,
  MoveStagingIntentPhrasesRequest,
  PhrasesCleaningRequestData,
} from '@just-ai/api/dist/generated/Caila';
import {
  MAX_NETWORK_TIMEOUT,
  MAX_NETWORK_TIMEOUT_FOR_IMPORT,
  MAX_NETWORK_TIMEOUT_FOR_PHRASES_CLEANING,
} from 'modules/Caila/components/AppContext';
import { Groupings, Phrases } from 'modules/Caila/utils';
import { axios } from 'pipes/functions';

export class LogLabelingService {
  static readonly BASE_PATH = '/cailapub';
  private accountId: number;
  private projectShortName: string;
  private logLabelingApi: LogLabelingApi;

  constructor(accountId: number, projectShortName: string) {
    this.accountId = accountId;
    this.projectShortName = projectShortName;
    this.logLabelingApi = new LogLabelingApi(
      {
        baseOptions: { timeout: MAX_NETWORK_TIMEOUT },
      },
      LogLabelingService.BASE_PATH,
      axios
    );
  }

  async createSession(file: File) {
    const { data: session } = await this.logLabelingApi.createLogLabelingSession(
      this.accountId,
      this.projectShortName,
      file,
      {
        timeout: MAX_NETWORK_TIMEOUT_FOR_IMPORT,
      }
    );
    return session;
  }

  async getSession(sessionId: number) {
    const { data: session } = await this.logLabelingApi.getLogLabelingSession(
      this.accountId,
      this.projectShortName,
      sessionId
    );
    return session;
  }

  async getLastSession() {
    const { data: sessions } = await this.logLabelingApi.listLogLabelingSession(this.accountId, this.projectShortName);
    return sessions[sessions.length - 1];
  }

  async getSessionData(sessionId: number) {
    const [allPhrases, statuses, groupings, stagingIntents] = await Promise.all([
      this.getPhrases(sessionId),
      this.getPhrasesStatus(sessionId),
      this.getGroupings(sessionId),
      this.getStagingIntents(),
    ]);
    return { allPhrases, statuses, groupings, stagingIntents };
  }

  async getGroupingGroups(sessionId: number, groupingId: number) {
    const { data: grouping } = await this.logLabelingApi.getGroupingData(
      this.accountId,
      this.projectShortName,
      sessionId,
      groupingId
    );
    return grouping.groups || [];
  }

  async deleteGrouping(sessionId: number, groupingId: number) {
    await this.logLabelingApi.deleteGroupingData(this.accountId, this.projectShortName, sessionId, groupingId);
  }

  async getPhrases(sessionId: number) {
    const { data: allPhrases } = await this.logLabelingApi.getPhrases(this.accountId, this.projectShortName, sessionId);
    return allPhrases;
  }

  async getPhrasesStatus(sessionId: number) {
    const { data: statuses } = await this.logLabelingApi.getPhrasesStatus(
      this.accountId,
      this.projectShortName,
      sessionId
    );
    return statuses;
  }

  async getGroupings(sessionId: number) {
    const { data: groupings } = await this.logLabelingApi.listGrouping(
      this.accountId,
      this.projectShortName,
      sessionId
    );
    return groupings;
  }

  async deleteSession(sessionId: number) {
    await this.logLabelingApi.deleteLogLabelingSession(this.accountId, this.projectShortName, sessionId);
  }

  async executeGrouping(sessionId: number, parameters: GroupingRequestData) {
    const { data: grouping } = await this.logLabelingApi.executeGrouping(
      this.accountId,
      this.projectShortName,
      sessionId,
      parameters
    );
    return grouping;
  }

  async deletePhrases(sessionId: number, indexes: string[]) {
    await this.logLabelingApi.deletePhrases(
      this.accountId,
      this.projectShortName,
      sessionId,
      indexes.map(Phrases.clearPrefix)
    );
  }

  async clearPhrases(sessionId: number, indexes: string[]) {
    await this.logLabelingApi.discardChanges(
      this.accountId,
      this.projectShortName,
      sessionId,
      indexes.map(Phrases.clearPrefix)
    );
  }

  async stagePhrases(sessionId: number, intentId: number, indexes: string[]) {
    await this.logLabelingApi.moveToIntent(this.accountId, this.projectShortName, sessionId, {
      intentId,
      phrases: indexes.map(Phrases.clearPrefix),
    });
  }

  async stageGroupingPhrases(sessionId: number, groupingId: string, indexes: string[]) {
    await this.logLabelingApi.moveToIntentMultiple(this.accountId, this.projectShortName, sessionId, {
      groupingId: Groupings.clearPrefix(groupingId),
      phrases: indexes.map(Phrases.clearPrefix),
    });
  }

  async applyStaged(sessionId: number) {
    await this.logLabelingApi.applyStagedChanges(this.accountId, this.projectShortName, sessionId);
  }

  async cleanPhrases(sessionId: number, options: PhrasesCleaningRequestData) {
    const { data: cleanResult } = await this.logLabelingApi.cleanPhrases(
      this.accountId,
      this.projectShortName,
      sessionId,
      options,
      {
        timeout: MAX_NETWORK_TIMEOUT_FOR_PHRASES_CLEANING,
      }
    );
    return cleanResult;
  }

  async uploadDialogs() {
    const { data: dialogPhrases } = await this.logLabelingApi.createLogLabelingSessionFromHistory(
      this.accountId,
      this.projectShortName
    );
    return dialogPhrases;
  }

  async getStagingIntents() {
    const { data: stagingIntents } = await this.logLabelingApi.getStagingIntents(this.accountId, this.projectShortName);
    return stagingIntents;
  }

  async moveStagingPhrases(updateData: MoveStagingIntentPhrasesRequest) {
    const { data: stagingIntents } = await this.logLabelingApi.moveStagingIntentPhrases(
      this.accountId,
      this.projectShortName,
      updateData
    );
    return stagingIntents;
  }

  async deleteStagingPhrases(deleteData: DeleteStagingIntentPhrasesRequest) {
    const { data: stagingIntents } = await this.logLabelingApi.deleteStagingIntentPhrases(
      this.accountId,
      this.projectShortName,
      deleteData
    );
    return stagingIntents;
  }
}
