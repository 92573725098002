import {
  CHANGE_CURRENT_PROJECT,
  SET_CURRENT_PROJECT,
  CLEAR_CURRENT_PROJECT,
  SET_CURRENT_PROJECT_PROPERTIES,
} from '../../constants/projects.actions';
import { LOGOUT_FULFILLED } from '../../constants/currentUser.actions';

import { LOGIN_TO_ACCOUNT_FULFILLED } from '../../constants/accounts.actions';

const InitialState = {
  currentProject: '',
  currentProjectProperties: {},
};

export default function CurrentProjectsReducer(state = InitialState, action) {
  switch (action.type) {
    case SET_CURRENT_PROJECT:
    case CHANGE_CURRENT_PROJECT:
      return {
        ...state,
        currentProject: action.projectShortName,
      };

    case LOGOUT_FULFILLED:
    case LOGIN_TO_ACCOUNT_FULFILLED:
    case CLEAR_CURRENT_PROJECT:
      return {
        ...state,
        currentProject: '',
      };
    case SET_CURRENT_PROJECT_PROPERTIES:
      return {
        ...state,
        currentProjectProperties: action.payload,
      };

    default:
      return state;
  }
}
