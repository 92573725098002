import { Store, Unsubscribe } from 'redux';

import { RootState } from 'storeTypes';
import { invariant } from 'utils/invariant';

class ReduxStateReference {
  private store?: Store;
  private state?: RootState;
  private unsub?: Unsubscribe;

  connect(store: Store) {
    invariant(store, 'Store obj does not exist');
    this.disconnect();

    this.store = store;
    this.state = store.getState();
    this.unsub = store.subscribe(() => {
      this.state = store.getState();
    });
  }

  get dispatch() {
    invariant(this.store, 'Store does not connected');
    return this.store.dispatch;
  }

  getState() {
    invariant(this.store, 'State does not exist. Seems like store does not connected');
    return this.state!;
  }

  disconnect() {
    this.unsub?.();
    this.store = undefined;
    this.state = undefined;
  }
}

export const reduxStateReference = new ReduxStateReference();
