import loadScript from 'simple-load-script';
import Cookies from 'universal-cookie';
import { merge } from 'lodash';

import { isEuroInstance } from 'isAccessFunction';
import { timeoutPromise } from 'pipes/functions';
import { PubSub } from 'services/PubSub';

import { DeepPartial, JustWidgetOptions } from './types';

enum JustWidgetEvent {
  READY = 'justwidget_ready',
  TOGGLE = 'justwidget_toggle',
  GET_MESSAGE = 'justwidget_get_message',
}

type SubscriptionMap = {
  opened: boolean;
  mounted: boolean;
  toggled: boolean;
  getMessage: any;
};

const cookies = new Cookies();

type Config = { options: DeepPartial<JustWidgetOptions>; language: string };
const defaultConfig: Config = { options: {}, language: 'en' };

export class TestWidgetInstance extends PubSub<SubscriptionMap> {
  private conf: Config = defaultConfig;
  public isMounded = false;

  public async openJustWidgetByUrl(testWidgetUrl: string, conf?: Partial<Config>) {
    cookies.remove('waTestMode', { path: '/' });
    this.conf = { ...defaultConfig, ...(conf ?? {}) };
    await timeoutPromise(
      3000,
      new Error('Chatwidget load timeout error!'),
      loadScript({
        url: testWidgetUrl,
        inBody: true,
        attrs: {
          id: 'justwidget',
          charset: 'UTF-8',
          async: true,
        },
      })
    );
    this.notify('opened', true);

    document.addEventListener(JustWidgetEvent.READY, this.handleWidgetMounted);
    document.addEventListener(JustWidgetEvent.TOGGLE, this.handleWidgetToggle);
    document.addEventListener(JustWidgetEvent.GET_MESSAGE, this.handleWidgetGetMessage);
  }

  public closeTestWidget() {
    document.removeEventListener(JustWidgetEvent.READY, this.handleWidgetMounted);
    document.removeEventListener(JustWidgetEvent.TOGGLE, this.handleWidgetToggle);
    return this.removeWidgetCode();
  }

  private handleWidgetToggle = (event: Event & { detail?: boolean }) => {
    this.notify('toggled', event.detail);
  };

  private handleWidgetGetMessage = (event: Event & { detail?: boolean }) => {
    this.notify('getMessage', event.detail);
  };

  private handleWidgetMounted = () => {
    const widgetRoot = document.getElementById('widget-root');
    if (!widgetRoot) {
      console.error(`TestWidget opening error. Cannot found element by selector #widget-root.`);
      this.removeWidgetCode();
      return;
    }
    if (!window.JustWidget?.toggleWidget) {
      // noinspection JSIgnoredPromiseFromCall
      this.removeWidgetCode();
      return;
    }

    if (window.JustWidgetAttributes && window.JustWidget.setNewAttributes) {
      window.JustWidgetAttributes.options.forcedLanguage = this.conf.language;
      if (isEuroInstance()) {
        window.JustWidgetAttributes.options.poweredLabel = 'Tovie DialogStudio';
        window.JustWidgetAttributes.options.poweredLink = 'https://dialogstudio.tovie.ai/';
      }
      window.JustWidgetAttributes.options = merge(window.JustWidgetAttributes.options, this.conf.options);
      window.JustWidget.setNewAttributes();
    }

    window.JustWidget.toggleWidget();

    const enLangClass = 'justwidget--en';
    if (this.conf.language === 'en') {
      widgetRoot.classList.add(enLangClass);
    } else {
      widgetRoot.classList.remove(enLangClass);
    }

    this.isMounded = true;
    this.notify('mounted', true);
  };

  private removeWidgetCode = async () => {
    this.isMounded = false;
    this.notify('mounted', false);

    if (window.JustWidget) await window.JustWidget.unmount();
    window.webpackJsonpJustWidget = [];
    let instances = document.querySelectorAll('[data-origin="justwidget"]');
    if (instances.length > 0) {
      for (let i = 0; i < instances.length; i += 1) {
        instances[i].remove();
      }
    }
    document.getElementById('justwidget')?.remove();
    window.JustWidget = undefined;
    this.notify('opened', false);
  };
}
