import {
  GET_APP_CONFIG_FULFILLED,
  GET_APP_CONFIG_PENDING,
  GET_APP_CONFIG_REJECTED,
  GET_APP_STATUS_FULFILLED,
  GET_APP_STATUS_PENDING,
  GET_APP_STATUS_REJECTED,
  GET_OPTIONS_FULFILLED,
} from '../../constants/appConfig.actions';

type AppConfigReducerState = Record<string, any> & {
  documentationBotConfig?: { enabled: boolean; scriptUrl: string };
};

const InitialState: AppConfigReducerState = {
  systemFeatures: [],
  appStatus: {},
  appStatusFetching: false,
  appStatusFetched: false,
  getLogin: null,
  botadmin: {},
  zenflow: {},
  reCaptchaEnabled: false,
  reCaptchaSiteKey: null,
  getUserLanguage: null,
  fetching: false,
  fetched: false,
  errors: [],
  cloudDomains: {},
  loginPasswordAuthorizationEnabled: false,
  userNeedToSpecifyCountryIsoCode: false,
  hidePartnerBankDetails: false,
  paraphrase: {},
  documentationBotConfig: undefined,
};

export default function AppConfigReducer(
  state: AppConfigReducerState = InitialState,
  action: { type: string; payload: any }
): AppConfigReducerState {
  switch (action.type) {
    case GET_APP_CONFIG_PENDING: {
      return {
        ...state,
        fetching: true,
        fetched: false,
      };
    }
    case GET_APP_CONFIG_FULFILLED: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        getLogin: action.payload.data.getLogin,
        systemFeatures: action.payload.data.systemFeatures,
        botadmin: action.payload.data.botadmin,
        metaSettings: action.payload.data.metaSettings,
        zenflow: action.payload.data.zenflow,
        reCaptchaEnabled: action.payload.data.reCaptchaEnabled,
        reCaptchaSiteKey: action.payload.data.reCaptchaSiteKey,
        getUserLanguage: action.payload.data.getUserLanguage,
        oauth2Enabled: action.payload.data.oauth2Enabled,
        isReporterEnabled: action.payload.data.reporterEnabled || action.payload.data.botadmin.reporterEnabled,
        imputerExternalUrl: action.payload.data.imputerExternalUrl,
        chatWidgetSecurityEnabled: action.payload.data.chatWidgetSecurityEnabled,
        documentationBotConfig: action.payload.data.documentationBotConfig,
        paraphrase: action.payload.data.paraphrase,
      };
    }
    case GET_APP_CONFIG_REJECTED: {
      return {
        ...state,
        fetching: false,
        fetched: true,
      };
    }

    case GET_APP_STATUS_PENDING: {
      return {
        ...state,
        appStatusFetching: true,
        appStatusFetched: false,
      };
    }

    case GET_APP_STATUS_REJECTED: {
      return {
        ...state,
        appStatusFetching: false,
        appStatusFetched: true,
      };
    }

    case GET_APP_STATUS_FULFILLED: {
      return {
        ...state,
        appStatus: action.payload.data,
        appStatusFetching: false,
        appStatusFetched: true,
      };
    }

    case GET_OPTIONS_FULFILLED: {
      return {
        ...state,
        cloudDomains: action.payload.data.domains,
        ccSessionsAreEnabled: action.payload.data.authorization?.ccSessionsAreEnabled,
        loginPasswordAuthorizationEnabled: action.payload.data.authorization?.loginPasswordAuthorizationEnabled,
        universalLoginMenuEnabled: action.payload.data.universalLoginMenuEnabled,
        usersCanChangeEmail: action.payload.data.security?.usersCanChangeEmail,
        usersCanCreateAccount: action.payload.data.security?.usersCanCreateAccount,
        usersCanChangePassword: action.payload.data.security?.usersCanChangePassword,
        usersCanResetPassword: action.payload.data.security?.usersCanResetPassword,
        userNeedToSpecifyCountryIsoCode: action.payload.data.registration?.userNeedToSpecifyCountryIsoCode,
        hidePartnerBankDetails: action.payload.data.hidePartnerBankDetails,
        paymentSystems: action.payload.data.paymentSystems,
      };
    }

    default: {
      return state;
    }
  }
}
