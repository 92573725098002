import { useAppContext } from '../../modules/Caila/components/AppContext';
import { usePrevious } from '@just-ai/just-ui';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../storeHooks';

export const useCurrentProject = (projectShortName: string) => {
  const { ProjectsServiceFromApi } = useAppContext();

  const prevProjectShortName = usePrevious(projectShortName);

  const [currentProject, setCurrentProjectState] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const projectList = useAppSelector(store => store.ProjectsReducer.projectList);
  const currentProjectProperties = useAppSelector(store => store.CurrentProjectsReducer.currentProjectProperties);

  useEffect(() => {
    if (prevProjectShortName === projectShortName) return;

    const getProjectByShortName = async (shortName: string) => {
      let project = projectList.find(({ shortName: sn }: { shortName: string }) => sn === shortName);

      if (!project && currentProjectProperties.shortName === shortName) {
        project = currentProjectProperties;
      }

      if (!project) {
        try {
          const response = await ProjectsServiceFromApi.getByProjectShortName(shortName);
          project = response.data;
        } catch (error) {}
      }

      return project;
    };

    const fetchProject = async () => {
      setIsLoading(true);

      try {
        const project = await getProjectByShortName(projectShortName);
        setCurrentProjectState(project);
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    };

    fetchProject();
  }, [ProjectsServiceFromApi, currentProjectProperties, prevProjectShortName, projectList, projectShortName]);

  return { currentProject, isLoading };
};
