import { PhraseStatusData, MethodEnum } from '@just-ai/api/dist/generated/Caila';
import { LogLabelingReducer, LogLabelingState, LOG_LABELING_INITIAL_STATE, Reducers } from '.';
import { Phrases, Groups } from '../../../utils';
import SourceReducer from './source.reducer';
import StagingReducer from './staging.reducer';
import SimpleReducer from './simple.reducer';
import KeywordsReducer from './keywords.reducer';
import IntentsReducer from './intents.reducer';
import UnrecogReducer from './unrecog.reducer';
import * as PhraseModule from 'modules/Caila/utils/phrases';

const statusesForType = {
  DELETE_PHRASES: PhraseStatusData.D,
  STAGE_PHRASES: PhraseStatusData.S,
  CLEAR_PHRASES: PhraseStatusData.P,
};

enum LogLabelingMode {
  SOURCE,
  STAGING,
  SIMPLE,
  KEYWORDS,
  INTENTS,
  UNRECOG,
}

const MethodModeMap = new Map<MethodEnum | 'source' | 'staging', LogLabelingMode>([
  ['source', LogLabelingMode.SOURCE],
  ['staging', LogLabelingMode.STAGING],
  [MethodEnum.Duplicates, LogLabelingMode.SIMPLE],
  [MethodEnum.KmeansClustering, LogLabelingMode.SIMPLE],
  [MethodEnum.LinkageClustering, LogLabelingMode.SIMPLE],
  [MethodEnum.TfidfKeywords, LogLabelingMode.KEYWORDS],
  [MethodEnum.UdpipeKeywords, LogLabelingMode.KEYWORDS],
  [MethodEnum.Classification, LogLabelingMode.INTENTS],
  [MethodEnum.NotRecognizedMessages, LogLabelingMode.UNRECOG],
]);

const reducers: Reducers = {
  [LogLabelingMode.SOURCE]: SourceReducer,
  [LogLabelingMode.STAGING]: StagingReducer as any,
  [LogLabelingMode.SIMPLE]: SimpleReducer,
  [LogLabelingMode.KEYWORDS]: KeywordsReducer,
  [LogLabelingMode.INTENTS]: IntentsReducer,
  [LogLabelingMode.UNRECOG]: UnrecogReducer,
};

export const filterAllPhrases = <PhraseType>(
  state: Readonly<LogLabelingState<PhraseType>>
): LogLabelingState<PhraseType> => ({
  ...state,
  filteredAllPhrases: Phrases.filterAndOptionallyAddTopN(
    state.allPhrases,
    state.phrasesFilter,
    state.showLabeled,
    state.withTopN,
    state.allGroups
  ),
});

export const filterAllGroups = (state: Readonly<LogLabelingState>): LogLabelingState => ({
  ...state,
  filteredAllGroups: Groups.filterAndCountPhrases(
    state.allGroups,
    state.filteredAllPhrases,
    state.hideConflicts,
    state.phrasesConfidenceThreshold,
    state.isTree
  ),
});

export const filterAllPhrasesAndAllGroups = (state: Readonly<LogLabelingState>): LogLabelingState =>
  filterAllGroups(filterAllPhrases(state));

const GroupingReducer: LogLabelingReducer = (prevState, action) => {
  switch (action.type) {
    case 'SET_PHRASES_FILTER':
      return reducers[prevState.mode](
        filterAllPhrases({
          ...prevState,
          phrasesFilter: action.value,
        }),
        action
      );

    case 'SET_PHRASES_CONFIDENCE_THRESHOLD':
      return reducers[prevState.mode](
        filterAllPhrasesAndAllGroups({
          ...prevState,
          phrasesConfidenceThreshold: action.value,
        }),
        action
      );

    case 'TOGGLE_SHOW_LABELED':
      return reducers[prevState.mode](
        filterAllPhrasesAndAllGroups({
          ...prevState,
          showLabeled: !prevState.showLabeled,
        }),
        action
      );

    case 'TOGGLE_HIDE_CONFLICTS':
      return reducers[prevState.mode](
        filterAllPhrasesAndAllGroups({
          ...prevState,
          hideConflicts: !prevState.hideConflicts,
        }),
        action
      );

    case 'DELETE_PHRASES':
      return reducers[prevState.mode](
        filterAllPhrasesAndAllGroups({
          ...prevState,
          allPhrases: Phrases.switchStatuses(prevState.allPhrases, action.indexes, statusesForType[action.type]),
        }),
        action
      );
    case 'STAGE_PHRASES':
      const newPhrasesToStage =
        prevState.mode !== LogLabelingMode['STAGING']
          ? action.indexes.map(index => ({
              id: String(PhraseModule.clearPrefix(index)),
              text: prevState.phrases[index]?.text,
              status: PhraseStatusData.S,
            }))
          : [];
      return reducers[prevState.mode](
        filterAllPhrasesAndAllGroups({
          ...prevState,
          allPhrases: Phrases.switchStatuses(prevState.allPhrases, action.indexes, statusesForType[action.type]),
          stagedPhrases: [...prevState.stagedPhrases, ...newPhrasesToStage],
        }),
        action
      );
    case 'CLEAR_PHRASES':
      return reducers[prevState.mode](
        filterAllPhrasesAndAllGroups({
          ...prevState,
          allPhrases: Phrases.switchStatuses(prevState.allPhrases, action.indexes, statusesForType[action.type]),
        }),
        action
      );

    case 'APPLY_STAGED_PHRASES':
      return { ...prevState, allPhrases: Phrases.applyStaged(prevState.allPhrases), stagedPhrases: [] };

    case 'SET_METHOD':
      const newMode = MethodModeMap.get(action.method);
      if (newMode === undefined) return { ...prevState };
      return reducers[newMode](
        {
          ...LOG_LABELING_INITIAL_STATE,
          allPhrases:
            action.allPhrases && action.statuses
              ? Phrases.toPhraseItems(action.allPhrases, action.statuses)
              : prevState.allPhrases,
          mode: newMode,
          stagedPhrases: action.stagedPhrases || prevState.stagedPhrases || [],
        },
        {
          type: 'INIT',
          allGroups: action.allGroups ? action.allGroups : [],
        }
      );

    default:
      return reducers[prevState.mode](prevState, action);
  }
};

export default GroupingReducer;
