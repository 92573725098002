import React, { createContext, FunctionComponent, useContext, useMemo, useState, useCallback } from 'react';
import {
  ProjectsApi,
  IntentsApi,
  FAQApi,
  EntitiesApi,
  InferenceApi,
  TrainingApi,
  SpellerApi,
  SettingsApi,
  ProjectSettingsData,
} from '@just-ai/api/dist/generated/Caila';
import { AlertNotificationItemProps } from '@just-ai/just-ui/dist/AlertNotification/AlertNotificationItem';
import SkillsApiService from '@just-ai/api/dist/services/SkillsApiService';
import { addMessage, dismissMessage } from 'actions/globalAlert.actions';
import ProjectService from '../../Botadmin/service/ProjectService';
import {
  ProjectDtoWithReplacedFields,
  ProjectService as ProjectsServiceFromApi,
} from '@just-ai/api/dist/services/ProjectService';
import TextToSpeechService from '../../TestTtsWidget/service/TextToSpeechService';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GitIntegrationsService from 'views/Channels/services/GitIntegrationsService';
import { setCurrentProjectShortName, setProjectToEdit } from '../../../actions/projects.actions';
import { BotProjectReadWithConfigDto } from 'modules/Botadmin/api/client';
import IntentsService from '../service/IntentsService';
import AimychatOperatorGroupService from 'views/Channels/services/AimychatOperatorGroupService';
import AimychatOperatorPlaceService from 'views/Channels/services/AimychatOperatorPlaceService';
import FacebookService from 'views/Channels/services/FacebookService';
import SettingsService from 'modules/JGraph/services/SettingsService';
import { axios } from 'pipes/functions';
import JGraphService from '@just-ai/api/dist/services/JGraphApi';
import BotConfigService from 'views/Channels/services/BotConfigService';
import { CustomBlocksService } from '@just-ai/text-campaign/dist/service/CustomBlockService';
import localize from 'localization';
import { AnalyticsEntityService } from '@just-ai/api/dist/services/AnalyticsEntitiesService';
import ReportApiService from '@just-ai/api/dist/services/ReportApiService';

import BotScorerApiService from '@just-ai/api/dist/services/BotScorerApiService';
import ApiGatewayService from '@just-ai/api/dist/services/ApiGatewayService';

export const MAX_NETWORK_TIMEOUT = 30000; // 30sec
export const MAX_NETWORK_TIMEOUT_FOR_IMPORT = 1000 * 60 * 5; // 2min

export const MAX_NETWORK_TIMEOUT_FOR_PHRASES_CLEANING = 1000 * 60 * 15; // 15min

export type CurrentUserLanguage = 'eng' | 'ru' | 'cn';

export type BotConfig = {
  id: number;
  channelType: string;
  botName: string;
  botId: string;
  botserverBotId: string;
  link: string;
  description: string | null;
  lastDeployResult: string | null;
  outdated: boolean;
  senderName: string | null;
  appId: string | null;
  secret: string | null;
  autoCreated: boolean;
  externalBotId: string | null;
};

export type ProjectData = {
  spellingCorrection?: boolean;
  templateJson?: string;
  isCdqaCreated?: boolean;
  isCdqaEnabled?: boolean;
  initialTab?: string;
} & ProjectSettingsData &
  ProjectDtoWithReplacedFields;

export type AppContextType = {
  accountId: number;
  userId?: number;
  email?: string;
  projectShortName: string;
  originalProjects: BotProjectReadWithConfigDto[];
  setOriginalProjects: (projects: BotProjectReadWithConfigDto[]) => void;
  onlyHeader: boolean;
  currentProject?: ProjectData;
  language: CurrentUserLanguage;
  setProjectShortName: (projectShortName: string) => void;
  setCurrentProject: (project?: ProjectData) => void;
  setCurrentProjectToEdit: (tabToSet?: string) => void;
  setCurrentLang: (currentLanguage: CurrentUserLanguage) => void;
  showOnlyHeader: (showOnlyHeader: boolean) => void;
  BotProjectsService: ProjectService;
  ProjectsServiceFromApi: ProjectsServiceFromApi;
  GitIntegrationsService: GitIntegrationsService;
  aimychatOperatorGroupService: AimychatOperatorGroupService;
  botScorerApiService: BotScorerApiService;
  aimychatOperatorPlaceService: AimychatOperatorPlaceService;
  botConfigService: BotConfigService;
  skillsApiService: SkillsApiService;
  reportApiService: ReportApiService;
  facebookService: FacebookService;
  TextToSpeechService: TextToSpeechService;
  ProjectsApi: ProjectsApi;
  IntentsApi: IntentsApi;
  IntentsService: IntentsService;
  FAQApi: FAQApi;
  EntitiesApi: EntitiesApi;
  InferenceApi: InferenceApi;
  TrainingApi: TrainingApi;
  SpellerApi: SpellerApi;
  SettingsApi: SettingsApi;
  SettingsService: SettingsService;
  AnalyticsEntityService: AnalyticsEntityService;
  JGraphService: JGraphService;
  CustomBlocksService: CustomBlocksService;
  currentAudioSpeed: number;
  setCurrentAudioSpeed: (currentSpeed: number) => void;
  appConfig: any;
  setAppConfig: (appConfig: any) => void;
  tariffUniqueName: string;
  addAlert: (message: AlertNotificationItemProps) => void;
  dismissAlert: (time: number) => void;
  setCurrentProjectShortNameInRedux: AppContextProviderProps['actions']['setCurrentProjectShortNameInRedux'];
  setProjectToEdit: AppContextProviderProps['actions']['setProjectToEdit'];
  ApiGatewayService: ApiGatewayService;
};

export const AppContext = createContext({} as AppContextType);

const CONFIGURATION = {
  basePath: '/cailapub',
  baseOptions: { timeout: MAX_NETWORK_TIMEOUT },
};

interface AppContextProviderProps {
  accountId: number;
  userId?: number;
  email?: string;
  projectShortName: string;
  currentProjectProperties: ProjectData;
  actions: {
    setCurrentProjectShortNameInRedux: (projectShortName?: string) => void;
    addAlert: (message: AlertNotificationItemProps) => void;
    dismissAlert: (time: number) => void;
    setProjectToEdit: (project: ProjectData) => void;
  };
  tariffUniqueName: string;
}

const AppContextProviderComponent: FunctionComponent<AppContextProviderProps> = React.memo(
  ({ children, accountId, userId, projectShortName, email, actions, tariffUniqueName, currentProjectProperties }) => {
    const [onlyHeader, showOnlyHeader] = useState(false);
    const [currentProject, setCurrentProject] = useState<ProjectData>();
    const [originalProjects, setOriginalProjects] = useState<BotProjectReadWithConfigDto[]>([]);
    const [language, setCurrentLang] = useState<CurrentUserLanguage>('eng');
    const [currentAudioSpeed, setCurrentAudioSpeed] = useState(Number(localStorage.AUDIO_PLAYBACK_RATE) || 1);
    const [appConfig, setAppConfig] = useState<any>(null);

    const setProjectShortName = useCallback(
      (newProjectShortName?: string) => {
        if (newProjectShortName && projectShortName !== newProjectShortName) {
          actions.setCurrentProjectShortNameInRedux(newProjectShortName);
        }
      },
      [actions, projectShortName]
    );

    const setCurrentProjectAndProjectShortName = useCallback(
      (projectData?: ProjectData) => {
        setCurrentProject(projectData);
        setProjectShortName(projectData?.shortName);
      },
      [setProjectShortName]
    );

    const setCurrentProjectToEdit = (tabToSet?: string) => {
      if (currentProjectProperties) {
        actions.setProjectToEdit(currentProjectProperties);
        setCurrentProjectAndProjectShortName({ ...currentProjectProperties, initialTab: tabToSet });
      }
    };

    return (
      <AppContext.Provider
        value={{
          accountId: accountId || -1,
          userId,
          email,
          originalProjects,
          setOriginalProjects,
          projectShortName,
          currentProject,
          setCurrentProjectToEdit,
          onlyHeader,
          language,
          setProjectShortName,
          setCurrentProject: setCurrentProjectAndProjectShortName,
          setCurrentLang,
          showOnlyHeader,
          botScorerApiService: useMemo(
            () => new BotScorerApiService(accountId || -1, projectShortName, axios),
            [accountId, projectShortName]
          ),
          aimychatOperatorGroupService: useMemo(() => new AimychatOperatorGroupService(), []),
          aimychatOperatorPlaceService: useMemo(() => new AimychatOperatorPlaceService(accountId), [accountId]),
          botConfigService: useMemo(() => new BotConfigService(accountId), [accountId]),
          skillsApiService: useMemo(
            () => new SkillsApiService(accountId, projectShortName, axios),
            [accountId, projectShortName]
          ),
          reportApiService: useMemo(() => new ReportApiService(accountId, axios), [accountId]),
          facebookService: useMemo(() => new FacebookService(accountId), [accountId]),
          TextToSpeechService: useMemo(() => new TextToSpeechService(accountId || -1), [accountId]),
          BotProjectsService: useMemo(() => new ProjectService(accountId || -1), [accountId]),
          ProjectsServiceFromApi: useMemo(() => new ProjectsServiceFromApi(accountId || -1, axios), [accountId]),
          GitIntegrationsService: useMemo(() => new GitIntegrationsService(accountId || -1), [accountId]),
          ProjectsApi: useMemo(() => new ProjectsApi(CONFIGURATION), []),
          IntentsApi: useMemo(() => new IntentsApi(CONFIGURATION), []),
          IntentsService: useMemo(
            () => new IntentsService(accountId || NaN, projectShortName),
            [accountId, projectShortName]
          ),
          FAQApi: useMemo(() => new FAQApi(CONFIGURATION), []),
          EntitiesApi: useMemo(() => new EntitiesApi(CONFIGURATION), []),
          InferenceApi: useMemo(() => new InferenceApi(CONFIGURATION), []),
          TrainingApi: useMemo(() => new TrainingApi(CONFIGURATION), []),
          SpellerApi: useMemo(() => new SpellerApi(CONFIGURATION), []),
          SettingsApi: useMemo(() => new SettingsApi(CONFIGURATION), []),
          SettingsService: useMemo(
            () => new SettingsService(accountId || NaN, projectShortName),
            [accountId, projectShortName]
          ),
          CustomBlocksService: useMemo(() => new CustomBlocksService(accountId, localize, axios), [accountId]),
          AnalyticsEntityService: useMemo(
            () => new AnalyticsEntityService(accountId, projectShortName),
            [accountId, projectShortName]
          ),
          JGraphService: useMemo(
            () => new JGraphService(accountId, projectShortName, axios),
            [accountId, projectShortName]
          ),
          ApiGatewayService: useMemo(() => new ApiGatewayService(), []),
          currentAudioSpeed,
          setCurrentAudioSpeed,
          appConfig,
          setAppConfig,
          tariffUniqueName,
          addAlert: actions.addAlert,
          dismissAlert: actions.dismissAlert,
          setCurrentProjectShortNameInRedux: actions.setCurrentProjectShortNameInRedux,
          setProjectToEdit: actions.setProjectToEdit,
        }}
      >
        {children}
      </AppContext.Provider>
    );
  }
);

export const useAppContext = () => useContext(AppContext);

function mapStateToProps(state: any) {
  return {
    accountId: state.CurrentAccountReducer.account?.id,
    userId: state.CurrentUserReducer.currentUser?.id,
    projectShortName: state.CurrentProjectsReducer.currentProject,
    currentProjectProperties: state.CurrentProjectsReducer.currentProjectProperties,
    email: state.CurrentUserReducer.currentUser?.email,
    tariffUniqueName: state.AccountManagerReducer.tariffUniqueName,
  };
}

//@ts-ignore
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setCurrentProjectShortNameInRedux: setCurrentProjectShortName,
      addAlert: addMessage,
      dismissAlert: dismissMessage,
      setProjectToEdit: setProjectToEdit,
    },
    dispatch
  ),
});

export const AppContextProvider = connect(mapStateToProps, mapDispatchToProps)(AppContextProviderComponent);
