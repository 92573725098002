import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useEditorContext } from '../../context/EditorContext';
import AceEditor, { IAceEditorProps } from 'react-ace';
import 'ace-builds/src-noconflict/mode-sc';
import 'ace-builds/src-noconflict/mode-xml';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-kotlin';
import 'ace-builds/src-noconflict/mode-text';
import 'ace-builds/src-noconflict/theme-finn';
import 'ace-builds/src-noconflict/keybinding-emacs';
import 'ace-builds/src-noconflict/keybinding-vim';
import 'ace-builds/src-noconflict/keybinding-sublime';
import 'ace-builds/src-noconflict/ext-emmet';
import 'ace-builds/src-noconflict/ext-searchbox';
import 'ace-builds/src-noconflict/ext-prompt';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-linking';
import invertColor from 'utils/colors/invertColor';
import { i18nTranslation } from '../../../Caila/locale/i18nToLocalize';
import { EditorSettings, DEFAULT_SETTINGS } from '../../service/LocalStorageService';
import classNames from 'classnames';
import { ScCompleter } from './completer/ScCompleter';
import './style.scss';
import isAccess from 'isAccessFunction';
import { Ace } from 'ace-builds';
import { useAppDispatch } from '../../../../storeHooks';
import { getCustomTags } from '../../../../reducers/JGraph.reducer/JGraphAsyncActions';
import { CustomTagsStore$ } from '../../../../reducers/JGraph.reducer/customTags.store';
import { IAceEditor } from 'react-ace/lib/types';
import { ScParser } from './parser/ScParser';
import { EditorPopup } from './components/EditorPopupCmp';
import { decorateAceEditor } from '../../features/GoToDefinition';

export const DEFAULT_EDITOR_PROPS: IAceEditorProps = {
  theme: 'finn',
  height: '100%',
  width: '100%',
  tabSize: 4,
  enableBasicAutocompletion: true,
  enableSnippets: true,
  setOptions: {
    animatedScroll: true,
    fixedWidthGutter: true,
    scrollPastEnd: true,
    useSoftTabs: true,
    useWorker: false,
  },
};

export const toAceEditorSettings = (settings: EditorSettings): IAceEditorProps => ({
  showGutter: settings.showGutter !== undefined ? settings.showGutter : DEFAULT_SETTINGS.showGutter,
  fontSize: settings.fontSize !== undefined ? settings.fontSize : DEFAULT_SETTINGS.fontSize,
  keyboardHandler: settings.keyboardHandler !== undefined ? settings.keyboardHandler : DEFAULT_SETTINGS.keyboardHandler,
});

const EXTENSION_MODE_MAP: { [key: string]: string } = {
  sc: 'sc',
  xml: 'xml',
  json: 'json',
  bp: 'json',
  yaml: 'yaml',
  yml: 'yaml',
  js: 'javascript',
  kt: 'kotlin',
  txt: 'text',
};
const DEFAULT_MODE = 'text';

const getModeByExtension = (extension?: string) => {
  if (!extension) return DEFAULT_MODE;
  if (EXTENSION_MODE_MAP[extension]) return EXTENSION_MODE_MAP[extension];
  return DEFAULT_MODE;
};

const { t } = i18nTranslation('Editor');

const drawColorVariables = (editor: IAceEditor) => {
  const nodes = editor.container.querySelectorAll('.ace_constant.ace_language.ace_string');
  // @ts-ignore
  nodes.forEach((el: HTMLSpanElement) => {
    const text = el.textContent?.replace(/"/g, '');
    if (text && /^#([a-fA-F0-9]{3}|[a-fA-F0-9]{6})$/.test(text)) {
      el.style.backgroundColor = text;
      el.style.borderRadius = '2px';
      el.style.color = invertColor(text, true);
    }
  });
};

const CodeEditor: FunctionComponent = () => {
  const {
    currentFile,
    currentSettings,
    editorRef,
    save,
    debounceSaveFile,
    states,
    intentNames,
    intentGroups,
    clearCurrentFileRanges,
    scenarioTokensSearch,
    openFileWithoutSave,
  } = useEditorContext();

  const scCompleter = useRef<ScCompleter>();
  const editorCompleters = useRef<Ace.Completer[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (currentFile?.extension === 'sc') {
      dispatch(getCustomTags({}));
    }
  }, [currentFile?.extension, dispatch]);

  useEffect(() => {
    if (!editorRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      editorRef.current?.editor.resize();
      editorRef.current?.editor.renderer.updateFull();
    });
    resizeObserver.observe(editorRef.current?.refEditor);
    return () => resizeObserver.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorRef.current]);

  const parser = useMemo(() => new ScParser(scenarioTokensSearch), [scenarioTokensSearch]);

  useEffect(() => {
    const editor = editorRef.current?.editor;
    if (!editor) return;

    const afterRender = () => drawColorVariables(editor);
    editor.renderer.on('afterRender', afterRender);

    editorCompleters.current = editor.completers.filter(completer => !(completer instanceof ScCompleter));

    if (currentFile?.extension !== 'sc') {
      editor.completers = [];
      return;
    }
    const undecorate = decorateAceEditor(editor, currentFile.path, parser, openFileWithoutSave);

    scCompleter.current = new ScCompleter(intentNames, intentGroups, states, currentFile.path);

    editor.completers = [scCompleter.current];

    const sub = CustomTagsStore$.subscribe(() => {
      if (currentFile?.extension === 'sc') {
        scCompleter.current = new ScCompleter(intentNames, intentGroups, states, currentFile.path);
        editor.completers = [scCompleter.current];
      }
    });

    return () => {
      editor.renderer.off('afterRender', afterRender);
      undecorate();
      sub.unsubscribe();
    };
  }, [
    currentFile?.extension,
    currentFile?.path,
    editorRef,
    intentGroups,
    intentNames,
    openFileWithoutSave,
    parser,
    states,
  ]);

  const handleChange = useCallback(
    (_value: string, event?: any) => {
      if (currentFile?.readonly) return;
      debounceSaveFile().catch(() => {});
      clearCurrentFileRanges();
      const editor = editorRef.current?.editor;
      if (!editor) return;

      const currentLine = editor.session.getDocument().getLine(event.end.row);
      if (!currentLine) return;

      if (currentFile?.extension === 'sc') {
        window.requestAnimationFrame(() => editor.execCommand('startAutocomplete'));
      }
    },
    [clearCurrentFileRanges, currentFile?.extension, currentFile?.readonly, debounceSaveFile, editorRef]
  );

  return (
    <>
      <AceEditor
        {...DEFAULT_EDITOR_PROPS}
        setOptions={{
          ...DEFAULT_EDITOR_PROPS.setOptions,
          readOnly: !isAccess('CODE_WRITE') || currentFile?.readonly,
          enableLinking: true,
        }}
        {...toAceEditorSettings(currentSettings)}
        className={classNames({ 'pl-3': currentSettings.showGutter === false })}
        name='Editor.CodeEditor'
        placeholder={currentFile ? t('aceEmptyFile') : t('aceNoFileSelected')}
        readOnly={!currentFile}
        onBlur={save}
        onChange={handleChange}
        mode={getModeByExtension(currentFile?.extension)}
        ref={editorRef}
      />
      <EditorPopup.EditorPopupCmp />
    </>
  );
};

export default CodeEditor;
