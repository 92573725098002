import React, { FC, useEffect, useRef } from 'react';
import StageObservablesProvider, { useStageObservableContext } from '../../contexts/StageObservablesProvider';
import { useAppSelector } from '../../../../storeHooks';
import { findScreenByPathId, findStartStateInStates } from '../../../../reducers/JGraph.reducer/Graph';
import { StageView } from '../StageView';
import { goUpSubject$ } from '../CurrentGroupPath';
import RenderingBlockWrapper from '../RenderingModal/RenderingBlockWrapper';
import { t } from 'localization';
import { usePrevious } from '@just-ai/just-ui';

type StageInGroupView = {};

export const StageInGroupView: FC<StageInGroupView> = () => {
  const { screens, stickers, themes } = useAppSelector(state => ({
    screens: state.JGraphReducer.graph.blocks,
    themes: state.JGraphReducer.graph.themes,
    stickers: state.JGraphReducer.stickers,
  }));
  const { selectedGroupPathId, selectedGroupPath, setPreviousGroup } = useStageObservableContext();
  const loadingWasShowed = useRef(false);
  const prevSelectedGroupPathId = usePrevious(selectedGroupPathId);

  useEffect(() => {
    if (!selectedGroupPathId) {
      loadingWasShowed.current = false;
    }
    if (prevSelectedGroupPathId !== selectedGroupPathId) {
      loadingWasShowed.current = false;
    }
  }, [prevSelectedGroupPathId, selectedGroupPathId]);

  useEffect(() => {
    if (!selectedGroupPathId) return;
    const screenGroup = findScreenByPathId(selectedGroupPathId, screens);
    if (screenGroup) {
      if (!loadingWasShowed.current) {
        RenderingBlockWrapper.State$.next({ cancellable: false, title: t('RenderingModal:BuildingScenarioTitle') });
        RenderingBlockWrapper.Percentage$.next({
          type: 'start',
          total: (screenGroup.states?.filter(state => state.hasOwnProperty('canRender')) || []).length,
          startTime: Date.now(),
        });
      } else {
        RenderingBlockWrapper.RenderingState$.next({ status: 'done' });
      }
      loadingWasShowed.current = true;
    }

    return () => {
      RenderingBlockWrapper.Percentage$.next({ type: 'cleanup' });
    };
  }, [screens, selectedGroupPathId]);

  const containerRef = useRef<HTMLDivElement>(null);
  if (!selectedGroupPathId) return null;
  if (!selectedGroupPath) return null;

  const screenGroup = findScreenByPathId(selectedGroupPathId, screens);
  if (!screenGroup) {
    setPreviousGroup();
    return null;
  }
  const startState = findStartStateInStates(screenGroup?.states || []);

  if ((screenGroup.states || []).length === 0) {
    goUpSubject$.next(true);
  }

  return (
    <div className='Jgraph-container Group' ref={containerRef} key={selectedGroupPathId}>
      <StageObservablesProvider
        themes={themes}
        startState={startState}
        screens={screenGroup.states || []}
        allScreens={screens}
        stickers={stickers}
        upComingSelectedGroup={{ path: selectedGroupPath, pathId: selectedGroupPathId }}
      >
        <StageView
          containerRef={containerRef}
          screens={screenGroup.states || []}
          showIncomingOutgoingConnections={screenGroup.states && screenGroup.states.length > 0}
          StageID='GroupStageID'
        />
      </StageObservablesProvider>
    </div>
  );
};
