import { useAppDispatch } from '../../../storeHooks';
import { useCallback, useEffect, useRef } from 'react';
import Konva from 'konva';
import { JGraphMoveRequestData, MovementData } from '@just-ai/api/dist/generated/Editorbe';
import { getTargetAndHeightByPathId, scrollToTargetGlobal$ } from '../utils/stageUtils';

import { Vector2d } from 'konva/lib/types';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { getColNumber } from '../../Editor/context/contextUtils';
import { mainSave$ } from './savingPipe';
import { moveState, syncStickers } from 'reducers/JGraph.reducer/JGraphAsyncActions';

import { setBlockPositions, updateConnectedStickersAfterStateUpdate } from 'reducers/JGraph.reducer';

import { JStateWithId, JGraphTheme } from '../../../reducers/JGraph.reducer/types';
import useOnlineStatus from '../../../utils/hooks/useOnlineStatus';

import { ElkAutoLayout } from '../services/AutoLayout/ElkAutoLayout';
import { PlacedNode, BaseAutoLayout } from '../services/AutoLayout/BaseAutoLayout';
import { DagreAutoLayout } from '../services/AutoLayout/DagreAutoLayout';
import { AppLogger } from 'services/AppLogger';
import { findScreenByPathId } from 'reducers/JGraph.reducer/Graph';
import { StickerInfo } from '../view/Sticker/types';
import { INodeGraph, GraphOptions } from '../services/NodeGraph/INodeGraph';
import { StatesNodeGraph } from '../services/NodeGraph/StatesNodeGraph';
import { ThemesNodeGraph } from '../services/NodeGraph/ThemesNodeGraph';

export type AutoLayoutType = 'elk' | 'dagree';

type Constructor<CLASS> = { new (options: GraphOptions): CLASS };
const autoLayoutStrategies: Record<AutoLayoutType, Constructor<BaseAutoLayout>> = {
  dagree: DagreAutoLayout,
  elk: ElkAutoLayout,
};

export const AutoLayoutStartSubject$ = new Subject<{ type: AutoLayoutType }>();
export const AutoLayoutProgressSubject$ = new BehaviorSubject<{
  type: AutoLayoutType | undefined;
  status: undefined | 'pending' | 'error' | 'done';
  error?: Error;
}>({
  status: undefined,
  type: undefined,
});

export type BlocksPositions = Record<string, Vector2d>;

export const useAutoplacement = (
  isInActiveGroup: boolean,
  isThemesStage: boolean | undefined,
  stage: Konva.Stage | null,
  canAutoplace$: Observable<any>,
  screens: JStateWithId[],
  stickers: StickerInfo[],
  renderStore$: BehaviorSubject<string[]>,
  themes?: JGraphTheme[],
  scrollToStateAfterAutoLayout?: JStateWithId
) => {
  const dispatch = useAppDispatch();
  const isOnline = useOnlineStatus();

  const autoPositioningStarted = useRef(false);

  const autoposition = useCallback(
    async (type: AutoLayoutType) => {
      if (!stage) return;

      const nodeGraph: INodeGraph = isThemesStage
        ? new ThemesNodeGraph(themes || [], stage)
        : new StatesNodeGraph(screens, stage);

      const strategy = new autoLayoutStrategies[type](nodeGraph.getGraphOptions());

      const nodes = nodeGraph.getNodes();
      nodes.forEach((node, index) => {
        strategy.addNode(node);
      });

      if (strategy.getCountOfNode() === 0) return;

      const connections = nodeGraph.getEdges();
      if (connections.length > 0) {
        connections.forEach(conn => strategy.addEdge(conn[0], conn[1]));
      }

      AutoLayoutProgressSubject$.next({ type, status: 'pending' });
      let placedNodes: PlacedNode[] | undefined;
      try {
        placedNodes = await strategy.calculate();
      } catch (error) {
        if (error instanceof Error) {
          AppLogger.error({
            message: '',
            exception: error,
          });
          AutoLayoutProgressSubject$.next({ type, status: 'error', error });
        }
        console.error(error);
      }

      if (!placedNodes) return;

      await nodeGraph.applyNewPositions(dispatch, placedNodes, type);

      AutoLayoutProgressSubject$.next({ type, status: 'done' });
    },
    [dispatch, isThemesStage, screens, stage, themes]
  );

  const placeScreensLikeAimylogic = useCallback(async () => {
    const blocks = screens;
    if (!stage) return;

    if (!blocks || blocks.length === 0) return;

    const colOffsets: number[] = [];
    let positionsToStore = {} as BlocksPositions;

    const positions = blocks.reduce(
      (positions, screen) => {
        if (!positions[screen.filename]) {
          positions[screen.filename] = [] as MovementData[];
        }
        const col = getColNumber(String(screen.aimyPosition));
        if (!colOffsets[col]) colOffsets[col] = 50;

        const { targetHeight } = getTargetAndHeightByPathId(screen.pathId, stage);

        positions[screen.filename].push({
          target: screen.path,
          x: 50 + col * 450,
          y: colOffsets[col],
        });
        positionsToStore[screen.pathId] = {
          x: 50 + col * 450,
          y: colOffsets[col],
        };

        colOffsets[col] += targetHeight + 50;

        return positions;
      },
      {} as { [key: string]: MovementData[] }
    );

    await dispatch(setBlockPositions(positionsToStore));

    // stickers move
    for (let [pathId, position] of Object.entries(positionsToStore)) {
      let screen = findScreenByPathId(pathId, screens);
      if (!screen) continue;
      const stateAfterUpdate = {
        path: screen.path,
        x: position.x,
        y: position.y,
      };
      await dispatch(updateConnectedStickersAfterStateUpdate({ stateBeforeUpdate: screen, stateAfterUpdate }));
    }
    await dispatch(syncStickers(stickers));

    Object.keys(positions).forEach(filename => {
      const moveJStateData: JGraphMoveRequestData = {
        movementsDataWithFiles: [
          {
            filenames: [filename],
            movementsData: positions[filename],
          },
        ],
      };
      mainSave$.next({
        type: 'move',
        path: 'batch save move',
        action: () => dispatch(moveState(moveJStateData)),
      });
    });
  }, [dispatch, screens, stage, stickers]);

  useEffect(() => {
    const sub = canAutoplace$.subscribe({
      next: value => {
        if (value !== '__END__') return;
        if (!stage || !isOnline) return;

        if (
          isThemesStage &&
          themes?.every(theme => theme.x === 0 && theme.y === 0) &&
          !autoPositioningStarted.current
        ) {
          autoPositioningStarted.current = true;
          // noinspection JSIgnoredPromiseFromCall
          autoposition('elk');
          return;
        }
        if (
          screens.length > 0 &&
          screens.every(screen => screen.x === 0 && screen.y === 0) &&
          !autoPositioningStarted.current
        ) {
          autoPositioningStarted.current = true;
          if (screens.every(screen => screen.value.startsWith('newNode') && !!screen.aimyPosition)) {
            // noinspection JSIgnoredPromiseFromCall
            placeScreensLikeAimylogic();
          } else {
            console.log('Autoposition');
            // noinspection JSIgnoredPromiseFromCall
            autoposition('dagree');
          }
        }
      },
    });

    const autoLayoutSub = AutoLayoutStartSubject$.subscribe(autoLayout => {
      const status = AutoLayoutProgressSubject$.getValue().status;

      if (!isInActiveGroup || status === 'pending') return;
      autoposition(autoLayout.type).then(() => {
        if (scrollToStateAfterAutoLayout) {
          scrollToTargetGlobal$.next({
            targetPathId: scrollToStateAfterAutoLayout.pathId,
          });
        }
      });
    });

    return () => {
      sub?.unsubscribe();
      autoLayoutSub?.unsubscribe();
    };
  }, [
    autoposition,
    canAutoplace$,
    placeScreensLikeAimylogic,
    renderStore$,
    screens,
    stage,
    isOnline,
    isInActiveGroup,
    scrollToStateAfterAutoLayout,
    isThemesStage,
    themes,
  ]);
};
