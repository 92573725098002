import { TagsListEntry } from '@just-ai/api/dist/generated/Editorbe';
import { AppLogger } from '@just-ai/logger';

import ProjectService from 'modules/Editor/service/ProjectService';

interface Locator {
  column: number;
  row: number;
  fileName: string;
}

export interface TokenizerToken {
  tagName: string;
  locator: Locator;
  tagValue: string;
}

type FileTokens = { fileName: string; tokens: TokenizerToken[] };

function normalizeFilePath(path: string): string {
  if (!path.startsWith('/')) {
    path = '/' + path;
  }
  return path;
}

export class ScenarioTokensSearch {
  constructor(private projectService: ProjectService) {}

  async getScenarioFilesTokens(): Promise<FileTokens[]> {
    const res = await Promise.all([
      this.projectService.getTagsList({ tagName: 'theme' }),
      this.projectService.getTagsList({ tagName: 'state' }),
      this.projectService.getTagsList({ includeGlobal: true, tagName: 'go' }),
    ]).catch(AppLogger.createNetworkHandler('getTagsList'));
    if (!res) return [];

    const entitiesByFiles = res.flat().reduce(
      (acc, el) => {
        acc[el.locator.fileName] ??= [];
        acc[el.locator.fileName].push(el);
        return acc;
      },
      {} as Record<string, TagsListEntry[]>
    );

    const files: FileTokens[] = [];
    for (let fileName in entitiesByFiles) {
      const fileTokens = entitiesByFiles[fileName];
      fileTokens.sort((a, b) => a.locator.line - b.locator.line);

      const normalizedFileName = normalizeFilePath(fileName);
      files.push({
        fileName: normalizedFileName,
        tokens: fileTokens.map(el => ({
          ...el,
          locator: {
            fileName: normalizedFileName,
            column: el.locator.col,
            row: el.locator.line - 1,
          },
        })) as TokenizerToken[],
      });
    }

    return files;
  }
}
